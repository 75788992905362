import React, { useEffect, useRef, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Fixed } from 'layouts';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Dialog,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Switch,
  FormControlLabel,
  Tooltip,
  Radio,
  Checkbox,
  ListItemText,
  FormLabel,
  FormGroup,
  Card,
  Typography,
  TextField,
  OutlinedInput,
  Chip,
  Modal,
  InputAdornment,
  CircularProgress,
  Icon,
  Divider,
  DialogContentText,
  RadioGroup,
  Grid,
  Alert,
  Autocomplete,
  Tabs,
  Tab,
  TableFooter,
  Popper,
} from '@mui/material';
import moment from 'moment';
import {
  Add,
  ArrowBack,
  Assessment,
  Close,
  ContentCopy,
  CopyAll,
  Delete,
  Edit,
  Group,
  Link,
  Lock,
  Mic,
  Notes,
  Redo,
  Replay,
  Send,
  StopCircle,
  VoiceChat,
} from '@mui/icons-material';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import RichTextEditor from 'components/RichTextEditor';
import * as yup from 'yup';
import { useFormik } from 'formik';
import generatePlaybook from 'redux/actions/Common/generatePlaybook';
import getDefaultPrompt from 'redux/actions/Common/getDefaultPrompt';
import getAllGeneratedPlaybooks from 'redux/actions/Common/getAllGeneratedPlaybooks';
import extractCompanyDescription from 'redux/actions/Common/extractCompanyDescription';
import extractProductDescription from 'redux/actions/Common/extractProductDescription';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const validationSchemaPlaybook = yup.object({
  companyWebsite: yup
    .string()
    .trim()
    .required('Please enter company website'),
  productWebsite: yup
    .string()
    .trim()
    .required('Please enter atleast one product url'),
});

const PlaybookGeneration = ({
  asComponent,
  handlePrefillFromGeneratedPlaybook,
  setShowGeneratePlaybookWithAI,
  setShowEditPlaybookDrawer,
}) => {
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const { pathname, state } = useLocation();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const isXs = useMediaQuery(theme.breakpoints.only('xs'), {
    defaultMatches: true,
  });
  const isSm = useMediaQuery(theme.breakpoints.only('sm'), {
    defaultMatches: true,
  });

  const {
    playbooks,
    fetching,
    fetchingPagination,
    lastTime,
    emptyList,
  } = useSelector((state) => state.allGeneratedPlaybooks);

  const [verticalOptions, setVerticalOptions] = useState([]);
  const [generatedPlaybook, setGeneratedPlaybook] = useState('');
  const [currentTab, setCurrentTab] = useState('new');
  const [generatingPlaybook, setGeneratingPlaybook] = useState(false);
  const [errorGeneratingPlaybook, setErrorGeneratingPlaybook] = useState(false);

  const [
    productDescriptionGenerationError,
    setProductDescriptionGenerationError,
  ] = useState(false);

  const [
    companyDescriptionGenerationError,
    setCompanyDescriptionGenerationError,
  ] = useState(false);

  const [companyWebsiteError, setCompanyWebsiteError] = useState(null);
  const [productUrlsError, setProductUrlsError] = useState(null);

  const [errorUrls, setErrorUrls] = useState([]);

  const [
    generatingProductDescription,
    setGeneratingProductDescription,
  ] = useState(false);
  const [
    generatingCompanyDescription,
    setGeneratingCompanyDescription,
  ] = useState(false);

  const [
    generateCompanyDescriptionPopper,
    setGenerateCompanyDescriptionPopper,
  ] = useState(null);
  const openCDPopper = Boolean(generateCompanyDescriptionPopper);
  const [
    generateProductDescriptionPopper,
    setGenerateProductDescriptionPopper,
  ] = useState(null);
  const openPDPopper = Boolean(generateProductDescriptionPopper);

  const [playbookGenerationErrors, setPlaybookGenerationErrors] = useState([]);

  const initialValuesPlaybook = {
    // companyName: '',
    companyWebsite: '',
    // productDescription: '',
    // productName: '',
    productWebsite: '',
  };

  const onSubmitPlaybook = (values) => {
    setGeneratingPlaybook(true);
    dispatch(
      generatePlaybook(
        {
          aboutCompanyUrls: [values?.companyWebsite],
          productUrls: values?.productWebsite
            ?.split('\n')
            ?.map((url) => url.trim()),
        },
        (data) => {
          setGeneratedPlaybook(data);
          setGeneratingPlaybook(false);
          setErrorGeneratingPlaybook(false);
          setPlaybookGenerationErrors([]);
          handlePrefillFromGeneratedPlaybook(data);
          setShowGeneratePlaybookWithAI(false);
        },
        (error) => {
          console.log(error);
          setGeneratingPlaybook(false);
          setErrorGeneratingPlaybook(true);
          setPlaybookGenerationErrors(error?.failedFields || []);
        },
      ),
    );
  };

  const formikPlaybook = useFormik({
    initialValues: initialValuesPlaybook,
    validationSchema: validationSchemaPlaybook,
    onSubmit: onSubmitPlaybook,
  });

  const handleListScroll = (e, paginate) => {
    // console.log(
    //   e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight,
    // );
    if (
      (e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight &&
        // viewType === 'list' &&
        !fetching &&
        !fetchingPagination &&
        playbooks &&
        playbooks.length % 15 === 0 &&
        emptyList === false) ||
      paginate
    ) {
      dispatch(
        getAllGeneratedPlaybooks(15, lastTime, (data) => {
          // setLinks(data || []);
        }),
      );
    }
  };

  useEffect(() => {
    if (currentTab === 'history') {
      dispatch(getAllGeneratedPlaybooks(15, null, () => {}));
    }
    setGeneratedPlaybook('');
  }, [currentTab]);

  useEffect(() => {
    dispatch(
      getDefaultPrompt((data) => {
        // console.log(data);
        setVerticalOptions(data?.verticals);
      }),
    );
  }, []);

  return asComponent ? (
    <Box
      display={'flex'}
      flexDirection={{ xs: 'column', sm: 'column', md: 'row' }}
    >
      <Box
        display={'flex'}
        flexDirection={'column'}
        sx={{
          width: 1,
        }}
      >
        <Box display={'flex'} position={'relative'}>
          {currentTab === 'new' ? (
            <>
              <Box
                flex={1}
                display={'flex'}
                sx={{
                  width: 1,
                  // minHeight:
                  //   isXs || isSm
                  //     ? 'calc(100vh - 110px)'
                  //     : 'calc(100vh - 110px)',
                  maxHeight:
                    isXs || isSm
                      ? 'calc(100vh - 110px)'
                      : 'calc(100vh - 110px)',
                  overflowY: 'scroll',
                  '&::-webkit-scrollbar': {
                    width: '5px',
                  },
                  '&::-webkit-scrollbar-track': {
                    boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                    webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                  },
                  '&::-webkit-scrollbar-thumb': {
                    backgroundColor: theme.palette.primary.main,
                    borderRadius: 2,
                  },
                  position: 'relative',
                  padding: 2,
                }}
              >
                <form onSubmit={formikPlaybook.handleSubmit}>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Typography
                        variant={'subtitle2'}
                        sx={{ marginBottom: 1 }}
                      >
                        <span style={{ fontWeight: 'bold' }}>
                          About Company*
                        </span>
                        <br />
                        (Company&apos;s About Us Page e.g.
                        https://qualification.ai)
                      </Typography>
                      <TextField
                        placeholder="Enter company website*"
                        variant="outlined"
                        name={'companyWebsite'}
                        fullWidth
                        size="small"
                        value={formikPlaybook.values.companyWebsite}
                        onChange={formikPlaybook.handleChange}
                        error={
                          // formikPlaybook.touched.companyWebsite &&
                          Boolean(
                            playbookGenerationErrors.includes('companyName') ||
                              playbookGenerationErrors.includes(
                                'companyDescription',
                              ) ||
                              formikPlaybook.errors.companyWebsite,
                          )
                        }
                        helperText={
                          formikPlaybook.errors.companyWebsite
                            ? 'Please enter valid company website'
                            : playbookGenerationErrors.includes(
                                'companyName',
                              ) ||
                              playbookGenerationErrors.includes(
                                'companyDescription',
                              )
                            ? 'Unable to generate playbook because we could not extract company name or description from the given company website'
                            : ''
                        }
                        onBlur={() => {
                          setCompanyWebsiteError(null);
                        }}
                        type={'text'}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        variant={'subtitle2'}
                        sx={{ marginBottom: 1 }}
                      >
                        <span style={{ fontWeight: 'bold' }}>
                          Product URLs*{' '}
                        </span>
                        <br />
                        (Enter each url in separate line. These urls can be used
                        to generate product description.) e.g.
                        https://qualification.ai
                      </Typography>
                      <TextField
                        placeholder="Enter product urls*"
                        variant="outlined"
                        name={'productWebsite'}
                        fullWidth
                        size="small"
                        multiline
                        rows={3}
                        value={formikPlaybook.values.productWebsite}
                        onChange={formikPlaybook.handleChange}
                        error={Boolean(
                          playbookGenerationErrors.includes(
                            'productDescription',
                          ) || formikPlaybook.errors.productWebsite,
                        )}
                        helperText={
                          formikPlaybook.errors.productWebsite
                            ? 'Please enter atleast one product url'
                            : playbookGenerationErrors.includes(
                                'productDescription',
                              )
                            ? 'Unable to generate playbook because we could not extract product description from the given product urls'
                            : null
                        }
                        type={'text'}
                        onBlur={() => {
                          setProductUrlsError(null);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Box
                        display={'flex'}
                        alignItems={'center'}
                        justifyContent={'space-between'}
                      >
                        <Button
                          variant="outlined"
                          size="small"
                          onClick={() => {
                            setShowGeneratePlaybookWithAI(false);
                            setShowEditPlaybookDrawer('new');
                          }}
                        >
                          {'Create Playbook Manually'}
                        </Button>
                        <Button
                          size="small"
                          startIcon={
                            generatingPlaybook ? (
                              <CircularProgress size={20} />
                            ) : null
                          }
                          variant="contained"
                          disabled={generatingPlaybook}
                          type="submit"
                        >
                          {'Generate Playbook'}
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </form>
              </Box>
            </>
          ) : (
            <>
              <Box flex={1} display={'flex'} gap={2}>
                {playbooks && playbooks.length > 0 ? (
                  <TableContainer
                    component={Paper}
                    sx={{
                      width: 1,
                      minHeight: 'calc(100vh - 162px)',
                      maxHeight: 'calc(100vh - 162px)',
                      overflowY: 'scroll',
                      '&::-webkit-scrollbar': {
                        width: '5px',
                      },
                      '&::-webkit-scrollbar-track': {
                        boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                        webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                      },
                      '&::-webkit-scrollbar-thumb': {
                        backgroundColor: theme.palette.primary.main,
                        borderRadius: 2,
                      },
                      borderRight: '1px solid #d3d3d3',
                    }}
                    onScroll={handleListScroll}
                  >
                    <Table
                      // sx={{ minWidth: 750 }}
                      stickyHeader
                      aria-label="simple table"
                    >
                      <TableHead sx={{ bgcolor: 'alternate.dark' }}>
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant={'caption'}
                              fontWeight={700}
                              sx={{ textTransform: 'uppercase' }}
                            >
                              ID
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              variant={'caption'}
                              fontWeight={700}
                              sx={{ textTransform: 'uppercase' }}
                              align="center"
                            >
                              DATE
                            </Typography>
                          </TableCell>

                          <TableCell>
                            <Typography
                              variant={'caption'}
                              fontWeight={700}
                              sx={{ textTransform: 'uppercase' }}
                              align="center"
                            >
                              ACTIONS
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {playbooks.map((pb, i) => (
                          <TableRow
                            key={i}
                            // sx={{
                            //   '&:last-child td, &:last-child th': {
                            //     border: 0,
                            //   },
                            // }}
                          >
                            <TableCell component="th" scope="row">
                              <Typography
                                variant={'subtitle2'}
                                fontWeight={700}
                              >
                                {pb?.id}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant={'caption'}>
                                {moment(pb?.createdOn).format('DD MMMM YYYY')}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Box
                                display={'flex'}
                                alignItems={'center'}
                                gap={1}
                              >
                                <Tooltip
                                  title="view playbook"
                                  placement="left"
                                  arrow
                                >
                                  <Button
                                    variant="outlined"
                                    size="small"
                                    color="primary"
                                    sx={{ marginLeft: 1 }}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      setGeneratedPlaybook(pb?.playbook);
                                    }}
                                  >
                                    View
                                  </Button>
                                </Tooltip>
                              </Box>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                      <TableFooter>
                        <TableRow>
                          <TableCell
                            variant="footer"
                            colSpan={6}
                            sx={{
                              padding: 0,
                            }}
                          >
                            {fetchingPagination ? (
                              <Box
                                sx={{
                                  width: '100%',
                                  display: 'flex',
                                  justifyContent: 'center',
                                  padding: 1,
                                  textAlign: 'center',
                                }}
                              >
                                <Button
                                  size="small"
                                  variant="contained"
                                  // type="submit"
                                  color="primary"
                                  onClick={handleListScroll}
                                  // className="searchSettings"
                                  disabled
                                  // fullWidth
                                >
                                  <Typography color="textPrimary">
                                    Loading more playbooks ...{' '}
                                  </Typography>
                                  <CircularProgress size={20} />
                                </Button>
                              </Box>
                            ) : emptyList === false &&
                              playbooks.length >= 10 ? (
                              <Box
                                sx={{
                                  width: '100%',
                                  display: 'flex',
                                  justifyContent: 'center',
                                  padding: 1,
                                  textAlign: 'center',
                                }}
                              >
                                <Button
                                  size="small"
                                  variant="contained"
                                  // type="submit"
                                  // fullWidth
                                  color="primary"
                                  onClick={(e) => handleListScroll(e, true)}
                                  // className="searchSettings"
                                >
                                  Load More
                                </Button>
                              </Box>
                            ) : null}
                          </TableCell>
                        </TableRow>
                      </TableFooter>
                    </Table>
                  </TableContainer>
                ) : (
                  <Box
                    width={1}
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'center'}
                  >
                    <Typography variant={'body1'} color={'text.secondary'}>
                      No Playbooks Generated
                    </Typography>
                  </Box>
                )}
              </Box>
              {playbooks && playbooks.length > 0 ? (
                <Box
                  flex={1}
                  sx={{
                    width: 1,
                    minHeight:
                      isXs || isSm
                        ? 'calc(100vh - 162px)'
                        : 'calc(100vh - 162px)',
                    maxHeight:
                      isXs || isSm
                        ? 'calc(100vh - 162px)'
                        : 'calc(100vh - 162px)',
                    overflowY: 'scroll',
                    '&::-webkit-scrollbar': {
                      width: '5px',
                    },
                    '&::-webkit-scrollbar-track': {
                      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                    },
                    '&::-webkit-scrollbar-thumb': {
                      backgroundColor: theme.palette.primary.main,
                      borderRadius: 2,
                      // outline: '1px solid slategrey',
                    },
                    paddingBottom: 8,
                  }}
                >
                  {generatedPlaybook ? (
                    <RichTextEditor
                      value={generatedPlaybook?.playbookText}
                      onChange={() => {}}
                      readOnly
                    />
                  ) : null}
                </Box>
              ) : null}
            </>
          )}
          {generatedPlaybook ? (
            <Box
              sx={{
                position: 'absolute',
                bottom: 0,
                left: '50%',
                width: 0.5,
                display: 'flex',
                justifyContent: 'flex-end',
                p: 1,
                background: '#fff',
              }}
            >
              <Button
                variant="outlined"
                size="small"
                onClick={() => {
                  handlePrefillFromGeneratedPlaybook(generatedPlaybook);
                }}
              >
                Use this
              </Button>
            </Box>
          ) : null}
        </Box>
      </Box>
    </Box>
  ) : null;
};

PlaybookGeneration.propTypes = {
  asComponent: PropTypes.bool,
  handlePrefillFromGeneratedPlaybook: PropTypes.func,
  setShowGeneratePlaybookWithAI: PropTypes.func,
  setShowEditPlaybookDrawer: PropTypes.func,
};

export default PlaybookGeneration;
