/* eslint-disable react/no-unescaped-entities */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { auth } from '../../../../../firebase';
import {
  sendEmailVerification,
  signInWithEmailAndPassword,
  signOut,
} from 'firebase/auth';
import { useDispatch, useSelector } from 'react-redux';
import firebaseSignin from 'redux/actions/Auth/firebaseSignin';
import firebaseCheckSession from 'redux/actions/Auth/firebaseCheckSession';
import { useHistory, useLocation } from 'react-router-dom';
import {
  Alert,
  CircularProgress,
  IconButton,
  InputAdornment,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { detect } from 'detect-browser';
import trackEvent from 'redux/actions/Common/trackEvent';
import logError from 'redux/actions/Common/logError';
import saveElevatorPitchProgress from 'redux/actions/Common/saveElevatorPitchProgress';
import firebaseLogout from 'redux/actions/Auth/firebaseLogout';

const validationSchema = yup.object({
  email: yup
    .string()
    .trim()
    .email('Please enter a valid email address')
    .required('Email is required.'),
  password: yup
    .string()
    .required('Please specify your password')
    .min(5, 'The password should have at minimum length of 5'),
});

const Form = ({ emailVerificationError, emailVerified }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { search } = useLocation();
  const [processing, setProcessing] = useState(false);
  const params = new URLSearchParams(window.location.search);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [error, setError] = useState('');
  const [info, setInfo] = useState('');

  const redirectTo = params.get('redirectTo');
  const redirectToId = params.get('redirectToId');
  const elevatorPitchToken = params.get('pt');

  const initialValues = {
    email: params.get('email')
      ? params.get('email').replace(/ /g, '+')
      : params.get('continueUrl')
      ? new URL(decodeURIComponent(params.get('continueUrl'))).searchParams
          .get('email')
          .replace(/ /g, '+')
      : '',
    password: '',
  };

  const { isLoggedIn } = useSelector((state) => state.firebaseSession);
  const userInfo = JSON.parse(localStorage.getItem('userInfo') || '{}');
  const { pathname } = useLocation();
  const browser = detect();

  const handleTrackEvent = (e, type) => {
    try {
      dispatch(
        trackEvent({
          context: 'click_event',
          eventType: type,
          userId: isLoggedIn ? userInfo?.id : null,
          time: new Date().getTime(),
          os: browser?.os,
          sessionId: isLoggedIn
            ? userInfo?.id
            : sessionStorage.getItem('trackingSessionId'),
          customData: {
            page_path: pathname,
            browser: browser?.name,
            browser_version: browser?.version,
          },
        }),
      );
    } catch (e) {
      console.log(e);
      dispatch(
        logError({
          message: e?.message,
          data: {
            formatted: {
              errorText: e?.message,
              severity: 'error',
            },
            raw: e,
          },
        }),
      );
    }
  };

  const onSubmit = (values) => {
    setProcessing(true);
    signInWithEmailAndPassword(auth, values.email, values.password)
      .then((userCreds) => {
        // console.log(userCreds);
        if (userCreds?.user?.emailVerified) {
          userCreds.user.getIdToken().then((idToken) => {
            dispatch(
              firebaseSignin(
                idToken,
                (signinResponse) => {
                  // console.log(signinResponse);
                  dispatch(
                    firebaseCheckSession((isLoggedIn) => {
                      if (isLoggedIn) {
                        if (elevatorPitchToken?.trim()) {
                          dispatch(
                            saveElevatorPitchProgress(
                              elevatorPitchToken,
                              signinResponse?.user?.id,
                              () => {
                                history.push(
                                  `/practice/interview?ept=${elevatorPitchToken}`,
                                );
                              },
                            ),
                          );
                        } else {
                          if (redirectTo) {
                            if (redirectTo.startsWith('jobs')) {
                              if (redirectToId) {
                                history.push({
                                  pathname: `/${redirectTo}`,
                                  state: {
                                    jobId: redirectToId,
                                  },
                                });
                              } else {
                                history.push(`/${redirectTo}`);
                              }
                            } else {
                              if (redirectToId) {
                                history.push(
                                  search
                                    ? `/${redirectTo}/${redirectToId}${search}&comingFromRedirect=true`
                                    : `/${redirectTo}/${redirectToId}?comingFromRedirect=true`,
                                );
                              } else {
                                history.push(
                                  `/${redirectTo}?comingFromRedirect=true`,
                                );
                              }
                            }
                          } else {
                            // console.log(
                            //   `${signinResponse?.user?.slug}?comingFromRedirect=true`,
                            // );
                            if (signinResponse?.whitelabelDomain) {
                              if (signinResponse?.loginCount === 1) {
                                if (
                                  window.location.host !==
                                  signinResponse?.whitelabelDomain
                                ) {
                                  dispatch(
                                    firebaseLogout(() => {
                                      dispatch(firebaseCheckSession());
                                      history.go();
                                    }),
                                  );
                                  window.open(
                                    `https://${signinResponse?.whitelabelDomain}/account/settings?t=playbook`,
                                  );
                                } else {
                                  history.push(`/account/settings?t=playbook`);
                                }
                              } else {
                                if (signinResponse?.user?.slug) {
                                  console.log(signinResponse?.user?.slug);
                                  if (
                                    window.location.host !==
                                    signinResponse?.whitelabelDomain
                                  ) {
                                    dispatch(
                                      firebaseLogout(() => {
                                        dispatch(firebaseCheckSession());
                                        history.go();
                                      }),
                                    );
                                    window.open(
                                      `https://${signinResponse?.whitelabelDomain}/${signinResponse?.user?.slug}?comingFromRedirect=true`,
                                    );
                                  } else {
                                    history.push(
                                      `/${signinResponse?.user?.slug}?comingFromRedirect=true`,
                                    );
                                  }
                                } else {
                                  if (
                                    window.location.host !==
                                    signinResponse?.whitelabelDomain
                                  ) {
                                    dispatch(
                                      firebaseLogout(() => {
                                        dispatch(firebaseCheckSession());
                                        history.go();
                                      }),
                                    );
                                    window.open(
                                      `https://${signinResponse?.whitelabelDomain}`,
                                    );
                                  } else {
                                    history.push('/');
                                  }
                                }
                              }
                            } else {
                              if (
                                signinResponse?.organization?.type === 'w_org'
                              ) {
                                history.push('/customer/settings');
                              } else {
                                if (signinResponse?.loginCount === 1) {
                                  dispatch({
                                    type: 'UPDATE_UI_STATE',
                                    payload: {
                                      key: 'showOnboardingDialog',
                                      value: true,
                                    },
                                  });
                                  history.push(`/account/settings?t=playbook`);
                                } else {
                                  if (signinResponse?.user?.slug) {
                                    console.log(signinResponse?.user?.slug);
                                    history.push(
                                      `/${signinResponse?.user?.slug}?comingFromRedirect=true`,
                                    );
                                  } else {
                                    history.push('/');
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }),
                  );
                },
                (err) => {
                  if (err) {
                    setProcessing(false);
                    setError(err);
                  } else {
                    setProcessing(false);
                    setError('Unable to sign in');
                  }
                },
              ),
            );
          });
        } else {
          sendEmailVerification(auth.currentUser);
          setProcessing(false);
          // formik.resetForm();
          signOut(auth).then(() => {});
          setError(
            'Email is not verified. Please verify your email with the new link sent to your email and sign in again.',
          );
        }
      })
      .catch((error) => {
        dispatch(
          logError({
            message: error?.message,
            data: {
              formatted: {
                email: values.email,
                errorText: error?.message,
                errorType: error?.code,
                severity: 'error',
              },
              raw: error,
            },
          }),
        );
        setProcessing(false);
        if (
          error.code === 'auth/wrong-password' ||
          error.code === 'auth/user-not-found'
        ) {
          setError('Incorrect email or password');
        } else if (error.code === 'auth/user-disabled') {
          setError(
            'Your account seems disabled. Please contact your administrator or reach out to support to learn more.',
          );
        } else {
          setError(error.message);
        }
        console.log(error.code);
      });
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit,
  });

  // console.log(error);

  return (
    <Box>
      <Box marginBottom={4}>
        {/* <Typography
          sx={{
            textTransform: 'uppercase',
            fontWeight: 'medium',
          }}
          gutterBottom
          color={'text.secondary'}
        >
          Sign In
        </Typography> */}
        <Typography
          variant="h4"
          sx={{
            fontWeight: 700,
          }}
        >
          Sign In
        </Typography>
        <Box
          display="flex"
          flexDirection={{ xs: 'column', sm: 'row' }}
          alignItems={{ xs: 'stretched', sm: 'center' }}
          justifyContent={'space-between'}
          width={1}
          marginTop={2}
        >
          <Box marginTop={{ xs: 1, sm: 0 }}>
            <Typography variant={'subtitle2'}>
              Don't have an account yet?{' '}
              <Link
                data-trackid={`move_to_sign_up_page`}
                component={'a'}
                color={'primary'}
                href={search ? `/auth/signup${search}` : '/auth/signup'}
                underline={'none'}
                onClick={(e) => {
                  handleTrackEvent(e, 'move_to_sign_up_page');
                }}
              >
                Request a demo
              </Link>
            </Typography>
          </Box>
        </Box>
        {/* <Typography color="text.secondary">
          Sign In to manage your account.
        </Typography> */}
      </Box>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          {error && (
            <Grid item xs={12}>
              <Alert severity="error">{error}</Alert>
            </Grid>
          )}
          {info && (
            <Grid item xs={12}>
              <Alert severity="info">{info}</Alert>
            </Grid>
          )}
          {emailVerified && (
            <Grid item xs={12}>
              <Alert severity="success">
                Email has been verified successfully. Please continue to sign in
              </Alert>
            </Grid>
          )}
          {emailVerificationError && (
            <Grid item xs={12}>
              <Alert severity="error">{emailVerificationError}</Alert>
            </Grid>
          )}
          <Grid item xs={12}>
            <Typography
              variant={'subtitle2'}
              sx={{ marginBottom: 2, fontWeight: 'bold' }}
            >
              Email
            </Typography>
            <TextField
              label="Enter your email *"
              variant="outlined"
              name={'email'}
              fullWidth
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
          </Grid>
          <Grid item xs={12}>
            <Box
              display="flex"
              flexDirection={{ xs: 'column', sm: 'row' }}
              alignItems={{ xs: 'stretched', sm: 'center' }}
              justifyContent={'space-between'}
              width={1}
              // marginTop={2}
              marginBottom={2}
            >
              <Typography variant={'subtitle2'} sx={{ fontWeight: 'bold' }}>
                Password
              </Typography>
              <Typography variant={'subtitle2'}>
                <Link
                  data-trackid={`move_to_reset_password`}
                  component={'a'}
                  color={'primary'}
                  href={
                    formik.values.email
                      ? search
                        ? `/reset/password${search}&email=${formik.values.email}`
                        : `/reset/password?email=${formik.values.email}`
                      : search
                      ? `/reset/password${search}`
                      : `/reset/password`
                  }
                  underline={'none'}
                  onClick={(e) => {
                    handleTrackEvent(e, 'move_to_reset_password');
                  }}
                >
                  Forgot your password?
                </Link>
              </Typography>
            </Box>
            <TextField
              label="Enter your password *"
              variant="outlined"
              name={'password'}
              fullWidth
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
              type={passwordVisible ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      data-trackid={`toggle_password_visibility`}
                      aria-label="toggle password visibility"
                      onClick={(e) => {
                        handleTrackEvent(e, 'toggle_password_visibility');
                        setPasswordVisible(!passwordVisible);
                      }}
                      onMouseDown={(e) => e.preventDefault()}
                    >
                      {passwordVisible ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item container xs={12}>
            <Box
              display="flex"
              flexDirection={{ xs: 'column', sm: 'row' }}
              alignItems={{ xs: 'stretched', sm: 'center' }}
              justifyContent={'space-between'}
              width={1}
              maxWidth={600}
              margin={'0 auto'}
            >
              <Box marginBottom={{ xs: 1, sm: 0 }}></Box>
              <Button
                data-trackid={'cta_sign_in'}
                fullWidth
                size={'large'}
                variant={'contained'}
                type={'submit'}
                disabled={processing}
                startIcon={processing ? <CircularProgress size={18} /> : null}
                onClick={(e) => {
                  handleTrackEvent(e, 'cta_sign_in');
                }}
              >
                <Typography variant="h6" fontWeight={500}>
                  Sign In
                </Typography>
              </Button>
            </Box>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

Form.propTypes = {
  emailVerificationError: PropTypes.bool,
  emailVerified: PropTypes.bool,
};

export default Form;
