import {
  Box,
  Button,
  Card,
  CircularProgress,
  Grid,
  IconButton,
  Typography,
  useTheme,
  Modal,
  Divider,
  Paper,
  Avatar,
  Tabs,
  Tab,
  useMediaQuery,
  TextField,
  InputAdornment,
  Alert,
  InputLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormHelperText,
  FormControl,
  FormGroup,
  Checkbox,
  Select,
  MenuItem,
  Link,
  Tooltip,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Drawer,
  FormLabel,
  Chip,
  Switch,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  colors,
  Icon,
  TableFooter,
  OutlinedInput,
  Menu,
  CardContent,
} from '@mui/material';
import { PublicFluid } from 'layouts';
import React, { useEffect, useRef, useState } from 'react';
import Container from 'components/Container';
import { useDispatch, useSelector } from 'react-redux';
import getAllCompetitions from 'redux/actions/Common/getAllCompetitions';
import { CompetitionCard } from 'components';
import {
  Add,
  ArrowDownward,
  ArrowDropDown,
  ArrowRight,
  Article,
  AutoGraph,
  Check,
  ChevronRight,
  Close,
  CloudUpload,
  Delete,
  DeleteOutline,
  DoDisturb,
  Edit,
  Groups,
  Label,
  MoreVert,
  PersonRemove,
  Remove,
  RestartAlt,
  Settings,
  SupportAgent,
  Visibility,
  VisibilityOff,
} from '@mui/icons-material';
import showAlert from 'redux/actions/Common/showAlert';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { detect } from 'detect-browser';
import trackEvent from 'redux/actions/Common/trackEvent';
import logError from 'redux/actions/Common/logError';
import getCandidateDetails from 'redux/actions/Candidate/getCandidateDetails';
import uploadResume from 'redux/actions/Candidate/uploadResume';
import updateProfile from 'redux/actions/Candidate/updateProfile';
import deleteResume from 'redux/actions/Candidate/deleteResume';
import getUserDetails from 'redux/actions/Common/getUserDetails';
import {
  capitalizeText,
  checkIfNumberExistsBetweenRange,
  defaultPlaybookFieldsMapping,
  validateEmail,
  validateURL,
} from 'utils';
import getPlaybookHistory from 'redux/actions/Common/getPlaybookHistory';
import RichTextEditor from 'components/RichTextEditor';
import updatePlaybook from 'redux/actions/Common/updatePlaybook';
import getBots from 'redux/actions/Common/getBots';
import requestBot from 'redux/actions/Common/requestBot';
import updateGlobalOrganizationConfig from 'redux/actions/Common/updateGlobalOrganizationConfig';
import getBotRequests from 'redux/actions/Common/getBotRequests';
import updateUserDetails from 'redux/actions/Common/updateUserDetails';
import getDefaultPrompt from 'redux/actions/Common/getDefaultPrompt';
import { useHistory } from 'react-router-dom';
import getOrganizationConfigs from 'redux/actions/Common/getOrganizationConfigs';
import integrateHubspot from 'redux/actions/Common/integrateHubspot';
import getIntegrationKey from 'redux/actions/Common/getIntegrationKey';
import AutoCompleteMUI from 'components/@2024/AutoCompleteMUI';
import getWlCustomers from 'redux/actions/Common/getWlCustomers';
import createCustomer from 'redux/actions/Common/createCustomer';
import getAllTeamMembers from 'redux/actions/Common/getAllTeamMembers';
import updateTeamMemberRole from 'redux/actions/Common/updateTeamMemberRole';
import sendTeamInvite from 'redux/actions/Common/sendTeamInvite';
import getAllTeamInvitations from 'redux/actions/Common/getAllTeamInvitations';
import removeUserFromOrg from 'redux/actions/Common/removeUserFromOrg';
import PerformanceDashboard from 'views/Candidate/PerformanceDashboard';
import getUploadUrlForWhitelabelLogo from 'redux/actions/Candidate/getUploadUrlForWhitelabelLogo';
import uploadWhitelabelLogo from 'redux/actions/Common/uploadWhitelabelLogo';
import verifyWhitelabelLogoUpload from 'redux/actions/Common/verifyWhitelabelLogoUpload';
import updateCustomerDetails from 'redux/actions/Common/updateCustomerDetails';
import SalesCallAnalysis from 'views/Candidate/SaleCallAnalysis/SalesCallAnalysis';
import ConversationAIMain from 'views/Candidate/ConversationAIMain';
import PlaybookGeneration from '../PlaybookGeneration';
import updateTeamSuspendStatus from 'redux/actions/Common/updateTeamSuspendStatus';
import SkillTagInput from 'components/@2024/SkillTagInput';
import getWlOrgPlans from 'redux/actions/Common/getWlOrgPlans';
import updateWlClientPlan from 'redux/actions/Common/updateWlClientPlan';
import updatePlanWL from 'redux/actions/Common/updatePlanWL';
import getAddons from 'redux/actions/Common/getAddons';
import deepEqual from 'deep-equal';
import getContentScoringConfigTemplate from 'redux/actions/Common/getContentScoringConfigTemplate';
import getPlaybookTemplates from 'redux/actions/Common/getPlaybookTemplates';
import deletePlaybookTemplate from 'redux/actions/Common/deletePlaybookTemplate';
import createPlaybookTemplate from 'redux/actions/Common/createPlaybookTemplate';
import updatePlaybookTemplate from 'redux/actions/Common/updatePlaybookTemplate';
import getPlaybooks from 'redux/actions/Common/getPlaybooks';
import deletePlaybook from 'redux/actions/Common/deletePlaybook';
import createPlaybook from 'redux/actions/Common/createPlaybook';
import updateTemplatePlaybook from 'redux/actions/Common/updateTemplatePlaybook';

const validationSchema = yup.object({
  appTitle: yup
    .string()
    .trim()
    .required('Please enter app title'),
  appSubTitle: yup
    .string()
    .trim()
    .required('Please enter app tagline'),
  // appLogo: yup
  //   .string()
  //   .trim()
  //   .required('Please upload a app logo'),
  whitelabelDomain: yup
    .string()
    .trim()
    .required('Please enter whitelabel domain'),
  organizationWebsite: yup.string(),
  metaDescription: yup.string(),
  textInLogo: yup.bool(),
});

const validationSchemaForInviteForm = yup.object().shape({
  designation: yup.string().required('Please select atleast one designation'),
  methodologies: yup.array(),
  emailVerified: yup.bool(),
  email: yup
    .string()
    .trim()
    .email('Please enter a valid email')
    .required('Email is required'),
  companyName: yup
    .string()
    .trim()
    .required('Company Name is required'),
  country: yup
    .string()
    .trim()
    .required('Please select a country'),
  demo: yup.bool(),
  planId: yup
    .string()
    .trim()
    .uuid('Please select a plan')
    .required('Please select a plan'),
  withPlaybook: yup.bool(),
  withProspects: yup.bool(),
  playbookStructure: yup.object().nullable(),
  prospectDetails: yup.object().nullable(),
  appTitle: yup.string().when('designation', {
    is: (designation) => designation === 'whitelabel_user',
    then: yup
      .string()
      .trim()
      .required('Please enter app title'),
    otherwise: yup.string(),
  }),
  appSubTitle: yup.string().when('designation', {
    is: (designation) => designation === 'whitelabel_user',
    then: yup
      .string()
      .trim()
      .required('Please enter app tagline'),
    otherwise: yup.string(),
  }),
  metaDescription: yup.string().trim(),
  organizationWebsite: yup.string().trim(),
  whitelabelDomain: yup.string().when('designation', {
    is: (designation) => designation === 'whitelabel_user',
    then: yup
      .string()
      .trim()
      .required('Please enter whitelabel domain'),
    otherwise: yup.string(),
  }),
});

const validationSchemaPlaybook = yup.object().nullable();

const CustomerSettings = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname } = useLocation();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const isXs = useMediaQuery(theme.breakpoints.only('xs'), {
    defaultMatches: false,
  });
  const params = new URLSearchParams(window.location.search);
  const {
    showCompetitionDetailsOnTopbar,
    currentNavigationLevel,
    showBackButtonOnTopbar,
  } = useSelector((state) => state.uiStates);
  const {
    invitations,
    fetching,
    fetchingPagination,
    lastSeen,
    emptyList,
  } = useSelector((state) => state.allTeamInvitations);
  const { isLoggedIn } = useSelector((state) => state.firebaseSession);
  const { userDetails } = useSelector((state) => state.userDetails);
  const { orgConfigs } = useSelector((state) => state.orgConfigs);
  const { dashboardSidebarExpanded } = useSelector((state) => state.uiStates);
  const userInfo = JSON.parse(localStorage.getItem('userInfo') || '{}');
  const allTeamMembers = useSelector((state) => state.allTeamMembers);
  const wlCustomers = useSelector((state) => state.wlCustomers);
  const { customers } = wlCustomers;
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState('');
  const [info, setInfo] = useState('');
  const logoWithTextRef = useRef();
  const [imageSizeError, setImageSizeError] = useState(false);
  const [logoWithText, setLogoWithText] = useState(null);
  const [invalidImageType, setInvalidImageType] = useState(false);

  const [currentTab, setCurrentTab] = useState('details');

  // const [customers] = useState([]);
  const [showAddCustomerDialog, setShowAddCustomerDialog] = useState(false);
  const [verticalOptions, setVerticalOptions] = useState([]);
  const [designationOptions, setDesignationOptions] = useState([]);
  const browser = detect();

  const [viewType, setViewType] = useState('customers');
  const [currentOrgId, setCurrentOrgId] = useState(null);
  const [updatingRole, setUpdatingRole] = useState(false);

  const [currentTeamsTab, setCurrentTeamsTab] = useState('members');

  const [loading, setLoading] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [teamRole, setTeamRole] = useState('ORG_STANDARD');
  const [currentMemberId, setCurrentMemberId] = useState('');

  const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] = useState(null);
  const [removingUser, setRemovingUser] = useState(false);

  const faviconRef = useRef();
  const [imageSizeError1, setImageSizeError1] = useState(false);
  const [favicon, setFavicon] = useState(null);
  const [invalidImageType1, setInvalidImageType1] = useState(false);

  const logoWithoutTextRef = useRef();
  const [imageSizeError2, setImageSizeError2] = useState(false);
  const [logoWithoutText, setLogoWithoutText] = useState(null);
  const [invalidImageType2, setInvalidImageType2] = useState(false);

  const [organizationName, setOrganizationName] = useState('');
  const [country, setCountry] = useState('united states');
  const [withPlaybook, setWithPlaybook] = useState(false);

  const [invitingCustomer, setInvitingCustomer] = useState(false);
  const [inviteTags, setInviteTags] = useState([]);
  const [tags, setTags] = useState([]);
  const [updatingOrgDetails, setUpdatingOrgDetails] = useState(false);

  const [showWarningBox, setShowWarningBox] = useState(false);
  const [
    showWarningBoxForUpdatePlaybook,
    setShowWarningBoxForUpdatePlaybook,
  ] = useState(false);

  const [showGeneratePlaybookWithAI, setShowGeneratePlaybookWithAI] = useState(
    '',
  );
  const resetFields = () => {};

  const [showQuestionDialog, setShowQuestionDialog] = useState(null);
  const [question, setQuestion] = useState('');
  const [newQuestions, setNewQuestions] = useState(['']);

  const [
    showConfirmDeleteQuestionDialog,
    setShowConfirmDeleteQuestionDialog,
  ] = useState(null);

  const [anchorOptionsEl, setAnchorOptionsEl] = useState(null);
  const [currentMenuIndex, setCurrentMenuIndex] = useState(0);

  const [showSuggestions, setShowSuggestions] = useState(true);
  const [showConfirmSuspend, setShowConfirmSuspend] = useState(null);

  const [plans, setPlans] = useState([]);
  const [currentClientPlan, setCurrentClientPlan] = useState(null);
  const [clientPlanPrice, setClientPlanPrice] = useState(0);
  const [selectedAddOns, setSelectedAddOns] = useState([]);

  const [anchorEl, setAnchorEl] = useState(null);
  const [currentPlanIndex, setCurrentPlanIndex] = useState(null);

  const [
    showPlanPricingUpdateDialog,
    setShowPlanPricingUpdateDialog,
  ] = useState(null);
  const [showConfirmPublishDialog, setShowConfirmPublishDialog] = useState(
    null,
  );
  const [publishingPlan, setPublishingPlan] = useState(false);
  const [updatingPlan, setUpdatingPlan] = useState(false);

  const [planPrice, setPlanPrice] = useState(0);
  const [priceUnit, setPriceUnit] = useState('usd');
  const [addOnsPrice, setAddOnsPrice] = useState({});

  const [planType, setPlanType] = useState('regular');
  const [trailPeriodDays, setTrailPeriodDays] = useState(14);
  const [addOns, setAddOns] = useState([]);

  const [withContentScoringConfig, setWithContentScoringConfig] = useState(
    false,
  );
  const [
    showEditContentScoringConfig,
    setShowEditContentScoringConfig,
  ] = useState(false);

  const [contentScoringConfig, setContentScoringConfig] = useState({
    inbound: null,
    outbound: null,
  });

  const [callType, setCallType] = useState('outbound');
  const [sameConfig, setSameConfig] = useState(true);

  const [criteriaName, setCriteriaName] = useState('');
  const [criteriaFactorName, setCriteriaFactorName] = useState('');

  const [configType, setConfigType] = useState('form');

  const [contentScoreConfigText, setContentScoreConfigText] = useState({
    inbound: '',
    outbound: '',
  });

  const [showConfigurationChanged, setShowConfigurationChanged] = useState(
    false,
  );

  const [currentPlaybooksTab, setCurrentPlaybookTabs] = useState('templates');
  const [playbookTemplates, setPlaybookTemplates] = useState([]);

  const [anchorElTemplate, setAnchorElTemplate] = useState(null);
  const [currentTemplateIndex, setCurrentTemplateIndex] = useState(0);
  const [showEditTemplateDialog, setShowEditTemplateDialog] = useState(null);
  const [
    showConfirmTemplateDeleteDialog,
    setShowConfirmTemplateDeleteDialog,
  ] = useState(null);
  const [deletingTemplate, setDeletingTemplate] = useState(false);

  const [defaultFieldsMapping, setDefaultFieldsMapping] = useState({});
  const [defaultPlaybookFields, setDefaultPlaybookFields] = useState(null);
  const [customPlaybookFields, setCustomPlaybookFields] = useState([]);
  const [playbookTemplate, setPlaybookTemplate] = useState(null);
  const [expandedField, setExpandedField] = useState(['default']);

  const [showAddCustomFieldDialog, setShowAddCustomFieldDialog] = useState(
    false,
  );
  const [templateTitle, setTemplateTitle] = useState('');
  const [customFieldContext, setCustomFieldContext] = useState('');
  const [customFieldTitle, setCustomFieldTitle] = useState('');
  const [creatingOrUpdatingTemplate, setCreatingOrUpdatingTemplate] = useState(
    false,
  );

  const [playbooks, setPlaybooks] = useState([]);

  const [anchorElPlaybook, setAnchorElPlaybook] = useState(null);
  const [currentPlaybookIndex, setCurrentPlaybookIndex] = useState(0);
  const [showEditPlaybookDialog, setShowEditPlaybookDialog] = useState(null);
  const [
    showConfirmPlaybookDeleteDialog,
    setShowConfirmPlaybookDeleteDialog,
  ] = useState(null);
  const [deletingPlaybook, setDeletingPlaybook] = useState(false);
  const [creatingOrUpdatingPlaybook, setCreatingOrUpdatingPlaybook] = useState(
    false,
  );
  const [selectedTemplate, setSelectedTemplate] = useState('-');
  const [selectedPlaybook, setSelectedPlaybook] = useState('-');
  const [methodologies, setMethodologies] = useState([]);

  const [showTemplatePreview, setShowTemplatePreview] = useState(null);

  const handleMenuClose = () => {
    setAnchorEl(null);
    setCurrentPlanIndex(null);
  };

  const handleTemplateMenuClose = () => {
    setAnchorElTemplate(null);
    setCurrentTemplateIndex(null);
  };

  const handlePlaybookMenuClose = () => {
    setAnchorElPlaybook(null);
    setCurrentPlaybookIndex(null);
  };

  const handleTrackEvent = (e, type) => {
    try {
      dispatch(
        trackEvent({
          context: 'click_event',
          eventType: type,
          userId: isLoggedIn ? userInfo?.id : null,
          time: new Date().getTime(),
          os: browser?.os,
          sessionId: isLoggedIn
            ? userInfo?.id
            : sessionStorage.getItem('trackingSessionId'),
          customData: {
            page_path: pathname,
            browser: browser?.name,
            browser_version: browser?.version,
          },
        }),
      );
    } catch (e) {
      console.log(e);
      dispatch(
        logError({
          message: e?.message,
          data: {
            formatted: {
              errorText: e?.message,
              severity: 'error',
            },
            raw: e,
          },
        }),
      );
    }
  };
  const initialValues = {
    appTitle: '',
    appSubTitle: '',
    organizationWebsite: '',
    whitelabelDomain: '',
    metaDescription: '',
    textInLogo: true,
  };

  const initialInviteFormValues = {
    designation: 'account_admin',
    methodologies: [],
    demo: false,
    emailVerified: true,
    email: '',
    companyName: '',
    country: 'united states',
    planId: '-',
    withPlaybook: false,
    withProspects: false,
    playbookStructure: {
      companyName: '',
      productDescription: '',
      productKeyFeatures: '',
      idealCustomerProfile: '',
      methodologies: [],
      customerQualificationQuestions: '',
      customerQuestions: [
        {
          question: '',
          answer: '',
        },
      ],
      challengesForCustomer: [
        {
          question: '',
          answer: '',
        },
      ],
      expectedCallToAction: '',
      competingProducts: '',
      awardsRatingAndReviews: '',
    },
    prospectDetails: null,
    appSubTitle: '',
    appTitle: '',
    metaDescription: '',
    organizationWebsite: '',
    whitelabelDomain: '',
  };

  const initialValuesPlaybook = {
    companyName: '',
    productDescription: '',
    productKeyFeatures: '',
    idealCustomerProfile: '',
    methodologies: [],
    customerQualificationQuestions: '',
    customerQuestions: [
      {
        question: '',
        answer: '',
      },
    ],
    challengesForCustomer: [
      {
        question: '',
        answer: '',
      },
    ],
    expectedCallToAction: '',
    competingProducts: '',
    awardsRatingAndReviews: '',
  };

  const handlePrefillFromGeneratedPlaybook = (structure) => {
    // console.log(structure);
    if (showGeneratePlaybookWithAI === 'invite') {
      setInviteTags(
        structure?.company?.split(':')?.[1]?.trim()
          ? [structure?.company?.split(':')?.[1]?.trim()]
          : [],
      );
      setDefaultPlaybookFields((prev) =>
        Object.keys(prev || {}).reduce((acc, curr) => {
          acc[curr] =
            structure[curr] || (typeof structure[curr] === 'object' ? [] : '');
          return acc;
        }, {}),
      );
      setShowGeneratePlaybookWithAI('');
    } else {
      setTags(
        structure?.company?.split(':')?.[1]?.trim()
          ? [structure?.company?.split(':')?.[1]?.trim()]
          : [],
      );
      setShowGeneratePlaybookWithAI('');
    }
  };

  const onSubmit = (values) => {
    console.log(values);
    if (logoWithText) {
      dispatch(
        getUploadUrlForWhitelabelLogo(
          {
            format: logoWithText.type,
            purpose: 'string',
            size: logoWithText.size,
            type: 'image',
          },
          ({ fileId, url }) => {
            dispatch(
              uploadWhitelabelLogo(url, logoWithText, () => {
                console.log('image uploaded');
                dispatch(
                  verifyWhitelabelLogoUpload(fileId, () => {
                    dispatch(getUserDetails(() => {}));
                  }),
                );
              }),
            );
          },
        ),
      );
    }
    if (favicon) {
      dispatch(
        getUploadUrlForWhitelabelLogo(
          {
            format: favicon.type,
            purpose: 'favicon',
            size: favicon.size,
            type: 'image',
          },
          ({ fileId, url }) => {
            dispatch(
              uploadWhitelabelLogo(url, favicon, () => {
                console.log('image uploaded');
                dispatch(
                  verifyWhitelabelLogoUpload(fileId, () => {
                    dispatch(getUserDetails(() => {}));
                  }),
                );
              }),
            );
          },
        ),
      );
    }
    if (logoWithoutText && !values.textInLogo) {
      dispatch(
        getUploadUrlForWhitelabelLogo(
          {
            format: logoWithoutText.type,
            purpose: 'logo-without-text',
            size: logoWithoutText.size,
            type: 'image',
          },
          ({ fileId, url }) => {
            dispatch(
              uploadWhitelabelLogo(url, logoWithoutText, () => {
                console.log('image uploaded');
                dispatch(
                  verifyWhitelabelLogoUpload(fileId, () => {
                    dispatch(getUserDetails(() => {}));
                  }),
                );
              }),
            );
          },
        ),
      );
    }
    dispatch(
      updateGlobalOrganizationConfig(
        {
          appSubTitle: values.appSubTitle,
          appTitle: values.appTitle,
          metaDescription: values.metaDescription,
          organizationWebsite: values.organizationWebsite,
          textInLogo: !values.textInLogo,
          generalAutoAnalysis: orgConfigs?.isGeneralAutoAnalysis,
        },
        () => {
          dispatch(getUserDetails(() => {}));
          dispatch(
            showAlert({
              message: 'Details updated successfully.',
              showCross: true,
              title: null,
              type: 'success',
              autoHideDuration: 2000,
              vertical: 'top',
              horizontal: 'center',
            }),
          );
        },
      ),
    );
  };

  const onImageChange = (event) => {
    if (event.target.files[0] && event.target.files[0].type.includes('image')) {
      if (Math.round(event.target.files[0].size) / 1024 > 2048) {
        setImageSizeError(true);
        dispatch(
          showAlert({
            message: 'Please upload an image upto 2 MB.',
            showCross: true,
            title: null,
            type: 'error',
            autoHideDuration: 2000,
            vertical: 'top',
            horizontal: 'center',
          }),
        );
      } else {
        setLogoWithText(event.target.files[0]);
      }
    } else if (!event.target.files[0]) {
      // setSelectedFile(event.target.files[0]);
    } else {
      logoWithTextRef.current.value = null;
      setInvalidImageType(true);
      dispatch(
        showAlert({
          message:
            'Please upload a valid image file.Supported formats are jpeg, png.',
          showCross: true,
          title: null,
          type: 'error',
          autoHideDuration: 2000,
          vertical: 'top',
          horizontal: 'center',
        }),
      );
    }
  };

  const onImageChange1 = (event) => {
    if (event.target.files[0] && event.target.files[0].type.includes('image')) {
      if (Math.round(event.target.files[0].size) / 1024 > 2048) {
        setImageSizeError1(true);
        dispatch(
          showAlert({
            message: 'Please upload an image upto 2 MB.',
            showCross: true,
            title: null,
            type: 'error',
            autoHideDuration: 2000,
            vertical: 'top',
            horizontal: 'center',
          }),
        );
      } else {
        setFavicon(event.target.files[0]);
      }
    } else if (!event.target.files[0]) {
      // setSelectedFile(event.target.files[0]);
    } else {
      faviconRef.current.value = null;
      setInvalidImageType1(true);
      dispatch(
        showAlert({
          message:
            'Please upload a valid image file.Supported formats are jpeg, png.',
          showCross: true,
          title: null,
          type: 'error',
          autoHideDuration: 2000,
          vertical: 'top',
          horizontal: 'center',
        }),
      );
    }
  };

  const onImageChange2 = () => {
    if (event.target.files[0] && event.target.files[0].type.includes('image')) {
      if (Math.round(event.target.files[0].size) / 1024 > 2048) {
        setImageSizeError2(true);
        dispatch(
          showAlert({
            message: 'Please upload an image upto 2 MB.',
            showCross: true,
            title: null,
            type: 'error',
            autoHideDuration: 2000,
            vertical: 'top',
            horizontal: 'center',
          }),
        );
      } else {
        setLogoWithoutText(event.target.files[0]);
      }
    } else if (!event.target.files[0]) {
      // setSelectedFile(event.target.files[0]);
    } else {
      logoWithoutTextRef.current.value = null;
      setInvalidImageType2(true);
      dispatch(
        showAlert({
          message:
            'Please upload a valid image file.Supported formats are jpeg, png.',
          showCross: true,
          title: null,
          type: 'error',
          autoHideDuration: 2000,
          vertical: 'top',
          horizontal: 'center',
        }),
      );
    }
  };

  const onInviteFormSubmit = (values) => {
    // console.log(values);
    if (
      userDetails?.plan?.trailEndsOn > new Date().getTime() ||
      userDetails?.plan?.currentPaymentStatus === 'paid'
    ) {
      if (
        values.withPlaybook &&
        !(
          values?.playbookStructure?.productDescription &&
          values?.playbookStructure?.productKeyFeatures &&
          values?.playbookStructure?.customerQualificationQuestions &&
          values?.playbookStructure?.customerQuestions &&
          values?.playbookStructure?.customerQuestions?.length > 0 &&
          values?.playbookStructure?.customerQuestions?.find(
            (x) => x?.question?.trim() && x?.answer?.trim(),
          ) &&
          values?.playbookStructure?.challengesForCustomer &&
          values?.playbookStructure?.challengesForCustomer?.length > 0 &&
          values?.playbookStructure?.challengesForCustomer?.find(
            (x) => x?.question?.trim() && x?.answer?.trim(),
          ) &&
          values?.playbookStructure?.competingProducts
        )
      ) {
        setShowWarningBox(true);
      } else {
        setInvitingCustomer(true);
        dispatch(
          createCustomer(
            values.withPlaybook
              ? values.withProspects
                ? {
                    addTeamMemberRequestDtos: [
                      {
                        designations: ['account_admin'],
                        email: values.email,
                        emailVerified: values.emailVerified,
                        orgRoles: ['ORG_ADMIN'],
                      },
                    ],
                    botDesignations: values.prospectDetails?.designation,
                    botVerticals: values.prospectDetails?.vertical,
                    demo: values?.demo,
                    methodologies: methodologies,
                    organizationName: values.companyName,
                    planId: values.planId,
                    countries: [values.country],
                    contentScoringConfig: withContentScoringConfig
                      ? {
                          inbound: sameConfig
                            ? contentScoringConfig.outbound
                            : contentScoringConfig.inbound,
                          outbound: contentScoringConfig.outbound,
                        }
                      : null,
                    planAddOns: addOns
                      ?.filter((addon) =>
                        selectedAddOns?.includes(addon?.feature?.feature),
                      )
                      ?.map((addon) => {
                        return {
                          ...addon,
                          price: {
                            ...addon.price,
                            amount: Number(
                              addOnsPrice?.[addon?.feature?.feature],
                            ),
                          },
                        };
                      }),
                    priceRequest: {
                      amount: clientPlanPrice,
                    },
                    trailPeriodDays: planType === 'trial' ? trailPeriodDays : 0,
                    invitedForTrailPlan: planType === 'trial' ? true : false,
                    playbookStructure: {
                      ...defaultPlaybookFields,
                      customPlayBookFields: customPlaybookFields,
                    },
                  }
                : {
                    addTeamMemberRequestDtos: [
                      {
                        designations: ['account_admin'],
                        email: values.email,
                        emailVerified: values.emailVerified,
                        orgRoles: ['ORG_ADMIN'],
                      },
                    ],
                    demo: values?.demo,
                    methodologies: methodologies,
                    organizationName: values.companyName,
                    contentScoringConfig: withContentScoringConfig
                      ? {
                          inbound: sameConfig
                            ? contentScoringConfig.outbound
                            : contentScoringConfig.inbound,
                          outbound: contentScoringConfig.outbound,
                        }
                      : null,
                    planAddOns: addOns
                      ?.filter((addon) =>
                        selectedAddOns?.includes(addon?.feature?.feature),
                      )
                      ?.map((addon) => {
                        return {
                          ...addon,
                          price: {
                            ...addon.price,
                            amount: Number(
                              addOnsPrice?.[addon?.feature?.feature],
                            ),
                          },
                        };
                      }),
                    priceRequest: {
                      amount: clientPlanPrice,
                    },
                    planId: values.planId,
                    trailPeriodDays: planType === 'trial' ? trailPeriodDays : 0,
                    invitedForTrailPlan: planType === 'trial' ? true : false,
                    countries: [values.country],
                    playbookStructure: {
                      ...defaultPlaybookFields,
                      customPlayBookFields: customPlaybookFields,
                    },
                  }
              : {
                  addTeamMemberRequestDtos: [
                    {
                      designations: ['account_admin'],
                      email: values.email,
                      emailVerified: values.emailVerified,
                      orgRoles: ['ORG_ADMIN'],
                    },
                  ],
                  organizationName: values.companyName,
                  demo: values?.demo,
                  methodologies: methodologies,
                  contentScoringConfig: withContentScoringConfig
                    ? {
                        inbound: sameConfig
                          ? contentScoringConfig.outbound
                          : contentScoringConfig.inbound,
                        outbound: contentScoringConfig.outbound,
                      }
                    : null,
                  trailPeriodDays: planType === 'trial' ? trailPeriodDays : 0,
                  invitedForTrailPlan: planType === 'trial' ? true : false,
                  planAddOns: addOns
                    ?.filter((addon) =>
                      selectedAddOns?.includes(addon?.feature?.feature),
                    )
                    ?.map((addon) => {
                      return {
                        ...addon,
                        price: {
                          ...addon.price,
                          amount: Number(
                            addOnsPrice?.[addon?.feature?.feature],
                          ),
                        },
                      };
                    }),
                  priceRequest: {
                    amount: clientPlanPrice,
                  },
                  countries: [values.country],
                  planId: values.planId,
                },
            (data, error) => {
              if (error) {
                setInvitingCustomer(false);
                dispatch(
                  showAlert({
                    message:
                      'Customer invitation failed. Please try again later!',
                    showCross: true,
                    title: null,
                    type: 'error',
                    autoHideDuration: 2000,
                    vertical: 'top',
                    horizontal: 'center',
                  }),
                );
              } else {
                console.log(data);
                if (
                  data?.invitationStatus?.[0]?.invitationStatus === 'failed'
                ) {
                  setInvitingCustomer(false);
                  dispatch(
                    showAlert({
                      message:
                        'Failed to invite client. Please try again later!',
                      showCross: true,
                      title: null,
                      type: 'error',
                      autoHideDuration: 2000,
                      vertical: 'top',
                      horizontal: 'center',
                    }),
                  );
                } else {
                  setInvitingCustomer(false);
                  dispatch(
                    getWlCustomers(userDetails?.organizationId, (data) => {
                      console.log(data);
                      // setCustomers(data);
                    }),
                  );
                  dispatch(
                    showAlert({
                      message: 'Customer invited successfully',
                      showCross: true,
                      title: null,
                      type: 'success',
                      autoHideDuration: 2000,
                      vertical: 'top',
                      horizontal: 'center',
                    }),
                  );
                  setShowAddCustomerDialog(false);
                }
              }
            },
          ),
        );
      }
    } else {
      dispatch({
        type: 'UPDATE_UI_STATE',
        payload: {
          key: 'showPlanExpired',
          value: true,
        },
      });
    }
  };

  const onSubmitPlaybook = (values) => {
    // console.log(values);
    if (
      withPlaybook &&
      !(
        values?.productDescription &&
        values?.productKeyFeatures &&
        values?.customerQualificationQuestions &&
        values?.customerQuestions &&
        values?.customerQuestions?.length > 0 &&
        values?.customerQuestions?.find(
          (x) => x?.question?.trim() && x?.answer?.trim(),
        ) &&
        values?.challengesForCustomer &&
        values?.challengesForCustomer?.length > 0 &&
        values?.challengesForCustomer?.find(
          (x) => x?.question?.trim() && x?.answer?.trim(),
        ) &&
        values?.competingProducts
      )
    ) {
      setShowWarningBoxForUpdatePlaybook(true);
    } else {
      setUpdatingOrgDetails(true);
      dispatch(
        updateCustomerDetails(
          currentOrgId,
          {
            organizationName,
            methodologies: methodologies,
            countries: [country],
            contentScoringConfig: withContentScoringConfig
              ? {
                  inbound: sameConfig
                    ? contentScoringConfig.outbound
                    : contentScoringConfig.inbound,
                  outbound: contentScoringConfig.outbound,
                }
              : null,
            playbookStructure: {
              ...defaultPlaybookFields,
              customPlayBookFields: customPlaybookFields,
            },
          },
          () => {
            setUpdatingOrgDetails(false);
            dispatch(getWlCustomers(userDetails?.organizationId, () => {}));
            dispatch(
              showAlert({
                message: 'Details updated successfully.',
                showCross: true,
                title: null,
                type: 'success',
                autoHideDuration: 2000,
                vertical: 'top',
                horizontal: 'center',
              }),
            );
          },
        ),
      );
    }
  };

  const handleCreateOrUpdateTemplate = (action) => {
    console.log(action);
    console.log(defaultPlaybookFields);
    console.log(customPlaybookFields);
    if (action === 'create') {
      setCreatingOrUpdatingTemplate(true);
      dispatch(
        createPlaybookTemplate(
          {
            title: templateTitle,
            customPlayBookFields: customPlaybookFields,
            defaultPlaybookFields: defaultPlaybookFields,
          },
          (data, error) => {
            if (error) {
              setCreatingOrUpdatingTemplate(false);
              dispatch(
                showAlert({
                  message: 'Failed to create template. Please try again later!',
                  showCross: true,
                  title: null,
                  type: 'error',
                  autoHideDuration: 2000,
                  vertical: 'top',
                  horizontal: 'center',
                }),
              );
            } else {
              setCreatingOrUpdatingTemplate(false);
              setShowEditTemplateDialog(null);
              dispatch(
                getPlaybookTemplates((data) => {
                  console.log(data);
                  setPlaybookTemplates(data || []);
                }),
              );
              dispatch(
                showAlert({
                  message: 'Template created successfully',
                  showCross: true,
                  title: null,
                  type: 'success',
                  autoHideDuration: 2000,
                  vertical: 'top',
                  horizontal: 'center',
                }),
              );
            }
          },
        ),
      );
    } else {
      setCreatingOrUpdatingTemplate(true);
      dispatch(
        updatePlaybookTemplate(
          showEditTemplateDialog?.id,
          {
            title: templateTitle,
            customPlayBookFields: customPlaybookFields,
            defaultPlaybookFields: defaultPlaybookFields,
          },
          (data, error) => {
            if (error) {
              setCreatingOrUpdatingTemplate(false);
              dispatch(
                showAlert({
                  message: 'Failed to update template. Please try again later!',
                  showCross: true,
                  title: null,
                  type: 'error',
                  autoHideDuration: 2000,
                  vertical: 'top',
                  horizontal: 'center',
                }),
              );
            } else {
              setCreatingOrUpdatingTemplate(false);
              setShowEditTemplateDialog(null);
              dispatch(
                getPlaybookTemplates((data) => {
                  console.log(data);
                  setPlaybookTemplates(data || []);
                }),
              );
              dispatch(
                showAlert({
                  message: 'Template updated successfully',
                  showCross: true,
                  title: null,
                  type: 'success',
                  autoHideDuration: 2000,
                  vertical: 'top',
                  horizontal: 'center',
                }),
              );
            }
          },
        ),
      );
    }
  };

  const handleCreateOrUpdateplaybook = (action) => {
    console.log(action);
    if (action === 'create') {
      setCreatingOrUpdatingPlaybook(true);
      dispatch(
        createPlaybook(
          {
            tags: tags,
            templateId: selectedTemplate,
            playbookStructure: {
              ...defaultPlaybookFields,
              customPlayBookFields: customPlaybookFields,
            },
          },
          (data, error) => {
            if (error) {
              setCreatingOrUpdatingPlaybook(false);
              dispatch(
                showAlert({
                  message: 'Failed to create playbook. Please try again later!',
                  showCross: true,
                  title: null,
                  type: 'error',
                  autoHideDuration: 2000,
                  vertical: 'top',
                  horizontal: 'center',
                }),
              );
            } else {
              setCreatingOrUpdatingPlaybook(false);
              setShowEditPlaybookDialog(null);
              dispatch(
                getPlaybooks((data) => {
                  console.log(data);
                  setPlaybooks(data?.history || []);
                }),
              );
              dispatch(
                showAlert({
                  message: 'Playbook created successfully',
                  showCross: true,
                  title: null,
                  type: 'success',
                  autoHideDuration: 2000,
                  vertical: 'top',
                  horizontal: 'center',
                }),
              );
            }
          },
        ),
      );
    } else if (action === 'update') {
      setCreatingOrUpdatingPlaybook(true);
      dispatch(
        updateTemplatePlaybook(
          showEditPlaybookDialog?.id,
          {
            tags: tags,
            templateId: selectedTemplate,
            playbookStructure: {
              ...defaultPlaybookFields,
              customPlayBookFields: customPlaybookFields,
            },
          },
          (data, error) => {
            if (error) {
              setCreatingOrUpdatingPlaybook(false);
              dispatch(
                showAlert({
                  message: 'Failed to create playbook. Please try again later!',
                  showCross: true,
                  title: null,
                  type: 'error',
                  autoHideDuration: 2000,
                  vertical: 'top',
                  horizontal: 'center',
                }),
              );
            } else {
              setCreatingOrUpdatingPlaybook(false);
              setShowEditPlaybookDialog(null);
              dispatch(
                getPlaybooks((data) => {
                  console.log(data);
                  setPlaybooks(data?.history || []);
                }),
              );
              dispatch(
                showAlert({
                  message: 'Playbook created successfully',
                  showCross: true,
                  title: null,
                  type: 'success',
                  autoHideDuration: 2000,
                  vertical: 'top',
                  horizontal: 'center',
                }),
              );
            }
          },
        ),
      );
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit,
  });

  const formikInvite = useFormik({
    initialValues: initialInviteFormValues,
    validationSchema: validationSchemaForInviteForm,
    onSubmit: onInviteFormSubmit,
  });

  const formikPlaybook = useFormik({
    initialValues: initialValuesPlaybook,
    validationSchema: validationSchemaPlaybook,
    onSubmit: onSubmitPlaybook,
  });

  const handleDomainChange = async (event, type) => {
    await formik.setFieldValue(
      type,
      event.target.value.replace(/^(https:\/\/|http:\/\/)/, ''),
    );
    try {
      const value = await validateURL(
        `https://${event.target.value.replace(/^(https:\/\/|http:\/\/)/, '')}`,
      );
      console.log(value);
      await formik.setErrors({
        ...formik.errors,
        [type]: false,
      });
    } catch (e) {
      console.log(e?.message);
      await formik.setErrors({
        ...formik.errors,
        [type]: e?.message,
      });
    }
  };

  const handleListScroll = (e, paginate) => {
    if (
      (e.target.scrollTop + e.target.clientHeight + 1 >=
        e.target.scrollHeight &&
        // viewType === 'list' &&
        !allTeamMembers?.fetching &&
        !allTeamMembers?.fetchingPagination &&
        allTeamMembers?.members &&
        allTeamMembers?.members.length % 25 === 0 &&
        allTeamMembers?.emptyList === false) ||
      paginate
    ) {
      dispatch(
        getAllTeamMembers(currentOrgId, 25, allTeamMembers?.lastSeen, () => {}),
      );
    }
  };

  const handleInvitationsListScroll = (e, paginate) => {
    if (
      (e.target.scrollTop + e.target.clientHeight + 1 >=
        e.target.scrollHeight &&
        // viewType === 'list' &&
        !fetching &&
        !fetchingPagination &&
        invitations &&
        invitations.length % 25 === 0 &&
        emptyList === false) ||
      paginate
    ) {
      dispatch(getAllTeamInvitations(currentOrgId, 25, lastSeen, () => {}));
    }
  };

  useEffect(() => {
    if (currentTab === 'customers' && userDetails) {
      dispatch(
        getWlCustomers(userDetails?.organizationId, (data) => {
          console.log(data);
          // setCustomers(data);
        }),
      );
      if (showAddCustomerDialog) {
        dispatch(getWlOrgPlans((plans) => setPlans(plans)));
        dispatch(getPlaybookTemplates((data) => setPlaybookTemplates(data)));
        dispatch(getPlaybooks((data) => setPlaybooks(data?.history || [])));
      }
    } else if (currentTab === 'plans') {
      dispatch(getWlOrgPlans((plans) => setPlans(plans)));
    }
  }, [currentTab, userDetails, showAddCustomerDialog]);

  useEffect(() => {
    if (currentTeamsTab === 'invitations') {
      dispatch(getAllTeamInvitations(currentOrgId, 25, null, () => {}));
    } else if (currentTeamsTab === 'memebrs') {
      dispatch(getAllTeamMembers(currentOrgId, 25, null, () => {}));
    } else if (currentTeamsTab === 'active-plan') {
      dispatch(getWlOrgPlans((plans) => setPlans(plans)));
    }
  }, [currentTeamsTab]);

  useEffect(() => {
    if (viewType === 'teams') {
      dispatch(getAllTeamMembers(currentOrgId, 25, null, (data) => {}));
    }
  }, [viewType]);

  useEffect(() => {
    if (params && params.get('t')) {
      setCurrentTab(params.get('t'));
    } else {
      setCurrentTab('details');
    }
  }, [window.location.href]);

  useEffect(() => {
    if (currentNavigationLevel === 1) {
      // setCurrentTab('customers');
      setViewType('customers');
    } else if (currentNavigationLevel === 2) {
      // setCurrentTab('customers');
      setViewType('teams');
    }
  }, [currentNavigationLevel]);

  useEffect(() => {
    if (userDetails) {
      formik.setValues({
        appTitle: userDetails?.custom?.appTitle,
        appSubTitle: userDetails?.custom?.appSubTitle,
        organizationWebsite: userDetails?.custom?.organizationWebsite,
        whitelabelDomain: userDetails?.custom?.whitelabelDomain,
        metaDescription: userDetails?.custom?.metaDescription,
        textInLogo: !userDetails?.custom?.textInLogo,
      });
    }
  }, [userDetails]);

  useEffect(() => {
    if (!showBackButtonOnTopbar) {
      setViewType('customers');
      setCurrentTeamsTab('members');
    }
  }, [showBackButtonOnTopbar]);

  useEffect(() => {
    if (
      orgConfigs?.methodologies &&
      Array.isArray(orgConfigs?.methodologies) &&
      orgConfigs?.methodologies?.length > 0
    ) {
      if (
        orgConfigs?.customMethodologies &&
        Array.isArray(orgConfigs?.customMethodologies) &&
        orgConfigs?.customMethodologies?.length > 0
      ) {
        formikInvite.setFieldValue('methodologies', [
          ...(orgConfigs?.methodologies || []),
          ...(orgConfigs?.customMethodologies || []),
        ]);
      } else {
        setMethodologies(orgConfigs?.methodologies);
      }
    }
  }, [orgConfigs]);

  useEffect(() => {
    if (
      viewType === 'teams' ||
      viewType === 'dashboard' ||
      viewType === 'practiceCalls' ||
      viewType === 'salesCalls'
    ) {
      dispatch({
        type: 'UPDATE_UI_STATE',
        payload: {
          key: 'showBackButtonOnTopbar',
          value: true,
        },
      });
    } else {
      if (currentNavigationLevel === 1) {
        dispatch({
          type: 'UPDATE_UI_STATE',
          payload: {
            key: 'showBackButtonOnTopbar',
            value: false,
          },
        });
      }
    }
  }, [viewType, currentNavigationLevel]);

  useEffect(() => {
    if (showEditContentScoringConfig) {
      if (currentOrgId) {
        setSameConfig(
          deepEqual(
            customers?.find((org) => org?.id === currentOrgId)
              ?.contentScoringConfig?.inbound,
            customers?.find((org) => org?.id === currentOrgId)
              ?.contentScoringConfig?.outbound,
          ),
        );
        if (
          customers?.find((org) => org?.id === currentOrgId)
            ?.contentScoringConfig?.inbound
        ) {
          setContentScoringConfig((prev) => {
            return {
              ...prev,
              inbound: customers?.find((org) => org?.id === currentOrgId)
                ?.contentScoringConfig?.inbound,
            };
          });
          setContentScoreConfigText((prev) => {
            return {
              ...prev,
              inbound: JSON.stringify(
                Object.keys(
                  customers?.find((org) => org?.id === currentOrgId)
                    ?.contentScoringConfig?.inbound,
                )?.reduce((acc, curr) => {
                  acc[curr] = customers?.find(
                    (org) => org?.id === currentOrgId,
                  )?.contentScoringConfig?.inbound?.[curr];
                  return acc;
                }, {}),
                null,
                2,
              ),
            };
          });
        } else {
          dispatch(
            getContentScoringConfigTemplate((data) => {
              setContentScoringConfig((prev) => {
                return {
                  ...prev,
                  inbound: data?.callType?.inbound,
                };
              });
              setContentScoreConfigText((prev) => {
                return {
                  ...prev,
                  inbound: JSON.stringify(data?.callType?.inbound, null, 2),
                };
              });
            }),
          );
        }
        if (
          customers?.find((org) => org?.id === currentOrgId)
            ?.contentScoringConfig?.outbound
        ) {
          setContentScoringConfig((prev) => {
            return {
              ...prev,
              outbound: customers?.find((org) => org?.id === currentOrgId)
                ?.contentScoringConfig?.outbound,
            };
          });
          setContentScoreConfigText((prev) => {
            return {
              ...prev,
              outbound: JSON.stringify(
                Object.keys(
                  customers?.find((org) => org?.id === currentOrgId)
                    ?.contentScoringConfig?.outbound,
                )?.reduce((acc, curr) => {
                  acc[curr] = customers?.find(
                    (org) => org?.id === currentOrgId,
                  )?.contentScoringConfig?.outbound?.[curr];
                  return acc;
                }, {}),
                null,
                2,
              ),
            };
          });
        } else {
          dispatch(
            getContentScoringConfigTemplate((data) => {
              setContentScoringConfig((prev) => {
                return {
                  ...prev,
                  outbound: data?.callType?.outbound,
                };
              });
              setContentScoreConfigText((prev) => {
                return {
                  ...prev,
                  outbound: JSON.stringify(data?.callType?.outbound, null, 2),
                };
              });
            }),
          );
        }
      } else {
        setSameConfig(
          deepEqual(
            orgConfigs?.contentScoringConfig?.inbound,
            orgConfigs?.contentScoringConfig?.outbound,
          ),
        );
        if (orgConfigs?.contentScoringConfig?.inbound) {
          setContentScoringConfig((prev) => {
            return {
              ...prev,
              inbound: orgConfigs?.contentScoringConfig?.inbound,
            };
          });
          setContentScoreConfigText((prev) => {
            return {
              ...prev,
              inbound: JSON.stringify(
                Object.keys(orgConfigs?.contentScoringConfig?.inbound)?.reduce(
                  (acc, curr) => {
                    acc[curr] =
                      orgConfigs?.contentScoringConfig?.inbound?.[curr];
                    return acc;
                  },
                  {},
                ),
                null,
                2,
              ),
            };
          });
        } else {
          dispatch(
            getContentScoringConfigTemplate((data) => {
              setContentScoringConfig((prev) => {
                return {
                  ...prev,
                  inbound: data?.callType?.inbound,
                };
              });
              setContentScoreConfigText((prev) => {
                return {
                  ...prev,
                  inbound: JSON.stringify(data?.callType?.inbound, null, 2),
                };
              });
            }),
          );
        }
        if (orgConfigs?.contentScoringConfig?.outbound) {
          setContentScoringConfig((prev) => {
            return {
              ...prev,
              outbound: orgConfigs?.contentScoringConfig?.outbound,
            };
          });
          setContentScoreConfigText((prev) => {
            return {
              ...prev,
              outbound: JSON.stringify(
                Object.keys(orgConfigs?.contentScoringConfig?.outbound)?.reduce(
                  (acc, curr) => {
                    acc[curr] =
                      orgConfigs?.contentScoringConfig?.outbound?.[curr];
                    return acc;
                  },
                  {},
                ),
                null,
                2,
              ),
            };
          });
        } else {
          dispatch(
            getContentScoringConfigTemplate((data) => {
              setContentScoringConfig((prev) => {
                return {
                  ...prev,
                  outbound: data?.callType?.outbound,
                };
              });
              setContentScoreConfigText((prev) => {
                return {
                  ...prev,
                  outbound: JSON.stringify(data?.callType?.outbound, null, 2),
                };
              });
            }),
          );
        }
      }
    }
  }, [showEditContentScoringConfig, currentOrgId]);

  useEffect(() => {
    if (currentOrgId) {
      setCountry(
        customers?.find((org) => org?.id === currentOrgId)?.countries?.[0] ||
          'united states',
      );
      setOrganizationName(
        customers?.find((org) => org?.id === currentOrgId)?.organizationName ||
          '',
      );
      if (
        customers?.find((org) => org?.id === currentOrgId)?.organizationName
      ) {
        setTags([
          customers?.find((org) => org?.id === currentOrgId)?.organizationName,
        ]);
      } else {
        setTags([]);
      }
      setCurrentClientPlan(
        customers?.find((org) => org?.id === currentOrgId)?.plan,
      );
      setDefaultPlaybookFields(
        Object?.keys(
          customers?.find((org) => org?.id === currentOrgId)
            ?.playbookStructure || {},
        )
          ?.filter(
            (key) =>
              key !== 'customPlayBookFields' &&
              customers?.find((org) => org?.id === currentOrgId)
                ?.playbookStructure?.[key] !== null,
          )
          ?.reduce((acc, curr) => {
            acc[curr] =
              customers?.find((org) => org?.id === currentOrgId)
                ?.playbookStructure?.[curr] || '';
            return acc;
          }, {}),
      );
      setCustomPlaybookFields(
        customers
          ?.find((org) => org?.id === currentOrgId)
          ?.playbookStructure?.customPlayBookFields?.map((field) => {
            return {
              ...field,
              content:
                customers
                  ?.find((org) => org?.id === currentOrgId)
                  ?.playbookStructure?.customPlayBookFields?.find(
                    (customField) => customField?.title === field?.title,
                  )?.content || '',
            };
          }) || [],
      );
      setMethodologies(
        customers?.find((org) => org?.id === currentOrgId)?.methodologies || [],
      );
      setWithContentScoringConfig(
        customers?.find((org) => org?.id === currentOrgId)
          ?.contentScoringConfig || false,
      );
    }
  }, [currentOrgId]);

  useEffect(() => {
    if (showQuestionDialog !== 'create') {
      setQuestion(showQuestionDialog);
    }
  }, [showQuestionDialog]);

  useEffect(() => {
    if (
      formikInvite.values.planId &&
      formikInvite.values.planId !== '-' &&
      plans &&
      plans.length > 0
    ) {
      setClientPlanPrice(
        plans?.find((plan) => plan?.id === formikInvite.values.planId)?.price
          ?.amount || 0,
      );
      dispatch(
        getAddons(formikInvite.values.planId, (addons) => {
          console.log(addons);
          setAddOns(addons);
        }),
      );
    } else {
      setAddOns([]);
    }
  }, [formikInvite.values.planId, plans]);

  useEffect(() => {
    if (addOns && addOns?.length > 0) {
      setAddOnsPrice(
        addOns?.reduce((acc, curr) => {
          if (acc[curr?.feature?.feature] === undefined) {
            acc[curr?.feature?.feature] = curr?.price?.amount;
          }
          return acc;
        }, {}),
      );
    }
  }, [addOns]);

  useEffect(() => {
    if (
      formikInvite.values.planId &&
      formikInvite.values.planId !== 'select' &&
      plans &&
      plans.length > 0
    ) {
      setPlanPrice(
        plans.find((plan) => plan?.id === formikInvite.values.planId)?.price
          ?.amount || 0,
      );
      setTrailPeriodDays(
        plans.find((plan) => plan?.id === formikInvite.values.planId)
          ?.trailPeriodDays || 14,
      );
    }
  }, [formikInvite.values.planId, plans]);

  useEffect(() => {
    if (
      formikInvite.values.planId &&
      formikInvite.values.planId !== 'select' &&
      plans &&
      plans.length > 0
    ) {
      if (planType !== 'trial') {
        setTrailPeriodDays(
          plans.find((plan) => plan?.id === formikInvite.values.planId)
            ?.trailPeriodDays || 14,
        );
      } else {
        setTrailPeriodDays(14);
      }
    }
  }, [formikInvite.values.planId, plans, planType]);

  useEffect(() => {
    if (currentPlaybooksTab === 'templates') {
      dispatch(
        getPlaybookTemplates((data) => {
          console.log(data);
          setPlaybookTemplates(data || []);
        }),
      );
    } else if (currentPlaybooksTab === 'playbooks') {
      dispatch(
        getPlaybooks((data) => {
          setPlaybooks(data?.history || []);
        }),
      );
    }
  }, [currentPlaybooksTab]);

  useEffect(() => {
    if (showEditTemplateDialog) {
      if (showEditTemplateDialog === 'new') {
        setDefaultPlaybookFields(
          Object.keys(defaultPlaybookFieldsMapping || {})?.reduce(
            (acc, curr) => {
              acc[curr] = '';
              return acc;
            },
            {},
          ),
        );
        setCustomPlaybookFields([]);
      } else {
        setTemplateTitle(showEditTemplateDialog?.title);
        setDefaultPlaybookFields(
          Object.keys(defaultPlaybookFieldsMapping || {})
            ?.filter((key) =>
              Object.keys(
                showEditTemplateDialog?.defaultPlaybookFields || {},
              )?.includes(key),
            )
            ?.reduce((acc, curr) => {
              acc[curr] = '';
              return acc;
            }, {}),
        );
        setCustomPlaybookFields(
          showEditTemplateDialog?.customPlayBookFields || [],
        );
      }
    }
  }, [showEditTemplateDialog]);

  useEffect(() => {
    if (showTemplatePreview) {
      setTemplateTitle(showTemplatePreview?.title);
      setDefaultPlaybookFields(
        Object.keys(defaultPlaybookFieldsMapping || {})
          ?.filter((key) =>
            Object.keys(
              showTemplatePreview?.defaultPlaybookFields || {},
            )?.includes(key),
          )
          ?.reduce((acc, curr) => {
            acc[curr] = '';
            return acc;
          }, {}),
      );
      setCustomPlaybookFields(showTemplatePreview?.customPlayBookFields || []);
    }
  }, [showTemplatePreview]);

  useEffect(() => {
    if (showEditPlaybookDialog && showEditPlaybookDialog !== 'new') {
      setTags(showEditPlaybookDialog?.tags);
      if (showEditPlaybookDialog?.templateId) {
        setSelectedTemplate(showEditPlaybookDialog?.templateId);
      } else {
        if (playbookTemplates && playbookTemplates?.length > 0) {
          setSelectedTemplate(
            playbookTemplates?.find((template) => template?.defaultTemplate)
              ?.id,
          );
        } else {
          setSelectedTemplate('-');
        }
      }
    } else if (showEditPlaybookDialog && showEditPlaybookDialog === 'new') {
      if (playbookTemplates && playbookTemplates?.length > 0) {
        setSelectedTemplate(
          playbookTemplates?.find((template) => template?.defaultTemplate)?.id,
        );
      } else {
        setSelectedTemplate('-');
      }
    }
  }, [showEditPlaybookDialog, playbookTemplates]);

  useEffect(() => {
    if (showEditPlaybookDialog && showEditPlaybookDialog === 'new') {
      if (selectedTemplate && selectedTemplate !== '-') {
        setDefaultPlaybookFields(
          playbookTemplates?.find(
            (template) => template?.id === selectedTemplate,
          )?.defaultPlaybookFields,
        );
        setCustomPlaybookFields(
          playbookTemplates?.find(
            (template) => template?.id === selectedTemplate,
          )?.customPlayBookFields || [],
        );
      } else if (selectedTemplate && selectedTemplate === '-') {
        setDefaultPlaybookFields(
          Object.keys(defaultPlaybookFieldsMapping || {})?.reduce(
            (acc, curr) => {
              acc[curr] = '';
              return acc;
            },
            {},
          ),
        );
        setCustomPlaybookFields([]);
      } else {
        setDefaultPlaybookFields(
          Object.keys(defaultPlaybookFieldsMapping || {})?.reduce(
            (acc, curr) => {
              acc[curr] = '';
              return acc;
            },
            {},
          ),
        );
        setCustomPlaybookFields([]);
      }
    } else if (showEditPlaybookDialog && showEditPlaybookDialog !== 'new') {
      if (selectedTemplate && selectedTemplate !== '-') {
        if (
          playbookTemplates?.find(
            (template) => template?.id === selectedTemplate,
          )
        ) {
          setDefaultPlaybookFields(
            Object?.keys(
              playbookTemplates?.find(
                (template) => template?.id === selectedTemplate,
              )?.defaultPlaybookFields || {},
            )?.reduce((acc, curr) => {
              acc[curr] =
                showEditPlaybookDialog?.playbookStructure?.[curr] || '';
              return acc;
            }, {}),
          );
          setCustomPlaybookFields(
            playbookTemplates
              ?.find((template) => template?.id === selectedTemplate)
              ?.customPlayBookFields?.map((field) => {
                return {
                  ...field,
                  content:
                    showEditPlaybookDialog?.playbookStructure?.customPlayBookFields?.find(
                      (customField) => customField?.title === field?.title,
                    )?.content || '',
                };
              }) || [],
          );
        } else {
          setDefaultPlaybookFields(
            Object.keys(defaultPlaybookFieldsMapping || {})?.reduce(
              (acc, curr) => {
                acc[curr] = '';
                return acc;
              },
              {},
            ),
          );
          setCustomPlaybookFields([]);
        }
      } else if (selectedTemplate && selectedTemplate === '-') {
        setDefaultPlaybookFields(
          Object.keys(defaultPlaybookFieldsMapping || {})?.reduce(
            (acc, curr) => {
              acc[curr] = '';
              return acc;
            },
            {},
          ),
        );
        setCustomPlaybookFields([]);
      } else {
        setDefaultPlaybookFields(
          Object.keys(defaultPlaybookFieldsMapping || {})?.reduce(
            (acc, curr) => {
              acc[curr] = '';
              return acc;
            },
            {},
          ),
        );
        setCustomPlaybookFields([]);
      }
    }
  }, [selectedTemplate, showEditPlaybookDialog]);

  useEffect(() => {
    if (showAddCustomerDialog) {
      if (playbookTemplates && playbookTemplates?.length > 0) {
        setSelectedTemplate(
          playbookTemplates?.find((template) => template?.defaultTemplate)?.id,
        );
      } else {
        setSelectedTemplate('-');
      }
      if (playbooks && playbooks?.length > 0) {
        setSelectedPlaybook(playbooks[0]?.id);
      } else {
        setSelectedPlaybook('-');
      }
    }
  }, [showAddCustomerDialog, playbookTemplates, playbooks]);

  useEffect(() => {
    if (
      selectedPlaybook &&
      selectedPlaybook !== '-' &&
      selectedTemplate &&
      selectedTemplate !== '-'
    ) {
      setDefaultPlaybookFields(
        Object?.keys(
          playbookTemplates?.find(
            (template) => template?.id === selectedTemplate,
          )?.defaultPlaybookFields || {},
        )?.reduce((acc, curr) => {
          acc[curr] =
            playbooks?.find((pb) => pb?.id === selectedPlaybook)
              ?.playbookStructure?.[curr] || '';
          return acc;
        }, {}),
      );
      setCustomPlaybookFields(
        playbookTemplates
          ?.find((template) => template?.id === selectedTemplate)
          ?.customPlayBookFields?.map((field) => {
            return {
              ...field,
              content:
                playbooks
                  ?.find((pb) => pb?.id === selectedPlaybook)
                  ?.playbookStructure?.customPlayBookFields?.find(
                    (customField) => customField?.title === field?.title,
                  )?.content || '',
            };
          }) || [],
      );
    } else {
      setDefaultPlaybookFields(
        Object.keys(defaultPlaybookFieldsMapping || {})?.reduce((acc, curr) => {
          acc[curr] = '';
          return acc;
        }, {}),
      );
      setCustomPlaybookFields([]);
    }
  }, [selectedPlaybook, selectedTemplate]);

  useEffect(() => {
    dispatch(
      getDefaultPrompt((data) => {
        console.log(data);
        setVerticalOptions(data?.verticals);
        setDesignationOptions(data?.designations);
      }),
    );
    dispatch(getUserDetails((data) => {}));
    dispatch(getOrganizationConfigs((data) => {}));
    return () => {
      dispatch({
        type: 'UPDATE_UI_STATE',
        payload: {
          key: 'currentNavigationLevel',
          value: 1,
        },
      });
      dispatch({
        type: 'UPDATE_UI_STATE',
        payload: {
          key: 'showBackButtonOnTopbar',
          value: false,
        },
      });
    };
  }, []);

  console.log(selectedTemplate);
  console.log(showEditPlaybookDialog);
  console.log('defaultPlaybookFields', defaultPlaybookFields);
  console.log('customPlaybookFields', customPlaybookFields);

  return (
    <Box
      sx={{
        width: {
          xs: '100%',
          sm: isLoggedIn ? 'calc(100% - 80px)' : 1,
          md: isLoggedIn
            ? dashboardSidebarExpanded
              ? 'calc(100% - 240px)'
              : 'calc(100% - 80px)'
            : 1,
        },
        marginLeft: {
          xs: '0px',
          sm: isLoggedIn ? '80px' : 0,
          md: isLoggedIn ? (dashboardSidebarExpanded ? '240px' : '80px') : 0,
        },
        height: 1,
        minHeight: isLoggedIn ? 'calc(100vh - 60px)' : 'calc(100vh - 146px)',
      }}
    >
      <Container
        paddingX={
          isLoggedIn
            ? dashboardSidebarExpanded
              ? 2
              : { lg: 16, md: 8, sm: 4, xs: 1 }
            : 8
        }
        paddingY={{ lg: 2, md: 2, sm: 2, xs: 3 }}
        maxWidth={{ xs: 1, sm: 1, md: isLoggedIn ? 1 : 1236 }}
      >
        {/* <Paper
          sx={{
            border: `1px solid ${theme.palette.background.level1}`,
            // borderRadius: 2,
            // minHeight: 200,
            // marginTop: 2,
          }}
          // elevation={0.3}
        > */}
        <Box>
          {isXs ? (
            <Tabs
              value={currentTab}
              onChange={(e, newValue) => setCurrentTab(newValue)}
              aria-label="Settings Tabs"
              orientation={'horizontal'}
              // indicatorColor={theme.palette.background.default}
              variant="scrollable"
              // scrollButtons={!isMd}
              allowScrollButtonsMobile
              sx={{
                borderBottom: '1px solid #d3d3d3',
              }}
            >
              <Tab
                value={'details'}
                label={
                  <Typography variant="body1">Whitelabel Details</Typography>
                }
                id={`simple-tab-${0}`}
                aria-controls={`simple-tabpanel-${0}`}
                sx={{}}
              />
            </Tabs>
          ) : null}
          {viewType === 'dashboard' ? (
            <PerformanceDashboard
              uId={currentMemberId}
              member={allTeamMembers?.members?.find(
                (member) => member?.id === currentMemberId,
              )}
            />
          ) : viewType === 'practiceCalls' ? (
            <ConversationAIMain
              parentComponent="customerSettings"
              uId={currentMemberId}
              member={allTeamMembers?.members?.find(
                (member) => member?.id === currentMemberId,
              )}
            />
          ) : viewType === 'salesCalls' ? (
            <SalesCallAnalysis
              parentComponent="customerSettings"
              uId={currentMemberId}
              member={allTeamMembers?.members?.find(
                (member) => member?.id === currentMemberId,
              )}
            />
          ) : (
            <>
              {currentTab === 'details' ? (
                <>
                  <Box display={'flex'}>
                    <form onSubmit={formik.handleSubmit}>
                      <Box
                        display={'flex'}
                        flexDirection={'column'}
                        gap={2}
                        alignItems={'start'}
                        p={2}
                        width={1}
                      >
                        <Typography variant="body1" color={'text.primary'}>
                          These fields are required for showing your clients the
                          appropriate details when the access the product.
                        </Typography>
                        <Box
                          display={'flex'}
                          flexDirection={'column'}
                          gap={2}
                          alignItems={'start'}
                          border={'1px solid #d3d3d3'}
                          borderRadius={2}
                          p={2}
                          position={'relative'}
                          pt={3}
                        >
                          <Box
                            // width={1}
                            display={'flex'}
                            alignItems={'center'}
                            gap={2}
                            justifyContent={'space-between'}
                            position={'absolute'}
                            sx={{
                              top: -16,
                              background: '#fff',
                              padding: 1,
                              left: 10,
                            }}
                          >
                            <Typography
                              variant="subtitle2"
                              fontWeight={'bold'}
                              // color={'text.secondary'}
                            >
                              Your Whitelabel Product Details
                            </Typography>
                          </Box>
                          <Grid container spacing={1}>
                            <Grid item xs={12}>
                              <Box display={'flex'}>
                                <Box
                                  flex={1}
                                  display={'flex'}
                                  gap={1}
                                  flexDirection={'column'}
                                >
                                  <Box display={'flex'} gap={1} flex={1}>
                                    <Box
                                      display={'flex'}
                                      flex={1}
                                      flexDirection={'column'}
                                    >
                                      <Typography
                                        variant={'subtitle2'}
                                        color={'text.secondary'}
                                        sx={{
                                          marginBottom: 1,
                                          fontWeight: 'bold',
                                        }}
                                      >
                                        App Title *
                                      </Typography>
                                      <TextField
                                        placeholder="Enter your app title *"
                                        variant="outlined"
                                        name={'appTitle'}
                                        fullWidth
                                        size="small"
                                        value={formik.values.appTitle}
                                        onChange={formik.handleChange}
                                        error={
                                          formik.touched.appTitle &&
                                          Boolean(formik.errors.appTitle)
                                        }
                                        helperText={
                                          formik.touched.appTitle &&
                                          formik.errors.appTitle
                                        }
                                        type={'text'}
                                      />
                                      <Typography
                                        variant={'subtitle2'}
                                        color={'text.secondary'}
                                        sx={{
                                          marginBottom: 1,
                                          fontWeight: 'bold',
                                        }}
                                      >
                                        App Tagline *
                                      </Typography>
                                      <TextField
                                        placeholder="Enter App Tagline*"
                                        variant="outlined"
                                        name={'appSubTitle'}
                                        fullWidth
                                        size="small"
                                        value={formik.values.appSubTitle}
                                        onChange={formik.handleChange}
                                        error={
                                          formik.touched.appSubTitle &&
                                          Boolean(formik.errors.appSubTitle)
                                        }
                                        helperText={
                                          formik.touched.appSubTitle &&
                                          formik.errors.appSubTitle
                                        }
                                        type={'text'}
                                      />
                                    </Box>
                                    <Box
                                      display={'flex'}
                                      flexDirection={'row'}
                                      gap={1}
                                    >
                                      <Divider
                                        orientation="vertical"
                                        sx={{
                                          height: 1,
                                        }}
                                      />
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            size="small"
                                            checked={formik.values.textInLogo}
                                            name="textInLogo"
                                          />
                                        }
                                        label={
                                          <Typography variant="caption">
                                            Include these text in Logo
                                          </Typography>
                                        }
                                        onChange={formik.handleChange}
                                      />
                                    </Box>
                                  </Box>
                                  <Box
                                    display={'flex'}
                                    alignItems={'end'}
                                    gap={2}
                                  >
                                    <Box
                                      position={'relative'}
                                      display={'flex'}
                                      flexDirection={'column'}
                                      gap={1}
                                    >
                                      <Typography
                                        variant={'subtitle2'}
                                        color={'text.secondary'}
                                        sx={{ fontWeight: 'bold' }}
                                      >
                                        Favicon
                                      </Typography>
                                      {favicon ? (
                                        <Avatar
                                          variant="square"
                                          alt={'Favicon'}
                                          src={URL.createObjectURL(favicon)}
                                          sx={{
                                            width: '100%',
                                            height: 'auto',
                                            background: '#fff',
                                            border: '1px solid #d3d3d3',
                                            boxShadow: '0 0 5px #d3d3d3',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            maxWidth: 120,
                                          }}
                                        >
                                          <Typography
                                            variant="caption"
                                            fontWeight={'bold'}
                                            color={'text.secondary'}
                                          >
                                            {'Favicon'}
                                          </Typography>
                                        </Avatar>
                                      ) : (
                                        <Avatar
                                          variant="square"
                                          alt={'Favicon'}
                                          src={userDetails?.custom?.favicon}
                                          sx={{
                                            width: '100%',
                                            height: 'auto',
                                            background: '#fff',
                                            border: '1px solid #d3d3d3',
                                            boxShadow: '0 0 5px #d3d3d3',
                                            display: 'flex',
                                            maxWidth: 120,
                                            flexDirection: 'column',
                                          }}
                                        >
                                          <Typography
                                            variant="caption"
                                            fontWeight={'bold'}
                                            color={'text.secondary'}
                                          >
                                            {'Favicon'}
                                          </Typography>
                                        </Avatar>
                                      )}
                                      <Box
                                        display={'flex'}
                                        alignItems={'center'}
                                        gap={2}
                                      >
                                        <InputLabel
                                          htmlFor="profileImg1"
                                          sx={{
                                            zIndex: 1,
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            gap: 0.5,
                                            border: `1px solid ${theme.palette.primary.main}`,
                                            borderRadius: 1,
                                            padding: '8px 16px',
                                            cursor: 'pointer',
                                            // width: 1,
                                          }}
                                        >
                                          <CloudUpload
                                            sx={{
                                              fontSize: 14,
                                              color: theme.palette.primary.main,
                                            }}
                                          />
                                          <Typography
                                            variant="body2"
                                            color={theme.palette.primary.main}
                                          >
                                            {userDetails?.custom?.favicon
                                              ? 'Change'
                                              : 'Select'}
                                          </Typography>
                                        </InputLabel>
                                      </Box>
                                      <input
                                        style={{ display: 'none' }}
                                        id="profileImg1"
                                        ref={faviconRef}
                                        type="file"
                                        accept="image/*"
                                        onChange={onImageChange1}
                                      />
                                    </Box>
                                    {/* {formik.values.textInLogo ? null : ( */}
                                    <Box
                                      position={'relative'}
                                      display={'flex'}
                                      flexDirection={'column'}
                                      gap={1}
                                    >
                                      <Typography
                                        variant={'subtitle2'}
                                        color={'text.secondary'}
                                        sx={{ fontWeight: 'bold' }}
                                      >
                                        Logo with text
                                      </Typography>
                                      {logoWithText ? (
                                        <Avatar
                                          variant="square"
                                          alt={'App Logo'}
                                          src={URL.createObjectURL(
                                            logoWithText,
                                          )}
                                          sx={{
                                            width: '100%',
                                            height: 'auto',
                                            background: '#fff',
                                            border: '1px solid #d3d3d3',
                                            boxShadow: '0 0 5px #d3d3d3',
                                            display: 'flex',
                                            maxWidth: 120,
                                            flexDirection: 'column',
                                          }}
                                        >
                                          <Typography
                                            variant="caption"
                                            fontWeight={'bold'}
                                            color={'text.secondary'}
                                          >
                                            {'Logo with text'}
                                          </Typography>
                                        </Avatar>
                                      ) : (
                                        <Avatar
                                          variant="square"
                                          alt={'Logo with text'}
                                          src={userDetails?.custom?.logo}
                                          sx={{
                                            width: '100%',
                                            height: 'auto',
                                            background: '#fff',
                                            border: '1px solid #d3d3d3',
                                            boxShadow: '0 0 5px #d3d3d3',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            maxWidth: 120,
                                          }}
                                        >
                                          <Typography
                                            variant="caption"
                                            fontWeight={'bold'}
                                            color={'text.secondary'}
                                          >
                                            {'Logo with text'}
                                          </Typography>
                                        </Avatar>
                                      )}
                                      <Box
                                        display={'flex'}
                                        alignItems={'center'}
                                        gap={2}
                                      >
                                        <InputLabel
                                          htmlFor="profileImg"
                                          sx={{
                                            zIndex: 1,
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            gap: 0.5,
                                            border: `1px solid ${theme.palette.primary.main}`,
                                            borderRadius: 1,
                                            padding: '8px 16px',
                                            cursor: 'pointer',
                                            // width: 1,
                                          }}
                                        >
                                          <CloudUpload
                                            sx={{
                                              fontSize: 14,
                                              color: theme.palette.primary.main,
                                            }}
                                          />
                                          <Typography
                                            variant="body2"
                                            color={theme.palette.primary.main}
                                          >
                                            {userDetails?.custom?.logo
                                              ? 'Change'
                                              : 'Select'}
                                          </Typography>
                                        </InputLabel>
                                      </Box>
                                      <input
                                        style={{ display: 'none' }}
                                        id="profileImg"
                                        ref={logoWithTextRef}
                                        type="file"
                                        accept="image/*"
                                        onChange={onImageChange}
                                      />
                                    </Box>
                                    {/* )} */}
                                    <Box
                                      position={'relative'}
                                      display={'flex'}
                                      flexDirection={'column'}
                                      gap={1}
                                    >
                                      <Typography
                                        variant={'subtitle2'}
                                        color={'text.secondary'}
                                        sx={{ fontWeight: 'bold' }}
                                      >
                                        Logo without text
                                      </Typography>
                                      {logoWithoutText ? (
                                        <Avatar
                                          variant="square"
                                          alt={'Logo without text'}
                                          src={URL.createObjectURL(
                                            logoWithoutText,
                                          )}
                                          sx={{
                                            width: '100%',
                                            height: 'auto',
                                            background: '#fff',
                                            border: '1px solid #d3d3d3',
                                            boxShadow: '0 0 5px #d3d3d3',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            maxWidth: 120,
                                          }}
                                        >
                                          <Typography
                                            variant="caption"
                                            fontWeight={'bold'}
                                            color={'text.secondary'}
                                          >
                                            {'Logo without text'}
                                          </Typography>
                                        </Avatar>
                                      ) : (
                                        <Avatar
                                          variant="square"
                                          alt={'Logo without text'}
                                          src={
                                            userDetails?.custom?.logoWithoutText
                                          }
                                          sx={{
                                            width: '100%',
                                            height: 'auto',
                                            background: '#fff',
                                            border: '1px solid #d3d3d3',
                                            boxShadow: '0 0 5px #d3d3d3',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            maxWidth: 120,
                                          }}
                                        >
                                          <Typography
                                            variant="caption"
                                            fontWeight={'bold'}
                                            color={'text.secondary'}
                                          >
                                            {'Logo without text'}
                                          </Typography>
                                        </Avatar>
                                      )}
                                      <Box
                                        display={'flex'}
                                        alignItems={'center'}
                                        gap={2}
                                      >
                                        <InputLabel
                                          htmlFor="profileImg2"
                                          sx={{
                                            zIndex: 1,
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            gap: 0.5,
                                            border: `1px solid ${theme.palette.primary.main}`,
                                            borderRadius: 1,
                                            padding: '8px 16px',
                                            cursor: 'pointer',
                                            // width: 1,
                                          }}
                                        >
                                          <CloudUpload
                                            sx={{
                                              fontSize: 14,
                                              color: theme.palette.primary.main,
                                            }}
                                          />
                                          <Typography
                                            variant="body2"
                                            color={theme.palette.primary.main}
                                          >
                                            {userDetails?.custom
                                              ?.logoWithoutText
                                              ? 'Change'
                                              : 'Select'}
                                          </Typography>
                                        </InputLabel>
                                      </Box>
                                      <input
                                        style={{ display: 'none' }}
                                        id="profileImg2"
                                        ref={logoWithoutTextRef}
                                        type="file"
                                        accept="image/*"
                                        onChange={onImageChange2}
                                      />
                                    </Box>
                                  </Box>
                                </Box>
                                <Box flex={1}>
                                  <Box
                                    flex={1}
                                    display={'flex'}
                                    flexDirection={'column'}
                                    // justifyContent={'center'}
                                    // alignItems={'center'}
                                    gap={1}
                                    border={'1px solid #d3d3d3'}
                                    // p={1}
                                    position={'relative'}
                                  >
                                    <Typography
                                      sx={{
                                        background: '#d3d3d3',
                                        padding: 1,
                                      }}
                                      variant="subtitle2"
                                      fontWeight={'bold'}
                                      color={'text.secondary'}
                                      textAlign={'center'}
                                    >
                                      Logo Preview
                                    </Typography>
                                    <Box
                                      sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: 2,
                                        alignItems: 'center',
                                        p: 2,
                                      }}
                                    >
                                      {formik.values.textInLogo ? (
                                        <Box
                                          display={'flex'}
                                          flex={2}
                                          flexDirection={'column'}
                                          gap={1}
                                        >
                                          <Typography
                                            variant="subtitle2"
                                            color={'text.secondary'}
                                            fontWeight={'bold'}
                                            textAlign={'center'}
                                          >
                                            Logo with text
                                          </Typography>
                                          <Box
                                            display={'flex'}
                                            alignItems={'center'}
                                          >
                                            <Box
                                              display={'flex'}
                                              component="a"
                                              title={
                                                formik.values?.appTitle ||
                                                'Qualification AI'
                                              }
                                              width={{ xs: 50, md: 50 }}
                                              height={{ xs: 50, md: 50 }}
                                            >
                                              {logoWithoutText ? (
                                                <Box
                                                  component={'img'}
                                                  src={
                                                    theme.palette.mode ===
                                                    'dark'
                                                      ? '/dark_theme_logo.svg'
                                                      : logoWithoutText
                                                      ? URL.createObjectURL(
                                                          logoWithoutText,
                                                        )
                                                      : '/light_theme_logo.svg'
                                                  }
                                                  height={1}
                                                  width={1}
                                                />
                                              ) : userDetails?.custom
                                                  ?.logoWithoutText ? (
                                                <Box
                                                  component={'img'}
                                                  src={
                                                    theme.palette.mode ===
                                                    'dark'
                                                      ? '/dark_theme_logo.svg'
                                                      : userDetails?.custom
                                                          ?.logoWithoutText
                                                      ? userDetails?.custom
                                                          ?.logoWithoutText
                                                      : '/light_theme_logo.svg'
                                                  }
                                                  height={1}
                                                  width={1}
                                                />
                                              ) : (
                                                <Box
                                                  component={'div'}
                                                  height={1}
                                                  width={1}
                                                  display={'flex'}
                                                  justifyContent={'center'}
                                                  alignItems={'center'}
                                                  sx={{
                                                    background: '#d3d3d3',
                                                    borderRadius: '4px',
                                                    padding: 1,
                                                  }}
                                                >
                                                  <Typography
                                                    variant="caption"
                                                    fontWeight={'bold'}
                                                    color={'text.secoondary'}
                                                    textAlign={'center'}
                                                  >
                                                    App Logo
                                                  </Typography>
                                                </Box>
                                              )}
                                            </Box>
                                            <Box ml={2}>
                                              <Typography
                                                color={
                                                  formik.values?.appTitle
                                                    ? 'text.primary'
                                                    : 'text.secondary'
                                                }
                                                variant={
                                                  formik.values?.appTitle
                                                    ? 'h6'
                                                    : 'body1'
                                                }
                                                fontWeight={'600'}
                                              >
                                                {formik.values?.appTitle ||
                                                  'Public Facing App Name'}
                                              </Typography>
                                              <Divider
                                                sx={{
                                                  border: `0.5px solid ${theme.palette.text.primary}`,
                                                }}
                                              />
                                              <Typography
                                                variant="caption"
                                                color="text.primary"
                                              >
                                                {formik.values?.appSubTitle ||
                                                  'App Secondary Text or Tagline'}
                                              </Typography>
                                            </Box>
                                          </Box>
                                        </Box>
                                      ) : (
                                        <Box
                                          display={'flex'}
                                          flex={2}
                                          flexDirection={'column'}
                                          gap={1}
                                        >
                                          <Typography
                                            variant="subtitle2"
                                            color={'text.secondary'}
                                            fontWeight={'bold'}
                                            textAlign={'center'}
                                          >
                                            Logo with text
                                          </Typography>
                                          <Box
                                            display={'flex'}
                                            component="a"
                                            // href={
                                            //   `https://${formik.values.organizationWebsite}` ||
                                            //   'https://qualification.ai'
                                            // }
                                            // title={
                                            //   formik.values?.appTitle ||
                                            //   'Qualification AI'
                                            // }
                                            height={{ xs: 50, md: 50 }}
                                            // width={{ xs: 50, md: 50 }}
                                          >
                                            {logoWithText ? (
                                              <Box
                                                component={'img'}
                                                src={
                                                  theme.palette.mode === 'dark'
                                                    ? '/dark_theme_logo.svg'
                                                    : logoWithText
                                                    ? URL.createObjectURL(
                                                        logoWithText,
                                                      )
                                                    : '/light_theme_logo.svg'
                                                }
                                                height={1}
                                                width={1}
                                              />
                                            ) : userDetails?.custom?.logo ? (
                                              <Box
                                                component={'img'}
                                                src={
                                                  theme.palette.mode === 'dark'
                                                    ? '/dark_theme_logo.svg'
                                                    : userDetails?.custom?.logo
                                                    ? userDetails?.custom?.logo
                                                    : '/light_theme_logo.svg'
                                                }
                                                height={1}
                                                width={1}
                                              />
                                            ) : (
                                              <Box
                                                component={'div'}
                                                height={1}
                                                width={1}
                                                display={'flex'}
                                                justifyContent={'center'}
                                                alignItems={'center'}
                                                sx={{
                                                  background: '#d3d3d3',
                                                  borderRadius: '4px',
                                                  padding: 1,
                                                }}
                                              >
                                                <Typography
                                                  variant="caption"
                                                  fontWeight={'bold'}
                                                  color={'text.secoondary'}
                                                  textAlign={'center'}
                                                >
                                                  App Logo with Text
                                                </Typography>
                                              </Box>
                                            )}
                                          </Box>
                                        </Box>
                                      )}
                                      <Box
                                        display={'flex'}
                                        flex={1}
                                        flexDirection={'column'}
                                        alignItems={'center'}
                                        gap={1}
                                      >
                                        <Typography
                                          variant="subtitle2"
                                          color={'text.secondary'}
                                          fontWeight={'bold'}
                                          textAlign={'center'}
                                        >
                                          Without text
                                        </Typography>
                                        <Box
                                          display={'flex'}
                                          component="a"
                                          // href={
                                          //   `https://${formik.values.organizationWebsite}` ||
                                          //   'https://qualification.ai'
                                          // }
                                          // title={
                                          //   formik.values?.appTitle ||
                                          //   'Qualification AI'
                                          // }
                                          height={{ xs: 50, md: 50 }}
                                          width={{ xs: 50, md: 50 }}
                                        >
                                          {logoWithoutText ? (
                                            <Box
                                              component={'img'}
                                              src={
                                                theme.palette.mode === 'dark'
                                                  ? '/dark_theme_logo.svg'
                                                  : logoWithoutText
                                                  ? URL.createObjectURL(
                                                      logoWithoutText,
                                                    )
                                                  : '/light_theme_logo.svg'
                                              }
                                              height={1}
                                              width={1}
                                            />
                                          ) : userDetails?.custom
                                              ?.logoWithoutText ? (
                                            <Box
                                              component={'img'}
                                              src={
                                                theme.palette.mode === 'dark'
                                                  ? '/dark_theme_logo.svg'
                                                  : userDetails?.custom
                                                      ?.logoWithoutText
                                                  ? userDetails?.custom
                                                      ?.logoWithoutText
                                                  : '/light_theme_logo.svg'
                                              }
                                              height={1}
                                              width={1}
                                            />
                                          ) : (
                                            <Box
                                              component={'div'}
                                              height={1}
                                              width={1}
                                              display={'flex'}
                                              justifyContent={'center'}
                                              alignItems={'center'}
                                              sx={{
                                                background: '#d3d3d3',
                                                borderRadius: '4px',
                                                padding: 1,
                                              }}
                                            >
                                              <Typography
                                                variant="caption"
                                                fontWeight={'bold'}
                                                color={'text.secoondary'}
                                                textAlign={'center'}
                                              >
                                                App Logo
                                              </Typography>
                                            </Box>
                                          )}
                                        </Box>
                                      </Box>
                                    </Box>
                                  </Box>
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography
                                variant={'subtitle2'}
                                color={'text.secondary'}
                                sx={{ marginBottom: 1 }}
                              >
                                <span style={{ fontWeight: 'bold' }}>
                                  App Domain *
                                </span>
                                <br />
                                (This is the domain your clients will access to
                                use the whitelabel product)
                              </Typography>
                              <TextField
                                disabled
                                placeholder="Enter your app domain *"
                                variant="outlined"
                                name={'whitelabelDomain'}
                                fullWidth
                                size="small"
                                value={formik.values.whitelabelDomain}
                                onChange={(e) =>
                                  handleDomainChange(e, 'whitelabelDomain')
                                }
                                error={
                                  formik.touched.whitelabelDomain &&
                                  Boolean(formik.errors.whitelabelDomain)
                                }
                                helperText={
                                  formik.touched.whitelabelDomain &&
                                  formik.errors.whitelabelDomain
                                }
                                sx={{
                                  '& .MuiOutlinedInput-adornedStart': {
                                    paddingLeft: 0,
                                  },
                                }}
                                type={'text'}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment
                                      position="start"
                                      sx={{
                                        paddingLeft: 0,
                                        marginRight: 2,
                                        '& p': {
                                          // backgroundColor: 'rgba(0, 0, 0, 0.12)',
                                          padding: 0,
                                          paddingLeft: 0,
                                          lineHeight: 1.1,
                                        },
                                      }}
                                    >
                                      <Typography
                                        style={{
                                          color: formik.errors.whitelabelDomain
                                            ? colors.red[500]
                                            : colors.blue[500],
                                        }}
                                      >
                                        https://
                                      </Typography>
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <Typography
                                variant={'subtitle2'}
                                color={'text.secondary'}
                                sx={{ marginBottom: 1 }}
                              >
                                <span style={{ fontWeight: 'bold' }}>
                                  App Landing Page Website *
                                </span>
                                <br />
                                (This is the landing page of your product that
                                explains what your product is?)
                              </Typography>
                              <TextField
                                placeholder="Enter your app landing page website *"
                                variant="outlined"
                                name={'organizationWebsite'}
                                fullWidth
                                size="small"
                                value={formik.values.organizationWebsite}
                                onChange={(e) =>
                                  handleDomainChange(e, 'organizationWebsite')
                                }
                                error={
                                  formik.touched.organizationWebsite &&
                                  Boolean(formik.errors.organizationWebsite)
                                }
                                helperText={
                                  formik.touched.organizationWebsite &&
                                  formik.errors.organizationWebsite
                                }
                                type={'text'}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment
                                      position="start"
                                      sx={{
                                        paddingLeft: 0,
                                        marginRight: 2,
                                        '& p': {
                                          // backgroundColor: 'rgba(0, 0, 0, 0.12)',
                                          padding: 0,
                                          paddingLeft: 0,
                                          lineHeight: 1.1,
                                        },
                                      }}
                                    >
                                      <Typography
                                        style={{
                                          color: formik.errors
                                            .organizationWebsite
                                            ? colors.red[500]
                                            : colors.blue[500],
                                        }}
                                      >
                                        https://
                                      </Typography>
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </Grid>
                            {/* <Grid item xs={12}>
                          <Typography
                            variant={'subtitle2'}
                            color={'text.secondary'}
                            sx={{ marginBottom: 1 }}
                          >
                            <span style={{ fontWeight: 'bold' }}>
                              Meta Description *{' '}
                            </span>
                            <br />
                            (Please describe your product in detail. Please also
                            add URLs to specific webpages and shared documents
                            if any.)
                          </Typography>
                          <TextField
                            placeholder="Enter meta description for your app *"
                            variant="outlined"
                            name={'metaDescription'}
                            fullWidth
                            size="small"
                            multiline
                            rows={5}
                            value={formik.values.metaDescription}
                            onChange={formik.handleChange}
                            error={
                              formik.touched.metaDescription &&
                              Boolean(formik.errors.metaDescription)
                            }
                            helperText={
                              formik.touched.metaDescription &&
                              formik.errors.metaDescription
                            }
                            type="text"
                          />
                        </Grid> */}
                            {error && (
                              <Grid item xs={12}>
                                <Alert severity="error">{error}</Alert>
                              </Grid>
                            )}
                            {info && (
                              <Grid item xs={12}>
                                <Alert severity="success">{info}</Alert>
                              </Grid>
                            )}
                            <Grid item xs={12}>
                              <Button
                                variant="contained"
                                disabled={false}
                                type="submit"
                              >
                                {'Save'}
                              </Button>
                            </Grid>
                          </Grid>
                        </Box>
                      </Box>
                    </form>
                  </Box>
                </>
              ) : currentTab === 'customers' ? (
                viewType === 'customers' ? (
                  <>
                    {wlCustomers?.fetching ? (
                      <Box
                        width={1}
                        height={1}
                        display={'flex'}
                        justifyContent={'center'}
                        alignItems={'center'}
                        flexDirection={'column'}
                      >
                        <CircularProgress size={20} />
                        <Typography
                          variant="body1"
                          color={'text.primary'}
                          mt={4}
                        >
                          Loading all clients ...
                        </Typography>
                      </Box>
                    ) : customers && customers?.length > 0 ? (
                      <Box display={'flex'} gap={1} flexDirection={'column'}>
                        <Box
                          width={1}
                          display={'flex'}
                          alignItems={'center'}
                          justifyContent={'flex-end'}
                        >
                          <Button
                            variant="contained"
                            size="small"
                            onClick={() => setShowAddCustomerDialog(true)}
                          >
                            Invite Client
                          </Button>
                        </Box>
                        <TableContainer
                          component={Paper}
                          sx={
                            {
                              // marginTop: 1,
                              //   minHeight: 'calc(100vh - 160px)',
                              //   maxHeight: 'calc(100vh - 160px)',
                              //   overflowY: 'scroll',
                              //   '&::-webkit-scrollbar': {
                              //     width: '5px',
                              //   },
                              //   '&::-webkit-scrollbar-track': {
                              //     boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                              //     webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                              //   },
                              //   '&::-webkit-scrollbar-thumb': {
                              //     backgroundColor: theme.palette.primary.main,
                              //     borderRadius: 2,
                              //   },
                            }
                          }
                          // onScroll={handleListScroll}
                        >
                          <Table
                            sx={{ minWidth: 750 }}
                            stickyHeader
                            aria-label="simple table"
                          >
                            <TableHead sx={{ bgcolor: 'alternate.dark' }}>
                              <TableRow>
                                <TableCell>
                                  <Typography
                                    variant={'caption'}
                                    fontWeight={700}
                                    sx={{ textTransform: 'uppercase' }}
                                  >
                                    Client
                                  </Typography>
                                </TableCell>
                                <TableCell align="center">
                                  <Typography
                                    variant={'caption'}
                                    fontWeight={700}
                                    sx={{ textTransform: 'uppercase' }}
                                  >
                                    Account Type
                                  </Typography>
                                </TableCell>
                                <TableCell align="center">
                                  <Typography
                                    variant={'caption'}
                                    fontWeight={700}
                                    sx={{ textTransform: 'uppercase' }}
                                  >
                                    Country
                                  </Typography>
                                </TableCell>
                                <TableCell align="center">
                                  <Typography
                                    variant={'caption'}
                                    fontWeight={700}
                                    sx={{ textTransform: 'uppercase' }}
                                  >
                                    Active Plan
                                  </Typography>
                                </TableCell>
                                <TableCell align="center">
                                  <Typography
                                    variant={'caption'}
                                    fontWeight={700}
                                    sx={{ textTransform: 'uppercase' }}
                                  >
                                    Plan Price
                                  </Typography>
                                </TableCell>
                                <TableCell align="center">
                                  <Typography
                                    variant={'caption'}
                                    fontWeight={700}
                                    sx={{ textTransform: 'uppercase' }}
                                  >
                                    Date
                                  </Typography>
                                </TableCell>
                                <TableCell align="center">
                                  <Typography
                                    variant={'caption'}
                                    fontWeight={700}
                                    sx={{ textTransform: 'uppercase' }}
                                  >
                                    Actions
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {customers.map((customer, i) => (
                                <TableRow
                                  key={i}
                                  // sx={{
                                  //   '&:last-child td, &:last-child th': {
                                  //     border: 0,
                                  //   },
                                  // }}
                                >
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    // align="center"
                                  >
                                    <Box
                                      display={'flex'}
                                      flexDirection={'column'}
                                      // gap={1}
                                    >
                                      <Typography
                                        fontWeight={'bold'}
                                        color={'text.secondary'}
                                      >
                                        {customer?.organizationName || ''}
                                      </Typography>
                                      <Typography variant="caption">
                                        {customer?.customFields?.primaryEmail}
                                      </Typography>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    align="center"
                                  >
                                    <Typography variant={'caption'}>
                                      {customer?.demo ? 'Demo Account' : '-'}
                                    </Typography>
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    align="center"
                                  >
                                    <Typography variant={'caption'}>
                                      {capitalizeText(
                                        customer?.countries?.[0],
                                      ) || ''}
                                    </Typography>
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    align="center"
                                  >
                                    <Typography
                                      variant={'caption'}
                                      textAlign={'center'}
                                    >
                                      {`${capitalizeText(
                                        customer?.plan?.title,
                                      ) || ''}`}
                                      <br />
                                      <span
                                        style={{
                                          color: theme.palette.primary.main,
                                        }}
                                      >{`${
                                        customer?.plan &&
                                        customer?.plan?.features &&
                                        Object.values(
                                          customer?.plan?.features || {},
                                        )?.filter((obj) => obj?.addedAsAddOn)
                                          ?.length > 0
                                          ? `+ ${
                                              Object.values(
                                                customer?.plan?.features || {},
                                              )?.filter(
                                                (obj) => obj?.addedAsAddOn,
                                              )?.length
                                            } Addons`
                                          : ''
                                      }`}</span>
                                    </Typography>
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    align="center"
                                  >
                                    <Typography
                                      variant={'caption'}
                                      textAlign={'center'}
                                    >
                                      {customer?.plan?.price?.amount
                                        ? `${
                                            customer?.plan?.price?.currency ===
                                            'usd'
                                              ? '$'
                                              : '&#8377;'
                                          } ${customer?.plan?.price?.amount}`
                                        : '-'}
                                    </Typography>
                                  </TableCell>
                                  <TableCell align="center">
                                    <Typography variant={'caption'}>
                                      {moment(customer?.createdOn).format(
                                        'DD MMMM YYYY',
                                      )}
                                    </Typography>
                                  </TableCell>
                                  <TableCell align="center">
                                    <Box
                                      display={'flex'}
                                      justifyContent={'center'}
                                      alignItems={'center'}
                                    >
                                      <Tooltip
                                        title={'View Team Dashboard'}
                                        placement="top"
                                        arrow
                                      >
                                        <IconButton
                                          disabled={customer?.suspended}
                                          onClick={(e) => {
                                            setViewType('teams');
                                            setCurrentOrgId(customer?.id);
                                            dispatch({
                                              type: 'UPDATE_UI_STATE',
                                              payload: {
                                                key: 'currentNavigationLevel',
                                                value:
                                                  currentNavigationLevel + 1,
                                              },
                                            });
                                          }}
                                        >
                                          <Groups
                                            color={
                                              customer?.suspended
                                                ? 'default'
                                                : 'primary'
                                            }
                                          />
                                        </IconButton>
                                      </Tooltip>
                                      <Tooltip
                                        title={
                                          customer?.suspended
                                            ? 'Re-enable Account'
                                            : 'Suspend Account'
                                        }
                                        placement="top"
                                        arrow
                                      >
                                        <IconButton
                                          onClick={(e) => {
                                            if (customer?.suspended) {
                                              dispatch(
                                                updateTeamSuspendStatus(
                                                  customer?.id,
                                                  !customer?.suspended,
                                                  () => {
                                                    setShowConfirmSuspend(null);
                                                    dispatch(
                                                      getWlCustomers(
                                                        userDetails?.organizationId,
                                                        () => {},
                                                      ),
                                                    );
                                                  },
                                                ),
                                              );
                                            } else {
                                              setShowConfirmSuspend(customer);
                                            }
                                          }}
                                        >
                                          {customer?.suspended ? (
                                            <RestartAlt
                                              sx={{
                                                fontSize: 18,
                                              }}
                                              color={
                                                customer?.suspended
                                                  ? 'primary'
                                                  : 'default'
                                              }
                                            />
                                          ) : (
                                            <DoDisturb
                                              sx={{
                                                fontSize: 18,
                                              }}
                                              color={
                                                customer?.suspended
                                                  ? 'default'
                                                  : 'primary'
                                              }
                                            />
                                          )}
                                        </IconButton>
                                      </Tooltip>
                                    </Box>
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Box>
                    ) : (
                      <Box
                        display={'flex'}
                        flexDirection={'column'}
                        gap={2}
                        alignItems={'center'}
                        justifyContent={'center'}
                      >
                        <Typography
                          variant="subtitle2"
                          color={'text.secondary'}
                        >
                          You have no client yet.
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          color={'text.secondary'}
                        >
                          To invite new client you have to create new team.
                        </Typography>
                        <Button
                          variant="contained"
                          size="small"
                          onClick={() => setShowAddCustomerDialog(true)}
                        >
                          Invite Client
                        </Button>
                      </Box>
                    )}
                  </>
                ) : viewType === 'teams' ? (
                  <Box
                    sx={{
                      border: '1px solid #d3d3d3',
                      borderRadius: 2,
                      minHeight: 'calc(100vh - 90px)',
                    }}
                  >
                    <Tabs
                      value={currentTeamsTab}
                      onChange={(e, newValue) => setCurrentTeamsTab(newValue)}
                      aria-label="Teams Tabs"
                      orientation={'horizontal'}
                      // indicatorColor={theme.palette.background.default}
                      variant="scrollable"
                      // scrollButtons={!isMd}
                      allowScrollButtonsMobile
                      sx={{
                        borderBottom: '1px solid #d3d3d3',
                      }}
                    >
                      <Tab
                        value={'members'}
                        label={
                          <Typography variant="body1">Team Members</Typography>
                        }
                        id={`simple-tab-${0}`}
                        aria-controls={`simple-tabpanel-${0}`}
                        sx={{}}
                      />
                      <Tab
                        value={'invite'}
                        label={
                          <Typography variant="body1">Invite Member</Typography>
                        }
                        id={`simple-tab-${0}`}
                        aria-controls={`simple-tabpanel-${0}`}
                        sx={{}}
                      />
                      <Tab
                        value={'invitations'}
                        label={
                          <Typography variant="body1">Invitations</Typography>
                        }
                        id={`simple-tab-${0}`}
                        aria-controls={`simple-tabpanel-${0}`}
                        sx={{}}
                      />
                      <Tab
                        value={'settings'}
                        label={
                          <Typography variant="body1">
                            Client Settings
                          </Typography>
                        }
                        id={`simple-tab-${0}`}
                        aria-controls={`simple-tabpanel-${0}`}
                        sx={{}}
                      />
                      <Tab
                        value={'donna-presets'}
                        label={
                          <Typography variant="body1">
                            {orgConfigs?.navBarTitles?.['Clients Dashboard']
                              ?.subTitles?.['Ask Donna']?.title || 'Ask Donna'}
                          </Typography>
                        }
                        id={`simple-tab-${0}`}
                        aria-controls={`simple-tabpanel-${0}`}
                        sx={{}}
                      />
                      <Tab
                        value={'active-plan'}
                        label={
                          <Typography variant="body1">Active Plan</Typography>
                        }
                        id={`simple-tab-${0}`}
                        aria-controls={`simple-tabpanel-${0}`}
                        sx={{}}
                      />
                    </Tabs>
                    {currentTeamsTab === 'members' ? (
                      <>
                        {allTeamMembers?.fetching ? (
                          <Box
                            width={1}
                            height={1}
                            display={'flex'}
                            justifyContent={'center'}
                            alignItems={'center'}
                            flexDirection={'column'}
                          >
                            <CircularProgress size={20} />
                            <Typography
                              variant="body1"
                              color={'text.primary'}
                              mt={4}
                            >
                              Loading team members ...
                            </Typography>
                          </Box>
                        ) : allTeamMembers?.members &&
                          allTeamMembers?.members?.length > 0 ? (
                          <Box
                            display={'flex'}
                            flexDirection={'column'}
                            gap={1}
                          >
                            <TableContainer
                              component={Paper}
                              sx={{
                                minHeight: isXs
                                  ? 'calc(100vh - 140px)'
                                  : 'calc(100vh - 140px)',
                                maxHeight: isXs
                                  ? 'calc(100vh - 140px)'
                                  : 'calc(100vh - 140px)',
                                overflowY: 'auto',
                                '&::-webkit-scrollbar': {
                                  width: '5px',
                                  height: '5px',
                                },
                                '&::-webkit-scrollbar-track': {
                                  boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                                  webkitBoxShadow:
                                    'inset 0 0 6px rgba(0,0,0,0.00)',
                                },
                                '&::-webkit-scrollbar-thumb': {
                                  backgroundColor: theme.palette.primary.main,
                                  borderRadius: 2,
                                },
                              }}
                              onScroll={handleListScroll}
                            >
                              <Table
                                sx={{ minWidth: 750 }}
                                stickyHeader
                                aria-label="simple table"
                              >
                                <TableHead sx={{ bgcolor: 'alternate.dark' }}>
                                  <TableRow
                                    sx={{
                                      '& .MuiTableCell-root': {
                                        border: '1px solid #e1e1e1',
                                      },
                                    }}
                                  >
                                    <TableCell align="center">
                                      <Typography
                                        variant={'caption'}
                                        fontWeight={700}
                                        sx={{
                                          textTransform: 'capitalize',
                                        }}
                                      >
                                        #
                                      </Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                      <Typography
                                        variant={'caption'}
                                        fontWeight={700}
                                        sx={{
                                          textTransform: 'capitalize',
                                        }}
                                      >
                                        Member
                                      </Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                      <Typography
                                        variant={'caption'}
                                        fontWeight={700}
                                        sx={{ textTransform: 'capitalize' }}
                                      >
                                        {orgConfigs?.navBarTitles?.[
                                          'Sales Call Analysis'
                                        ]?.title || 'Sales Call Analysis'}
                                      </Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                      <Typography
                                        variant={'caption'}
                                        fontWeight={700}
                                        sx={{ textTransform: 'capitalize' }}
                                      >
                                        {orgConfigs?.navBarTitles?.[
                                          'Private Roleplays'
                                        ]?.title || 'Private Roleplays'}
                                      </Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                      <Typography
                                        variant="caption"
                                        fontWeight={700}
                                        sx={{ textTransform: 'capitalize' }}
                                      >
                                        Actions
                                      </Typography>
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {allTeamMembers?.members.map((member, i) => (
                                    <TableRow key={i}>
                                      <TableCell align="center">
                                        <Typography
                                          variant="subtitle1"
                                          sx={{
                                            fontWeight: 'bold',
                                          }}
                                        >
                                          {i + 1}
                                        </Typography>
                                      </TableCell>
                                      <TableCell
                                        component="th"
                                        // rowSpan={2}
                                        sx={{
                                          position: 'relative',
                                        }}
                                      >
                                        <Box
                                          display={'flex'}
                                          flexDirection={'column'}
                                          // gap={1}
                                        >
                                          <Box
                                            display={'flex'}
                                            alignItems={'center'}
                                            gap={2}
                                          >
                                            <Typography
                                              fontWeight={'bold'}
                                              color={'text.secondary'}
                                            >
                                              {member?.fullName || ''}
                                            </Typography>
                                          </Box>
                                          <Typography variant="caption">
                                            {member?.orgRoles?.[0]
                                              ?.split('_')
                                              ?.join(' ')}
                                          </Typography>
                                          <Typography variant="caption">
                                            {member?.email}
                                          </Typography>
                                        </Box>
                                      </TableCell>
                                      <TableCell align="center" sx={{}}>
                                        {member?.custom?.counts
                                          ?.salesCallAnalysed > 0 ? (
                                          <Button
                                            variant="text"
                                            color="primary"
                                            onClick={() => {
                                              if (member?.id === userInfo?.id) {
                                                history.push(
                                                  '/sales-call-analysis',
                                                );
                                              } else {
                                                setViewType('salesCalls');
                                                setCurrentMemberId(member?.id);
                                                dispatch({
                                                  type: 'UPDATE_UI_STATE',
                                                  payload: {
                                                    key:
                                                      'currentNavigationLevel',
                                                    value:
                                                      currentNavigationLevel +
                                                      1,
                                                  },
                                                });
                                              }
                                            }}
                                          >
                                            <Typography
                                              variant="body2"
                                              fontWeight={'bold'}
                                              sx={{
                                                textDecoration: 'underline',
                                              }}
                                            >
                                              {member?.custom?.counts
                                                ?.salesCallAnalysed || 0}
                                            </Typography>
                                          </Button>
                                        ) : (
                                          <Typography
                                            // color={'text.secondary'}
                                            variant={'caption'}
                                          >
                                            {member?.custom?.counts
                                              ?.salesCallAnalysed || 0}
                                          </Typography>
                                        )}
                                      </TableCell>
                                      <TableCell align="center" sx={{}}>
                                        {/* {member?.custom?.counts
                                          ?.practiceCallAnalysed > 0 ? (
                                          <Button
                                            size="small"
                                            variant="text"
                                            color="primary"
                                            onClick={() => {
                                              if (member?.id === userInfo?.id) {
                                                history.push(
                                                  '/conversation-ai',
                                                );
                                              } else {
                                                setViewType('practiceCalls');
                                                setCurrentMemberId(member?.id);
                                                dispatch({
                                                  type: 'UPDATE_UI_STATE',
                                                  payload: {
                                                    key:
                                                      'currentNavigationLevel',
                                                    value:
                                                      currentNavigationLevel +
                                                      1,
                                                  },
                                                });
                                              }
                                            }}
                                          >
                                            <Typography
                                              variant="body2"
                                              fontWeight={'bold'}
                                              sx={{
                                                textDecoration: 'underline',
                                              }}
                                            >
                                              {member?.custom?.counts
                                                ?.practiceCallAnalysed || 0}
                                            </Typography>
                                          </Button>
                                        ) : ( */}
                                        <Typography
                                          // color={'text.secondary'}
                                          variant={'caption'}
                                        >
                                          {member?.custom?.counts
                                            ?.practiceCallAnalysed || 0}
                                        </Typography>
                                        {/* // )} */}
                                      </TableCell>
                                      <TableCell align="center">
                                        <Box
                                          display={'flex'}
                                          alignItems={'center'}
                                          justifyContent={'center'}
                                          gap={1}
                                        >
                                          <Tooltip
                                            title={'View performance graph'}
                                            placement="top"
                                            arrow
                                          >
                                            <IconButton
                                              onClick={(e) => {
                                                setViewType('dashboard');
                                                setCurrentMemberId(member?.id);
                                              }}
                                            >
                                              <AutoGraph color="primary" />
                                            </IconButton>
                                          </Tooltip>
                                          {member?.id !== userInfo?.id ? (
                                            <Tooltip
                                              title={'Remove User'}
                                              placement="top"
                                              arrow
                                            >
                                              <IconButton
                                                onClick={(e) => {
                                                  setShowConfirmDeleteDialog(
                                                    member?.id,
                                                  );
                                                }}
                                              >
                                                <PersonRemove color="primary" />
                                              </IconButton>
                                            </Tooltip>
                                          ) : null}
                                          <FormControl fullWidth size="small">
                                            <Select
                                              disabled={
                                                member?.email ===
                                                  userInfo?.email ||
                                                updatingRole
                                              }
                                              size="small"
                                              labelId="role-select-label"
                                              id="role-select"
                                              name="teamRole"
                                              MenuProps={{
                                                disableScrollLock: true,
                                              }}
                                              sx={{
                                                '& .MuiSelect-select': {
                                                  padding: '4px 8px',
                                                },
                                              }}
                                              onChange={(e) => {
                                                setUpdatingRole(true);
                                                dispatch(
                                                  updateTeamMemberRole(
                                                    [
                                                      {
                                                        roles: [e.target.value],
                                                        userId: member?.id,
                                                      },
                                                    ],
                                                    (error) => {
                                                      if (error) {
                                                        dispatch(
                                                          showAlert({
                                                            message:
                                                              'Failed to update role',
                                                            showCross: true,
                                                            title: null,
                                                            type: 'error',
                                                            autoHideDuration: 2000,
                                                            vertical: 'top',
                                                            horizontal:
                                                              'center',
                                                          }),
                                                        );
                                                      } else {
                                                        dispatch(
                                                          getAllTeamMembers(
                                                            currentOrgId,
                                                            25,
                                                            null,
                                                            () => {},
                                                          ),
                                                        );
                                                        dispatch(
                                                          showAlert({
                                                            message:
                                                              'Role updated successfully.',
                                                            showCross: true,
                                                            title: null,
                                                            type: 'success',
                                                            autoHideDuration: 2000,
                                                            vertical: 'top',
                                                            horizontal:
                                                              'center',
                                                          }),
                                                        );
                                                      }
                                                      setUpdatingRole(false);
                                                    },
                                                  ),
                                                );
                                              }}
                                              value={member?.orgRoles?.[0]}
                                            >
                                              <MenuItem
                                                key={0}
                                                value="ORG_STANDARD"
                                              >
                                                <Typography variant="caption">
                                                  STANDARD
                                                </Typography>
                                              </MenuItem>
                                              <MenuItem
                                                key={1}
                                                value="ORG_ADMIN"
                                              >
                                                <Typography variant="caption">
                                                  ADMIN
                                                </Typography>
                                              </MenuItem>
                                            </Select>
                                          </FormControl>
                                        </Box>
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                                <TableFooter>
                                  <TableRow>
                                    <TableCell
                                      variant="footer"
                                      colSpan={8}
                                      sx={{
                                        padding: 0,
                                      }}
                                    >
                                      {allTeamMembers?.fetchingPagination ? (
                                        <Box
                                          sx={{
                                            width: '100%',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            padding: 1,
                                            textAlign: 'center',
                                          }}
                                        >
                                          <Button
                                            size="small"
                                            variant="contained"
                                            // type="submit"
                                            color="primary"
                                            onClick={handleListScroll}
                                            // className="searchSettings"
                                            disabled
                                            // fullWidth
                                          >
                                            <Typography color="textPrimary">
                                              Loading more members ...{' '}
                                            </Typography>
                                            <CircularProgress size={20} />
                                          </Button>
                                        </Box>
                                      ) : allTeamMembers?.emptyList === false &&
                                        allTeamMembers?.members.length >= 25 ? (
                                        <Box
                                          sx={{
                                            width: '100%',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            padding: 1,
                                            textAlign: 'center',
                                          }}
                                        >
                                          <Button
                                            size="small"
                                            variant="contained"
                                            // type="submit"
                                            // fullWidth
                                            color="primary"
                                            onClick={(e) =>
                                              handleListScroll(e, true)
                                            }
                                            // className="searchSettings"
                                          >
                                            Load More
                                          </Button>
                                        </Box>
                                      ) : null}
                                    </TableCell>
                                  </TableRow>
                                </TableFooter>
                              </Table>
                            </TableContainer>
                          </Box>
                        ) : (
                          <Box
                            width={1}
                            height={1}
                            display={'flex'}
                            justifyContent={'center'}
                            alignItems={'center'}
                            flexDirection={'column'}
                            gap={2}
                          >
                            <Typography
                              variant="body1"
                              color={'text.secondary'}
                              mt={4}
                            >
                              No team members have joined yet.
                            </Typography>
                          </Box>
                        )}
                      </>
                    ) : currentTeamsTab === 'invite' ? (
                      <>
                        <Box
                          p={2}
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                          }}
                        >
                          <Box
                            flex={1}
                            sx={{
                              maxWidth: 500,
                            }}
                          >
                            <Grid container spacing={1}>
                              <Grid item xs={6}>
                                <Typography
                                  variant="subtitle2"
                                  color={'text.secondary'}
                                >
                                  First Name
                                </Typography>
                                <TextField
                                  placeholder="First Name"
                                  variant="outlined"
                                  name={'firstName'}
                                  // fullWidth
                                  // multiline
                                  // rows={3}
                                  size="small"
                                  sx={{
                                    width: '100%',
                                  }}
                                  value={firstName}
                                  onChange={(e) => setFirstName(e.target.value)}
                                  type="text"
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <Typography
                                  variant="subtitle2"
                                  color={'text.secondary'}
                                >
                                  Last Name
                                </Typography>
                                <TextField
                                  placeholder="Last Name"
                                  variant="outlined"
                                  name={'lastName'}
                                  // fullWidth
                                  // multiline
                                  // rows={3}
                                  size="small"
                                  sx={{
                                    width: '100%',
                                  }}
                                  value={lastName}
                                  onChange={(e) => setLastName(e.target.value)}
                                  type="text"
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <Typography
                                  variant="subtitle2"
                                  color={'text.secondary'}
                                >
                                  Email
                                </Typography>
                                <TextField
                                  placeholder="Email"
                                  variant="outlined"
                                  name={'email'}
                                  // fullWidth
                                  // multiline
                                  // rows={3}
                                  size="small"
                                  sx={{
                                    width: '100%',
                                  }}
                                  value={email}
                                  onChange={(e) => setEmail(e.target.value)}
                                  type="email"
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <Typography
                                  variant="subtitle2"
                                  color={'text.secondary'}
                                  // sx={{ marginBottom: 1 }}
                                >
                                  Select Role
                                </Typography>
                                <FormControl fullWidth>
                                  <Select
                                    size="small"
                                    labelId="role-select-label"
                                    id="role-select"
                                    name="teamRole"
                                    MenuProps={{ disableScrollLock: true }}
                                    onChange={(e) =>
                                      setTeamRole(e.target.value)
                                    }
                                    value={teamRole}
                                  >
                                    <MenuItem key={0} value="ORG_STANDARD">
                                      STANDARD
                                    </MenuItem>
                                    <MenuItem key={1} value="ORG_ADMIN">
                                      ADMIN
                                    </MenuItem>
                                  </Select>
                                </FormControl>
                              </Grid>
                              {error && (
                                <Grid item xs={12}>
                                  <Alert severity="error">{error}</Alert>
                                </Grid>
                              )}
                              <Grid item xs={12}>
                                <Button
                                  variant="contained"
                                  color="primary"
                                  size="small"
                                  // sx={{ marginLeft: 'auto' }}
                                  onClick={() => {
                                    if (
                                      email &&
                                      email?.trim() &&
                                      validateEmail(email?.trim())
                                    ) {
                                      if (
                                        userDetails?.plan?.trailEndsOn >
                                          new Date().getTime() ||
                                        userDetails?.plan
                                          ?.currentPaymentStatus === 'paid'
                                      ) {
                                        setLoading(true);
                                        dispatch(
                                          sendTeamInvite(
                                            {
                                              orgId: currentOrgId,
                                              invitationType: 'team_invite',
                                              receiverDtls: [
                                                {
                                                  designations: [
                                                    'account_admin',
                                                  ],
                                                  email,
                                                  emailVerified: true,
                                                  firstName,
                                                  lastName,
                                                  orgRoles: [teamRole],
                                                },
                                              ],
                                            },
                                            (error) => {
                                              if (error) {
                                                setLoading(false);
                                                dispatch(
                                                  showAlert({
                                                    message: error?.includes(
                                                      'already exists',
                                                    )
                                                      ? 'User already registered with the same email address'
                                                      : 'Failed to send the invitation. Please try again!',
                                                    showCross: true,
                                                    title: null,
                                                    type: 'error',
                                                    autoHideDuration: 2000,
                                                    vertical: 'top',
                                                    horizontal: 'center',
                                                  }),
                                                );
                                              } else {
                                                setLoading(false);
                                                dispatch(
                                                  showAlert({
                                                    message:
                                                      'Invitation has been sent successfully.',
                                                    showCross: true,
                                                    title: null,
                                                    type: 'success',
                                                    autoHideDuration: 2000,
                                                    vertical: 'top',
                                                    horizontal: 'center',
                                                  }),
                                                );
                                              }
                                            },
                                          ),
                                        );
                                      } else {
                                        dispatch({
                                          type: 'UPDATE_UI_STATE',
                                          payload: {
                                            key: 'showPlanExpired',
                                            value: true,
                                          },
                                        });
                                      }
                                    } else {
                                      setError('Please enter a valid email.');
                                    }
                                  }}
                                >
                                  Send Invite
                                </Button>
                              </Grid>
                            </Grid>
                          </Box>
                        </Box>
                      </>
                    ) : currentTeamsTab === 'invitations' ? (
                      <>
                        {fetching ? (
                          <Box
                            width={1}
                            height={1}
                            display={'flex'}
                            justifyContent={'center'}
                            alignItems={'center'}
                            flexDirection={'column'}
                          >
                            <CircularProgress size={20} />
                            <Typography
                              variant="body1"
                              color={'text.primary'}
                              mt={4}
                            >
                              Loading all invitations
                            </Typography>
                          </Box>
                        ) : invitations && invitations?.length > 0 ? (
                          <TableContainer
                            component={Paper}
                            sx={{
                              minHeight: isXs
                                ? 'calc(100vh - 140px)'
                                : 'calc(100vh - 140px)',
                              maxHeight: isXs
                                ? 'calc(100vh - 140px)'
                                : 'calc(100vh - 140px)',
                              overflowY: 'auto',
                              '&::-webkit-scrollbar': {
                                width: '5px',
                                height: '5px',
                              },
                              '&::-webkit-scrollbar-track': {
                                boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                                webkitBoxShadow:
                                  'inset 0 0 6px rgba(0,0,0,0.00)',
                              },
                              '&::-webkit-scrollbar-thumb': {
                                backgroundColor: theme.palette.primary.main,
                                borderRadius: 2,
                              },
                            }}
                            onScroll={handleInvitationsListScroll}
                          >
                            <Table
                              sx={{ minWidth: 750 }}
                              stickyHeader
                              aria-label="simple table"
                            >
                              <TableHead sx={{ bgcolor: 'alternate.dark' }}>
                                <TableRow
                                  sx={{
                                    '& .MuiTableCell-root': {
                                      // border: '1px solid #e1e1e1',
                                    },
                                  }}
                                >
                                  <TableCell>
                                    <Typography
                                      variant={'caption'}
                                      fontWeight={700}
                                      sx={{
                                        textTransform: 'capitalize',
                                      }}
                                    >
                                      Member
                                    </Typography>
                                  </TableCell>
                                  <TableCell align="center">
                                    <Typography
                                      variant={'caption'}
                                      fontWeight={700}
                                      sx={{
                                        textTransform: 'capitalize',
                                      }}
                                    >
                                      Invited On
                                    </Typography>
                                  </TableCell>
                                  <TableCell align="center">
                                    <Typography
                                      variant={'caption'}
                                      fontWeight={700}
                                      sx={{
                                        textTransform: 'capitalize',
                                      }}
                                    >
                                      Joined
                                    </Typography>
                                  </TableCell>
                                  <TableCell align="center">
                                    <Typography
                                      variant={'caption'}
                                      fontWeight={700}
                                      sx={{
                                        textTransform: 'capitalize',
                                      }}
                                    >
                                      Joined On
                                    </Typography>
                                  </TableCell>
                                  <TableCell align="center">
                                    <Typography
                                      variant={'caption'}
                                      fontWeight={700}
                                      sx={{ textTransform: 'capitalize' }}
                                    >
                                      Role
                                    </Typography>
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {invitations.map((invitation, i) => (
                                  <TableRow key={i}>
                                    <TableCell
                                      sx={{
                                        // border: '1px solid #e1e1e1',
                                        position: 'relative',
                                      }}
                                    >
                                      <Box
                                        display={'flex'}
                                        flexDirection={'column'}
                                        // gap={1}
                                      >
                                        <Typography
                                          fontWeight={'bold'}
                                          color={'text.secondary'}
                                        >
                                          {invitation?.receiverEmail}
                                        </Typography>
                                        <Typography variant="caption">{`${invitation?.firstName ||
                                          ''} ${invitation?.lastName ||
                                          ''}`}</Typography>
                                      </Box>
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      // sx={{ border: '1px solid #e1e1e1' }}
                                    >
                                      <Typography
                                        variant="caption"
                                        // fontWeight={700}
                                        sx={{ textTransform: 'capitalize' }}
                                      >
                                        {moment(invitation.createdOn).format(
                                          'DD MMMM YY',
                                        )}
                                      </Typography>
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      // sx={{ border: '1px solid #e1e1e1' }}
                                    >
                                      <Typography
                                        variant="caption"
                                        // fontWeight={700}
                                        sx={{ textTransform: 'capitalize' }}
                                      >
                                        {invitation?.joined ? 'true' : 'false'}
                                      </Typography>
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      // sx={{ border: '1px solid #e1e1e1' }}
                                    >
                                      <Typography
                                        variant="caption"
                                        // fontWeight={700}
                                        sx={{ textTransform: 'capitalize' }}
                                      >
                                        {invitation?.joined
                                          ? moment(invitation.joinedOn).format(
                                              'DD MMMM YY',
                                            )
                                          : 'Invitation Sent'}
                                      </Typography>
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      // sx={{ border: '1px solid #e1e1e1' }}
                                    >
                                      <Typography
                                        variant="caption"
                                        // fontWeight={700}
                                        sx={{ textTransform: 'capitalize' }}
                                      >
                                        {invitation?.orgRoles?.[0] ||
                                          'STANDARD'}
                                      </Typography>
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                              <TableFooter>
                                <TableRow>
                                  <TableCell
                                    variant="footer"
                                    colSpan={8}
                                    sx={{
                                      padding: 0,
                                    }}
                                  >
                                    {fetchingPagination ? (
                                      <Box
                                        sx={{
                                          width: '100%',
                                          display: 'flex',
                                          justifyContent: 'center',
                                          padding: 1,
                                          textAlign: 'center',
                                        }}
                                      >
                                        <Button
                                          size="small"
                                          variant="contained"
                                          // type="submit"
                                          color="primary"
                                          onClick={handleListScroll}
                                          // className="searchSettings"
                                          disabled
                                          // fullWidth
                                        >
                                          <Typography color="textPrimary">
                                            Loading more invitations ...{' '}
                                          </Typography>
                                          <CircularProgress size={20} />
                                        </Button>
                                      </Box>
                                    ) : emptyList === false &&
                                      invitations.length >= 25 ? (
                                      <Box
                                        sx={{
                                          width: '100%',
                                          display: 'flex',
                                          justifyContent: 'center',
                                          padding: 1,
                                          textAlign: 'center',
                                        }}
                                      >
                                        <Button
                                          size="small"
                                          variant="contained"
                                          // type="submit"
                                          // fullWidth
                                          color="primary"
                                          onClick={(e) =>
                                            handleListScroll(e, true)
                                          }
                                          // className="searchSettings"
                                        >
                                          Load More
                                        </Button>
                                      </Box>
                                    ) : null}
                                  </TableCell>
                                </TableRow>
                              </TableFooter>
                            </Table>
                          </TableContainer>
                        ) : (
                          <Box
                            width={1}
                            height={1}
                            display={'flex'}
                            justifyContent={'center'}
                            alignItems={'center'}
                            flexDirection={'column'}
                            gap={2}
                          >
                            <Typography
                              variant="body1"
                              color={'text.secondary'}
                              mt={4}
                            >
                              No invites sent yet.
                            </Typography>
                          </Box>
                        )}
                      </>
                    ) : currentTeamsTab === 'settings' ? (
                      <form onSubmit={formikPlaybook.handleSubmit}>
                        <Box>
                          <Box
                            sx={{
                              minHeight: isXs
                                ? 'calc(100vh - 200px)'
                                : 'calc(100vh - 200px)',
                              maxHeight: isXs
                                ? 'calc(100vh - 200px)'
                                : 'calc(100vh - 200px)',
                              overflowY: 'auto',
                              '&::-webkit-scrollbar': {
                                width: '5px',
                                height: '5px',
                              },
                              '&::-webkit-scrollbar-track': {
                                boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                                webkitBoxShadow:
                                  'inset 0 0 6px rgba(0,0,0,0.00)',
                              },
                              '&::-webkit-scrollbar-thumb': {
                                backgroundColor: theme.palette.primary.main,
                                borderRadius: 2,
                              },
                            }}
                            display={'flex'}
                            flexDirection={'column'}
                            gap={1}
                            padding={2}
                            alignItems={'start'}
                          >
                            <TextField
                              sx={{ minWidth: 300 }}
                              label="Company Name*"
                              variant="outlined"
                              name={'organizationName'}
                              size="small"
                              // fullWidth
                              value={organizationName}
                              onChange={(e) =>
                                setOrganizationName(e.target.value)
                              }
                            />
                            <Typography
                              variant={'subtitle2'}
                              // sx={{  }}
                              color={'text.secondary'}
                              fontWeight={'bold'}
                            >
                              <span>
                                Pick all the sales methodologies that the sales
                                team is expected to follow.
                              </span>
                            </Typography>
                            <FormControl
                              component="fieldset"
                              variant="standard"
                            >
                              <FormGroup row>
                                {[
                                  ...(orgConfigs?.methodologies || []),
                                  ...(orgConfigs?.customMethodologies || []),
                                ]?.map((mthd) => (
                                  <FormControlLabel
                                    key={mthd}
                                    checked={methodologies?.includes(mthd)}
                                    control={
                                      <Checkbox
                                        size="small"
                                        value={mthd}
                                        name="methods"
                                      />
                                    }
                                    label={
                                      mthd === 'meddpic'
                                        ? 'MEDDICC'
                                        : mthd?.toUpperCase()
                                    }
                                    onChange={(e) => {
                                      if (methodologies?.includes(mthd)) {
                                        setMethodologies((prev) =>
                                          prev.filter(
                                            (mthd) => mthd !== e.target.value,
                                          ),
                                        );
                                      } else {
                                        setMethodologies((prev) => [
                                          ...prev,
                                          e.target.value,
                                        ]);
                                      }
                                    }}
                                  />
                                ))}
                              </FormGroup>
                            </FormControl>
                            <Box
                              sx={{
                                width: 1,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                              }}
                            >
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    size="small"
                                    checked={withContentScoringConfig}
                                    name="withContentScoringConfig"
                                  />
                                }
                                label={
                                  <Typography variant="subtitle2">
                                    Call Scoring Configurations
                                  </Typography>
                                }
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    setShowEditContentScoringConfig(true);
                                  } else {
                                    setShowEditContentScoringConfig(false);
                                  }
                                  setWithContentScoringConfig(e.target.checked);
                                }}
                              />
                              {withContentScoringConfig ? (
                                <Button
                                  startIcon={<Settings />}
                                  size="small"
                                  onClick={() =>
                                    setShowEditContentScoringConfig(true)
                                  }
                                >
                                  Edit Configurations
                                </Button>
                              ) : null}
                            </Box>
                            <Box
                              gap={3}
                              display={'flex'}
                              alignItems={'center'}
                              justifyContent={'space-between'}
                            >
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    size="small"
                                    checked={withPlaybook}
                                    name="withPlaybook"
                                  />
                                }
                                label={
                                  <Typography variant="subtitle2">
                                    Playbook
                                  </Typography>
                                }
                                onChange={(e) => {
                                  setWithPlaybook(e.target.checked);
                                }}
                              />
                              {withPlaybook ? (
                                <Button
                                  variant="contained"
                                  size="small"
                                  onClick={() => {
                                    if (
                                      userDetails?.plan?.trailEndsOn >
                                        new Date().getTime() ||
                                      userDetails?.plan
                                        ?.currentPaymentStatus === 'paid'
                                    ) {
                                      setShowGeneratePlaybookWithAI('settings');
                                    } else {
                                      dispatch({
                                        type: 'UPDATE_UI_STATE',
                                        payload: {
                                          key: 'showPlanExpired',
                                          value: true,
                                        },
                                      });
                                    }
                                  }}
                                >
                                  Generate with AI
                                </Button>
                              ) : null}
                            </Box>
                            {withPlaybook ? (
                              <Box>
                                <Grid container spacing={1}>
                                  <Grid item xs={8}>
                                    <Typography
                                      variant="subtitle2"
                                      color={'text.secondary'}
                                      fontWeight={'bold'}
                                    >
                                      Playbook Title*
                                    </Typography>
                                    <AutoCompleteMUI
                                      id="tags"
                                      value={tags}
                                      onChange={(event, newValue, reason) => {
                                        setTags([...(newValue || [])]);
                                      }}
                                      options={[]}
                                      onTextChange={(e) => {
                                        if (e.target.value?.trim()) {
                                          setTags([e.target.value]);
                                        } else {
                                          setTags([]);
                                        }
                                      }}
                                      onKeyDown={() => {}}
                                    />
                                  </Grid>
                                  <Grid item xs={4}></Grid>
                                  {Object.keys(
                                    defaultPlaybookFields || {},
                                  )?.map((field, index) => (
                                    <Grid item xs={12} key={index}>
                                      <Box
                                        p={1}
                                        borderRadius={1}
                                        display={'flex'}
                                        flexDirection={'column'}
                                        gap={1}
                                      >
                                        <Box
                                          display={'flex'}
                                          alignItems={'center'}
                                          gap={1}
                                          justifyContent={'space-between'}
                                        >
                                          <Typography
                                            variant="subtitle2"
                                            color={'text.secondary'}
                                            fontWeight={'bold'}
                                          >
                                            {
                                              defaultPlaybookFieldsMapping?.[
                                                field
                                              ]?.text
                                            }
                                          </Typography>
                                        </Box>
                                        {field === 'challengesForCustomer' ? (
                                          <>
                                            {defaultPlaybookFields.challengesForCustomer &&
                                              Array.isArray(
                                                defaultPlaybookFields.challengesForCustomer,
                                              ) &&
                                              defaultPlaybookFields
                                                .challengesForCustomer.length >
                                                0 &&
                                              defaultPlaybookFields.challengesForCustomer?.map(
                                                (objAndAns, faqIndex) => {
                                                  return (
                                                    <Box
                                                      key={faqIndex}
                                                      sx={{
                                                        border:
                                                          '1px solid #d3d3d3',
                                                        borderRadius: 2,
                                                        padding: 2,
                                                        marginTop: 1,
                                                        position: 'relative',
                                                      }}
                                                    >
                                                      <Button
                                                        variant="text"
                                                        sx={{
                                                          position: 'absolute',
                                                          top: 0,
                                                          right: 10,
                                                          zIndex: 2,
                                                          background: '#fff',
                                                          '&:hover': {
                                                            background: '#fff',
                                                          },
                                                        }}
                                                        onClick={() => {
                                                          const updatedObjAndAns = defaultPlaybookFields.challengesForCustomer.filter(
                                                            (objAndAns, i) =>
                                                              i !== faqIndex,
                                                          );
                                                          setDefaultPlaybookFields(
                                                            (prev) => {
                                                              return {
                                                                ...prev,
                                                                challengesForCustomer: updatedObjAndAns,
                                                              };
                                                            },
                                                          );
                                                        }}
                                                      >
                                                        Remove
                                                      </Button>
                                                      <TextField
                                                        label={`Challenge ${faqIndex +
                                                          1}`}
                                                        size="small"
                                                        variant="outlined"
                                                        fullWidth
                                                        value={
                                                          objAndAns?.question
                                                        }
                                                        sx={{ marginTop: 1 }}
                                                        onChange={(e) => {
                                                          const updatedObjAndAns = defaultPlaybookFields.challengesForCustomer.map(
                                                            (objAndAns, i) => {
                                                              if (
                                                                i === faqIndex
                                                              ) {
                                                                return {
                                                                  ...objAndAns,
                                                                  question:
                                                                    e.target
                                                                      .value,
                                                                };
                                                              } else {
                                                                return objAndAns;
                                                              }
                                                            },
                                                          );
                                                          setDefaultPlaybookFields(
                                                            (prev) => {
                                                              return {
                                                                ...prev,
                                                                challengesForCustomer: updatedObjAndAns,
                                                              };
                                                            },
                                                          );
                                                        }}
                                                      />
                                                      <TextField
                                                        size="small"
                                                        label={`Recommended Response`}
                                                        variant="outlined"
                                                        fullWidth
                                                        value={
                                                          objAndAns?.answer
                                                        }
                                                        onChange={(e) => {
                                                          const updatedObjAndAns = defaultPlaybookFields.challengesForCustomer.map(
                                                            (objAndAns, i) => {
                                                              if (
                                                                i === faqIndex
                                                              ) {
                                                                return {
                                                                  ...objAndAns,
                                                                  answer:
                                                                    e.target
                                                                      .value,
                                                                };
                                                              } else {
                                                                return objAndAns;
                                                              }
                                                            },
                                                          );
                                                          setDefaultPlaybookFields(
                                                            (prev) => {
                                                              return {
                                                                ...prev,
                                                                challengesForCustomer: updatedObjAndAns,
                                                              };
                                                            },
                                                          );
                                                        }}
                                                        sx={{ marginTop: 1 }}
                                                        // error={formik.touched.email && Boolean(formik.errors.email)}
                                                        // helperText={formik.touched.email && formik.errors.email}
                                                      />
                                                    </Box>
                                                  );
                                                },
                                              )}
                                            <Button
                                              onClick={() => {
                                                setDefaultPlaybookFields(
                                                  (prev) => {
                                                    return {
                                                      ...prev,
                                                      challengesForCustomer: [
                                                        ...prev.challengesForCustomer,
                                                        {
                                                          question: '',
                                                          answer: '',
                                                        },
                                                      ],
                                                    };
                                                  },
                                                );
                                              }}
                                              sx={{ marginTop: 1 }}
                                              variant="contained"
                                            >
                                              Add Challenge and Recommended
                                              Response
                                            </Button>
                                          </>
                                        ) : field === 'customerQuestions' ? (
                                          <>
                                            {defaultPlaybookFields.customerQuestions &&
                                              Array.isArray(
                                                defaultPlaybookFields.customerQuestions,
                                              ) &&
                                              defaultPlaybookFields
                                                .customerQuestions.length > 0 &&
                                              defaultPlaybookFields.customerQuestions?.map(
                                                (objAndAns, faqIndex) => {
                                                  return (
                                                    <Box
                                                      key={faqIndex}
                                                      sx={{
                                                        border:
                                                          '1px solid #d3d3d3',
                                                        borderRadius: 2,
                                                        padding: 2,
                                                        marginTop: 1,
                                                        position: 'relative',
                                                      }}
                                                    >
                                                      <Button
                                                        variant="text"
                                                        sx={{
                                                          position: 'absolute',
                                                          top: 0,
                                                          right: 10,
                                                          zIndex: 2,
                                                          background: '#fff',
                                                          '&:hover': {
                                                            background: '#fff',
                                                          },
                                                        }}
                                                        onClick={() => {
                                                          const updatedObjAndAns = defaultPlaybookFields.customerQuestions.filter(
                                                            (objAndAns, i) =>
                                                              i !== faqIndex,
                                                          );
                                                          setDefaultPlaybookFields(
                                                            (prev) => {
                                                              return {
                                                                ...prev,
                                                                customerQuestions: updatedObjAndAns,
                                                              };
                                                            },
                                                          );
                                                        }}
                                                      >
                                                        Remove
                                                      </Button>
                                                      <TextField
                                                        label={`Objection ${faqIndex +
                                                          1}`}
                                                        size="small"
                                                        variant="outlined"
                                                        fullWidth
                                                        value={
                                                          objAndAns?.question
                                                        }
                                                        sx={{ marginTop: 1 }}
                                                        onChange={(e) => {
                                                          const updatedObjAndAns = defaultPlaybookFields.customerQuestions.map(
                                                            (objAndAns, i) => {
                                                              if (
                                                                i === faqIndex
                                                              ) {
                                                                return {
                                                                  ...objAndAns,
                                                                  question:
                                                                    e.target
                                                                      .value,
                                                                };
                                                              } else {
                                                                return objAndAns;
                                                              }
                                                            },
                                                          );
                                                          setDefaultPlaybookFields(
                                                            (prev) => {
                                                              return {
                                                                ...prev,
                                                                customerQuestions: updatedObjAndAns,
                                                              };
                                                            },
                                                          );
                                                        }}
                                                      />
                                                      <TextField
                                                        size="small"
                                                        label={`Answer`}
                                                        variant="outlined"
                                                        fullWidth
                                                        value={
                                                          objAndAns?.answer
                                                        }
                                                        onChange={(e) => {
                                                          const updatedObjAndAns = defaultPlaybookFields.customerQuestions.map(
                                                            (objAndAns, i) => {
                                                              if (
                                                                i === faqIndex
                                                              ) {
                                                                return {
                                                                  ...objAndAns,
                                                                  answer:
                                                                    e.target
                                                                      .value,
                                                                };
                                                              } else {
                                                                return objAndAns;
                                                              }
                                                            },
                                                          );
                                                          setDefaultPlaybookFields(
                                                            (prev) => {
                                                              return {
                                                                ...prev,
                                                                customerQuestions: updatedObjAndAns,
                                                              };
                                                            },
                                                          );
                                                        }}
                                                        sx={{ marginTop: 1 }}
                                                        // error={formik.touched.email && Boolean(formik.errors.email)}
                                                        // helperText={formik.touched.email && formik.errors.email}
                                                      />
                                                    </Box>
                                                  );
                                                },
                                              )}
                                            <Button
                                              onClick={() => {
                                                setDefaultPlaybookFields(
                                                  (prev) => {
                                                    return {
                                                      ...prev,
                                                      customerQuestions: [
                                                        ...prev.customerQuestions,
                                                        {
                                                          question: '',
                                                          answer: '',
                                                        },
                                                      ],
                                                    };
                                                  },
                                                );
                                              }}
                                              sx={{ marginTop: 1 }}
                                              variant="contained"
                                            >
                                              Add Objection and Answer
                                            </Button>
                                          </>
                                        ) : (
                                          <TextField
                                            placeholder={
                                              defaultPlaybookFieldsMapping?.[
                                                field
                                              ]?.text
                                            }
                                            variant="outlined"
                                            name={field}
                                            fullWidth
                                            size="small"
                                            multiline
                                            rows={5}
                                            value={
                                              defaultPlaybookFields?.[field]
                                            }
                                            onChange={(e) => {
                                              setDefaultPlaybookFields(
                                                (prev) => {
                                                  return {
                                                    ...prev,
                                                    [field]: e.target.value,
                                                  };
                                                },
                                              );
                                            }}
                                            type={'text'}
                                          />
                                        )}
                                      </Box>
                                    </Grid>
                                  ))}
                                  {customPlaybookFields?.map((field, index) => (
                                    <Grid item xs={12} key={index}>
                                      <Box
                                        p={1}
                                        borderRadius={1}
                                        display={'flex'}
                                        flexDirection={'column'}
                                        gap={1}
                                      >
                                        <Box
                                          display={'flex'}
                                          alignItems={'center'}
                                          gap={1}
                                          justifyContent={'space-between'}
                                        >
                                          <Typography
                                            variant="subtitle2"
                                            color={'text.secondary'}
                                            fontWeight={'bold'}
                                          >
                                            {field?.title}
                                          </Typography>
                                          {/* <Tooltip arrow placement="top" title="Delete Field">
                          <IconButton
                            sx={{
                              borderRadius: '4px',
                              border: '1px solid #d3d3d3',
                              padding: '4px',
                              width: 26,
                              height: 26,
                            }}
                            onClick={() => {
                              setCustomPlaybookFields((prev) =>
                                prev.filter(
                                  (item) => item?.title !== field?.title,
                                ),
                              );
                            }}
                          >
                            <Delete sx={{ fontSize: 16 }} />
                          </IconButton>
                        </Tooltip> */}
                                        </Box>
                                        <TextField
                                          placeholder={field?.title}
                                          variant="outlined"
                                          name={field}
                                          fullWidth
                                          size="small"
                                          multiline
                                          rows={5}
                                          value={field?.content}
                                          onChange={(e) => {
                                            setCustomPlaybookFields((prev) => {
                                              return prev.map((item) => {
                                                if (
                                                  item?.title === field?.title
                                                ) {
                                                  return {
                                                    ...item,
                                                    content: e.target.value,
                                                  };
                                                } else {
                                                  return item;
                                                }
                                              });
                                            });
                                          }}
                                          type={'text'}
                                        />
                                      </Box>
                                    </Grid>
                                  ))}
                                </Grid>
                              </Box>
                            ) : null}
                          </Box>
                          <Box
                            p={1}
                            sx={{
                              borderTop: '1px solid #d3d3d3',
                              // height: 80,
                            }}
                            display={'flex'}
                            alignItems={'center'}
                            justifyContent={'space-between'}
                          >
                            <Box display={'flex'} flexDirection={'column'}>
                              {!organizationName.trim() && (
                                <Typography
                                  variant="caption"
                                  sx={{
                                    color: theme.palette.error.dark,
                                  }}
                                >
                                  Company Name is required *
                                </Typography>
                              )}
                              {withPlaybook && !(tags && tags?.length > 0) ? (
                                <Typography
                                  variant="caption"
                                  sx={{
                                    color: theme.palette.error.dark,
                                  }}
                                >
                                  Playbook title is required *
                                </Typography>
                              ) : null}
                            </Box>
                            {withPlaybook ? (
                              <Button
                                variant="contained"
                                disabled={
                                  updatingOrgDetails ||
                                  !organizationName.trim() ||
                                  (withPlaybook && tags && tags?.length === 0)
                                }
                                size="small"
                                type="submit"
                              >
                                {'Update'}
                              </Button>
                            ) : (
                              <Button
                                variant="contained"
                                size="small"
                                disabled={
                                  updatingOrgDetails || !organizationName.trim()
                                }
                                onClick={(e) => {
                                  setUpdatingOrgDetails(true);
                                  dispatch(
                                    updateCustomerDetails(
                                      currentOrgId,
                                      {
                                        organizationName,
                                        methodologies: methodologies,
                                        countries: [country],
                                        contentScoringConfig: withContentScoringConfig
                                          ? {
                                              inbound: sameConfig
                                                ? contentScoringConfig.outbound
                                                : contentScoringConfig.inbound,
                                              outbound:
                                                contentScoringConfig.outbound,
                                            }
                                          : null,
                                      },
                                      () => {
                                        setUpdatingOrgDetails(false);
                                        dispatch(
                                          getWlCustomers(
                                            userDetails?.organizationId,
                                            () => {},
                                          ),
                                        );
                                        dispatch(
                                          showAlert({
                                            message:
                                              'Details updated successfully.',
                                            showCross: true,
                                            title: null,
                                            type: 'success',
                                            autoHideDuration: 2000,
                                            vertical: 'top',
                                            horizontal: 'center',
                                          }),
                                        );
                                      },
                                    ),
                                  );
                                }}
                              >
                                Update
                              </Button>
                            )}
                          </Box>
                        </Box>
                      </form>
                    ) : currentTeamsTab === 'donna-presets' ? (
                      <Box
                        p={2}
                        display={'flex'}
                        flexDirection={'column'}
                        gap={1}
                      >
                        <Box
                          display={'flex'}
                          flexDirection={'column'}
                          gap={1}
                          position={'relative'}
                        >
                          <Typography
                            variant="h6"
                            color={'text.secondary'}
                            fontWeight={'bold'}
                          >
                            {orgConfigs?.navBarTitles?.['Clients Dashboard']
                              ?.subTitles?.['Ask Donna']?.title || 'Ask Donna'}
                          </Typography>
                          <Typography
                            variant="body1"
                            color={'text.secondary'}
                            // fontWeight={'bold'}
                          >
                            {orgConfigs?.navBarTitles?.['Clients Dashboard']
                              ?.subTitles?.['Ask Donna']?.title ||
                              'Ask Donna'}{' '}
                            is an AI assistant. Your customer can ask any
                            question related to the call that has been analyzed.
                          </Typography>
                          <Box
                            display={'flex'}
                            alignItems={'center'}
                            justifyContent={'space-between'}
                          >
                            <Box>
                              <Typography
                                variant="body1"
                                color={'text.secondary'}
                                fontWeight={'bold'}
                              >
                                Preset Questions
                              </Typography>
                              <Typography
                                variant="body2"
                                color={'text.secondary'}
                                // fontWeight={'bold'}
                              >
                                Preset questions that assistant will answer for
                                each call
                              </Typography>
                            </Box>
                            <Button
                              startIcon={<Add />}
                              variant="contained"
                              size="small"
                              sx={{
                                padding: '4px 8px',
                              }}
                              onClick={() => setShowQuestionDialog('create')}
                            >
                              Add new question
                            </Button>
                          </Box>
                          <Box
                            display={'flex'}
                            flexDirection={'column'}
                            gap={1}
                            sx={{
                              border:
                                customers?.find(
                                  (org) => org?.id === currentOrgId,
                                )?.presetQuestions &&
                                customers?.find(
                                  (org) => org?.id === currentOrgId,
                                )?.presetQuestions?.length === 0
                                  ? '1px solid #d3d3d3'
                                  : 'none',
                              borderRadius: 1,
                              padding:
                                customers?.find(
                                  (org) => org?.id === currentOrgId,
                                )?.presetQuestions &&
                                customers?.find(
                                  (org) => org?.id === currentOrgId,
                                )?.presetQuestions?.length === 0
                                  ? 2
                                  : 0,
                            }}
                          >
                            {customers?.find((org) => org?.id === currentOrgId)
                              ?.presetQuestions &&
                            customers?.find((org) => org?.id === currentOrgId)
                              ?.presetQuestions?.length > 0 ? (
                              customers
                                ?.find((org) => org?.id === currentOrgId)
                                ?.presetQuestions?.map((q, index) => (
                                  <Box
                                    sx={{
                                      border: '1px solid #d3d3d3',
                                      borderRadius: 1,
                                      padding: 1,
                                      display: 'flex',
                                      justifyContent: 'space-between',
                                    }}
                                    key={index}
                                  >
                                    <Typography>
                                      {index + 1}). {q}
                                    </Typography>
                                    <IconButton
                                      onClick={(e) => {
                                        setAnchorOptionsEl(e.currentTarget);
                                        setCurrentMenuIndex(index);
                                      }}
                                      sx={{
                                        padding: '4px',
                                      }}
                                    >
                                      <MoreVert
                                        sx={{
                                          fontSize: 20,
                                        }}
                                      />
                                    </IconButton>
                                    <Menu
                                      id="simple-menu"
                                      anchorEl={anchorOptionsEl}
                                      keepMounted
                                      open={
                                        Boolean(anchorOptionsEl) &&
                                        index === currentMenuIndex
                                      }
                                      onClose={() => setAnchorOptionsEl(null)}
                                      sx={{
                                        '& .MuiMenu-paper': {
                                          boxShadow:
                                            '0 2px 2px 2px rgba(140, 152, 164, 0.2)',
                                        },
                                      }}
                                    >
                                      <MenuItem
                                        onClick={() => {
                                          setShowQuestionDialog(q);
                                          setAnchorOptionsEl(null);
                                        }}
                                      >
                                        Update Question
                                      </MenuItem>
                                      <MenuItem
                                        onClick={() => {
                                          setShowConfirmDeleteQuestionDialog(q);
                                          setAnchorOptionsEl(null);
                                        }}
                                      >
                                        Delete Question
                                      </MenuItem>
                                    </Menu>
                                  </Box>
                                ))
                            ) : (
                              <Box>
                                <Typography
                                  variant="body2"
                                  color={'text.secondary'}
                                  // fontWeight={'bold'}
                                  sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    minHeight: 150,
                                  }}
                                >
                                  You can add preset questions here.
                                </Typography>
                              </Box>
                            )}
                          </Box>
                        </Box>
                      </Box>
                    ) : currentTeamsTab === 'active-plan' ? (
                      <Box
                        p={2}
                        display={'flex'}
                        flexDirection={'column'}
                        alignItems={'start'}
                        gap={1}
                      >
                        <Typography>{currentClientPlan?.title}</Typography>
                        {/* <FormControl size="small">
                          <Select
                            size="small"
                            labelId="active-plan-select-label"
                            id="active-plan-select"
                            name="selectedPlan"
                            MenuProps={{
                              disableScrollLock: true,
                            }}
                            sx={{
                              '& .MuiSelect-select': {
                                // padding: '4px 8px',
                              },
                            }}
                            onChange={(e) => {
                              console.log(e.target.value);
                              setCurrentClientPlan(e.target.value);
                              dispatch(
                                updateWlClientPlan(
                                  e.target.value,
                                  currentOrgId,
                                  () => {
                                    dispatch(
                                      showAlert({
                                        message: 'Plan updated successfully.',
                                        showCross: true,
                                        title: null,
                                        type: 'success',
                                        autoHideDuration: 2000,
                                        vertical: 'top',
                                        horizontal: 'center',
                                      }),
                                    );
                                  },
                                ),
                              );
                            }}
                            value={currentClientPlan || '-'}
                          >
                            <MenuItem value={'-'}>
                              <Typography variant="body2">
                                Select Plan
                              </Typography>
                            </MenuItem>
                            {plans?.map((plan) => (
                              <MenuItem key={plan?.id} value={plan?.id}>
                                <Typography variant="body2">
                                  {plan?.title}
                                </Typography>
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl> */}
                        {customers?.find((org) => org?.id === currentOrgId)
                          ?.plan &&
                        customers?.find((org) => org?.id === currentOrgId)?.plan
                          ?.features &&
                        Object.values(
                          customers?.find((org) => org?.id === currentOrgId)
                            ?.plan?.features || {},
                        )?.filter((obj) => obj?.addedAsAddOn)?.length > 0 ? (
                          <Box
                            display={'flex'}
                            flexDirection={'column'}
                            gap={1}
                          >
                            <Box display={'flex'} alignItems={'center'} gap={1}>
                              <Add
                                sx={{
                                  fontSize: 16,
                                  color: theme.palette.primary.main,
                                }}
                              />
                              <Typography
                                sx={{
                                  color: theme.palette.primary.main,
                                }}
                              >
                                {
                                  Object.values(
                                    customers?.find(
                                      (org) => org?.id === currentOrgId,
                                    )?.plan?.features || {},
                                  )?.filter((obj) => obj?.addedAsAddOn)?.length
                                }{' '}
                                Addons
                              </Typography>
                            </Box>
                            <Box ml={2}>
                              {Object.values(
                                customers?.find(
                                  (org) => org?.id === currentOrgId,
                                )?.plan?.features || {},
                              )
                                ?.filter((obj) => obj?.addedAsAddOn)
                                ?.map((feature) => (
                                  <Box
                                    key={feature?.feature}
                                    display={'flex'}
                                    alignItems={'center'}
                                    gap={1}
                                  >
                                    -{' '}
                                    {capitalizeText(
                                      feature?.feature?.split('_')?.join(' '),
                                    )}
                                  </Box>
                                ))}
                            </Box>
                          </Box>
                        ) : null}
                      </Box>
                    ) : null}
                  </Box>
                ) : viewType === 'dashboard' ? (
                  <PerformanceDashboard
                    uId={currentMemberId}
                    member={allTeamMembers?.members?.find(
                      (member) => member?.id === currentMemberId,
                    )}
                  />
                ) : null
              ) : currentTab === 'clients-ask-donna' ? (
                <Box p={2} display={'flex'} flexDirection={'column'} gap={1}>
                  <Box
                    display={'flex'}
                    flexDirection={'column'}
                    gap={1}
                    position={'relative'}
                  >
                    <Typography
                      variant="h6"
                      color={'text.secondary'}
                      fontWeight={'bold'}
                    >
                      {orgConfigs?.navBarTitles?.['Clients Dashboard']
                        ?.subTitles?.['Ask Donna']?.title || 'Ask Donna'}
                    </Typography>
                    <Typography
                      variant="body1"
                      color={'text.secondary'}
                      // fontWeight={'bold'}
                    >
                      {orgConfigs?.navBarTitles?.['Clients Dashboard']
                        ?.subTitles?.['Ask Donna']?.title || 'Ask Donna'}{' '}
                      is an AI Assistant. Your customers can ask any question
                      related to the call that has been analyzed.
                    </Typography>
                    <Box
                      display={'flex'}
                      alignItems={'center'}
                      justifyContent={'space-between'}
                    >
                      <Box>
                        <Typography
                          variant="body1"
                          color={'text.secondary'}
                          fontWeight={'bold'}
                        >
                          Preset Questions
                        </Typography>
                        <Typography
                          variant="body2"
                          color={'text.secondary'}
                          // fontWeight={'bold'}
                        >
                          Preset questions that assistant will answer for each
                          call of your customer
                        </Typography>
                      </Box>
                      <Button
                        startIcon={<Add />}
                        variant="contained"
                        size="small"
                        sx={{
                          padding: '4px 8px',
                        }}
                        onClick={() => setShowQuestionDialog('create')}
                      >
                        Add new question
                      </Button>
                    </Box>
                    <Box
                      display={'flex'}
                      flexDirection={'column'}
                      gap={1}
                      sx={{
                        border:
                          orgConfigs?.clientPresetQuestions &&
                          orgConfigs?.clientPresetQuestions?.length === 0
                            ? '1px solid #d3d3d3'
                            : 'none',
                        borderRadius: 1,
                        padding:
                          orgConfigs?.clientPresetQuestions &&
                          orgConfigs?.clientPresetQuestions?.length === 0
                            ? 2
                            : 0,
                      }}
                    >
                      {orgConfigs?.clientPresetQuestions &&
                      orgConfigs?.clientPresetQuestions?.length > 0 ? (
                        orgConfigs?.clientPresetQuestions?.map((q, index) => (
                          <Box
                            sx={{
                              border: '1px solid #d3d3d3',
                              borderRadius: 1,
                              padding: 1,
                              display: 'flex',
                              justifyContent: 'space-between',
                            }}
                            key={index}
                          >
                            <Typography>
                              {index + 1}). {q}
                            </Typography>
                            <IconButton
                              onClick={(e) => {
                                setAnchorOptionsEl(e.currentTarget);
                                setCurrentMenuIndex(index);
                              }}
                              sx={{
                                padding: '4px',
                              }}
                            >
                              <MoreVert
                                sx={{
                                  fontSize: 20,
                                }}
                              />
                            </IconButton>
                            <Menu
                              id="simple-menu"
                              anchorEl={anchorOptionsEl}
                              keepMounted
                              open={
                                Boolean(anchorOptionsEl) &&
                                index === currentMenuIndex
                              }
                              onClose={() => setAnchorOptionsEl(null)}
                              sx={{
                                '& .MuiMenu-paper': {
                                  boxShadow:
                                    '0 2px 2px 2px rgba(140, 152, 164, 0.2)',
                                },
                              }}
                            >
                              <MenuItem
                                onClick={() => {
                                  setShowQuestionDialog(q);
                                  setAnchorOptionsEl(null);
                                }}
                              >
                                Update Question
                              </MenuItem>
                              <MenuItem
                                onClick={() => {
                                  setShowConfirmDeleteQuestionDialog(q);
                                  setAnchorOptionsEl(null);
                                }}
                              >
                                Delete Question
                              </MenuItem>
                            </Menu>
                          </Box>
                        ))
                      ) : (
                        <Box>
                          <Typography
                            variant="body2"
                            color={'text.secondary'}
                            // fontWeight={'bold'}
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              minHeight: 150,
                            }}
                          >
                            You can add your own preset questions.
                          </Typography>
                        </Box>
                      )}
                    </Box>

                    {/* {orgConfigs?.defaultPresetQuestions?.questions &&
                    orgConfigs?.defaultPresetQuestions?.questions?.length >
                      0 ? (
                      <>
                        <Divider />
                        <Typography
                          variant="body2"
                          color={'text.secondary'}
                          fontWeight={'bold'}
                        >
                          Here are few suggested questions that you can add to
                          your list.
                        </Typography>
                        <Box display={'flex'} flexDirection={'column'} gap={1}>
                          {orgConfigs?.defaultPresetQuestions?.questions &&
                          orgConfigs?.defaultPresetQuestions?.questions
                            ?.length > 0
                            ? orgConfigs?.defaultPresetQuestions?.questions?.map(
                                (q, index) => (
                                  <Box
                                    sx={{
                                      border: '1px solid #d3d3d3',
                                      borderRadius: 1,
                                      padding: 1,
                                    }}
                                    key={index}
                                  >
                                    <Typography>
                                      {index + 1}). {q}
                                    </Typography>
                                  </Box>
                                ),
                              )
                            : null}
                        </Box>
                      </>
                    ) : null} */}
                  </Box>
                </Box>
              ) : currentTab === 'clients-playbooks' ? (
                <Box display={'flex'} flexDirection={'column'} gap={1}>
                  <Tabs
                    value={currentPlaybooksTab}
                    onChange={(e, newValue) => setCurrentPlaybookTabs(newValue)}
                    aria-label="Playbooks Tabs"
                    orientation={'horizontal'}
                    // indicatorColor={theme.palette.background.default}
                    variant="scrollable"
                    // scrollButtons={!isMd}
                    allowScrollButtonsMobile
                    sx={{
                      border: '1px solid #d3d3d3',
                    }}
                  >
                    <Tab
                      value={'templates'}
                      label={
                        <Typography variant="body1">
                          Playbook Templates
                        </Typography>
                      }
                      id={`simple-tab-${0}`}
                      aria-controls={`simple-tabpanel-${0}`}
                      sx={{}}
                    />
                    <Tab
                      value={'playbooks'}
                      label={<Typography variant="body1">Playbooks</Typography>}
                      id={`simple-tab-${0}`}
                      aria-controls={`simple-tabpanel-${0}`}
                      sx={{}}
                    />
                  </Tabs>
                  <Box
                    sx={{
                      // border: '1px solid #d3d3d3',
                      padding: 1,
                    }}
                  >
                    {currentPlaybooksTab === 'templates' ? (
                      <Box>
                        <Box
                          display={'flex'}
                          alignItems={'center'}
                          gap={2}
                          mb={2}
                        >
                          <Button
                            variant="contained"
                            color="primary"
                            startIcon={<Add />}
                            size="small"
                            onClick={() => setShowEditTemplateDialog('new')}
                          >
                            Create Template
                          </Button>
                        </Box>
                        {playbookTemplates && playbookTemplates.length > 0 ? (
                          <Grid container spacing={2}>
                            {playbookTemplates.map((template, index) => (
                              <Grid
                                item
                                xs={12}
                                key={template?.id}
                                sm={6}
                                md={4}
                                lg={4}
                              >
                                <Card
                                  sx={{
                                    padding: 2,
                                    position: 'relative',
                                    paddingBottom: 6,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: 1,
                                    alignItems: 'start',
                                  }}
                                >
                                  <Typography
                                    variant="body1"
                                    fontWeight={'bold'}
                                    color={'text.secondary'}
                                  >
                                    {template?.title}
                                  </Typography>
                                  <Chip
                                    size="small"
                                    color="primary"
                                    variant="outlined"
                                    label={
                                      template?.defaultTemplate
                                        ? 'Default'
                                        : 'Custom'
                                    }
                                  />
                                  <Typography
                                    variant="subtitle2"
                                    color={'text.secondary'}
                                  >
                                    {(Object.keys(
                                      template?.defaultPlaybookFields || {},
                                    )?.length || 0) +
                                      (template?.customPlayBookFields?.length ||
                                        0)}{' '}
                                    fields
                                  </Typography>
                                  <Button
                                    size="small"
                                    variant="outlined"
                                    onClick={() => {
                                      setShowTemplatePreview(template);
                                      setExpandedField(['default', 'custom']);
                                    }}
                                  >
                                    Preview
                                  </Button>
                                  <Typography
                                    variant="caption"
                                    color={'text.secondary'}
                                    sx={{
                                      position: 'absolute',
                                      bottom: 18,
                                      right: 18,
                                    }}
                                  >
                                    Created On{' '}
                                    {moment(template?.createOn).format(
                                      'DD MMM YYYY',
                                    )}
                                  </Typography>
                                  <IconButton
                                    size="small"
                                    onClick={(e) => {
                                      setAnchorElTemplate(e.target);
                                      setCurrentTemplateIndex(index);
                                    }}
                                    sx={{
                                      position: 'absolute',
                                      top: 5,
                                      right: 5,
                                    }}
                                  >
                                    <MoreVert />
                                  </IconButton>
                                  <Menu
                                    id="simple-menu"
                                    anchorEl={anchorElTemplate}
                                    keepMounted
                                    open={
                                      Boolean(anchorElTemplate) &&
                                      currentTemplateIndex === index
                                    }
                                    onClose={handleTemplateMenuClose}
                                    sx={{
                                      '& .MuiMenu-paper': {
                                        boxShadow:
                                          '0 2px 2px 2px rgba(140, 152, 164, 0.2)',
                                      },
                                    }}
                                  >
                                    <MenuItem
                                      onClick={() => {
                                        setShowEditTemplateDialog(template);
                                        handleTemplateMenuClose();
                                      }}
                                    >
                                      Edit Template
                                    </MenuItem>
                                    <MenuItem
                                      onClick={() => {
                                        setShowConfirmTemplateDeleteDialog(
                                          template?.id,
                                        );
                                        setAnchorElTemplate(null);
                                      }}
                                    >
                                      Delete Template
                                    </MenuItem>
                                  </Menu>
                                </Card>
                              </Grid>
                            ))}
                          </Grid>
                        ) : (
                          <Box
                            width={1}
                            height={1}
                            display={'flex'}
                            justifyContent={'center'}
                            alignItems={'center'}
                            flexDirection={'column'}
                            gap={2}
                          >
                            <Typography
                              variant="body1"
                              color={'text.secondary'}
                              mt={4}
                            >
                              No playbook templates found
                            </Typography>
                          </Box>
                        )}
                      </Box>
                    ) : (
                      <Box>
                        <Box
                          display={'flex'}
                          alignItems={'center'}
                          gap={2}
                          mb={2}
                        >
                          <Button
                            variant="contained"
                            color="primary"
                            startIcon={<Add />}
                            size="small"
                            onClick={() => setShowEditPlaybookDialog('new')}
                          >
                            Create Playbook
                          </Button>
                        </Box>
                        {playbooks && playbooks.length > 0 ? (
                          <Grid container spacing={2}>
                            {playbooks.map((playbook, index) => (
                              <Grid
                                item
                                xs={12}
                                key={playbook?.id}
                                sm={6}
                                md={4}
                                lg={4}
                              >
                                <Card
                                  sx={{
                                    padding: 2,
                                    position: 'relative',
                                    paddingBottom: 12,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: 1,
                                    alignItems: 'start',
                                  }}
                                >
                                  <Typography
                                    variant="body1"
                                    fontWeight={'bold'}
                                    color={'text.secondary'}
                                  >
                                    {playbook?.tags?.[0]?.trim()}
                                  </Typography>
                                  {/* <Chip
                                    size="small"
                                    color="primary"
                                    variant="outlined"
                                    label={
                                      playbook?.defaultTemplate
                                        ? 'Default'
                                        : 'Custom'
                                    }
                                  /> */}
                                  {/* <Typography
                                    variant="subtitle2"
                                    color={'text.secondary'}
                                  >
                                    {(Object.keys(
                                      playbook?.defaultPlaybookFields || {},
                                    )?.length || 0) +
                                      (playbook?.customPlayBookFields?.length ||
                                        0)}{' '}
                                    fields
                                  </Typography> */}
                                  <Typography
                                    variant="caption"
                                    color={'text.secondary'}
                                    sx={{
                                      position: 'absolute',
                                      bottom: 18,
                                      right: 18,
                                    }}
                                  >
                                    Created On{' '}
                                    {moment(playbook?.createOn).format(
                                      'DD MMM YYYY',
                                    )}
                                  </Typography>
                                  {/* {index !== 0 ? ( */}
                                  <IconButton
                                    size="small"
                                    onClick={(e) => {
                                      setAnchorElPlaybook(e.target);
                                      setCurrentPlaybookIndex(index);
                                    }}
                                    sx={{
                                      position: 'absolute',
                                      top: 5,
                                      right: 5,
                                    }}
                                  >
                                    <MoreVert />
                                  </IconButton>
                                  {/* ) : null} */}
                                  <Menu
                                    id="simple-menu"
                                    anchorEl={anchorElPlaybook}
                                    keepMounted
                                    open={
                                      Boolean(anchorElPlaybook) &&
                                      currentPlaybookIndex === index
                                    }
                                    onClose={handlePlaybookMenuClose}
                                    sx={{
                                      '& .MuiMenu-paper': {
                                        boxShadow:
                                          '0 2px 2px 2px rgba(140, 152, 164, 0.2)',
                                      },
                                    }}
                                  >
                                    <MenuItem
                                      onClick={() => {
                                        setShowEditPlaybookDialog(playbook);
                                        handlePlaybookMenuClose();
                                      }}
                                    >
                                      Edit Playbook
                                    </MenuItem>
                                    <MenuItem
                                      onClick={() => {
                                        setShowConfirmPlaybookDeleteDialog(
                                          playbook?.id,
                                        );
                                        setAnchorElPlaybook(null);
                                      }}
                                    >
                                      Delete Playbook
                                    </MenuItem>
                                  </Menu>
                                </Card>
                              </Grid>
                            ))}
                          </Grid>
                        ) : (
                          <Box
                            width={1}
                            height={1}
                            display={'flex'}
                            justifyContent={'center'}
                            alignItems={'center'}
                            flexDirection={'column'}
                            gap={2}
                          >
                            <Typography
                              variant="body1"
                              color={'text.secondary'}
                              mt={4}
                            >
                              No playbook found
                            </Typography>
                          </Box>
                        )}
                      </Box>
                    )}
                  </Box>
                </Box>
              ) : currentTab === 'plans' ? (
                <Box p={2} display={'flex'} flexDirection={'column'} gap={1}>
                  <Box marginBottom={2}>
                    <Box marginBottom={2}>
                      <Typography
                        sx={{
                          // textTransform: 'uppercase',
                          fontWeight: 'medium',
                          fontSize: 20,
                        }}
                        color={'primary'}
                        align={'center'}
                      >
                        Available Plans and Subscriptions
                      </Typography>
                    </Box>
                    <Typography
                      sx={{ fontWeight: 700 }}
                      variant={'h5'}
                      align={'center'}
                      gutterBottom
                    >
                      Startup, SMB or Enterprise, we have a plan for you.
                    </Typography>
                    <Typography
                      variant={'body1'}
                      component={'p'}
                      color={'text.secondary'}
                      align={'center'}
                    >
                      Flexible plans that can be adapted to your customer needs.
                    </Typography>
                  </Box>
                  <Grid container spacing={isMd ? 2 : 2}>
                    {plans?.map((plan, i) => (
                      <Grid
                        item
                        container
                        // alignItems={'center'}
                        xs={12}
                        sm={6}
                        md={6}
                        key={plan?.id}
                        data-aos={'fade-up'}
                        data-aos-delay={i * 100}
                        data-aos-offset={100}
                        data-aos-duration={600}
                      >
                        <Box
                          component={Card}
                          display={'flex'}
                          flexDirection={'column'}
                          border={0}
                          width={1}
                          position={'relative'}
                          // paddingY={item.isHighlighted && isMd ? 8 : 2}
                        >
                          <CardContent
                            sx={{
                              background: '#fff',
                              color: theme.palette.primary.main,
                              // border: '5px solid #f1f1f1',
                              position: 'relative',
                            }}
                          >
                            <IconButton
                              size="small"
                              onClick={(e) => {
                                setAnchorEl(e.target);
                                setCurrentPlanIndex(i);
                              }}
                              sx={{
                                position: 'absolute',
                                top: 5,
                                right: 5,
                              }}
                            >
                              <MoreVert />
                            </IconButton>
                            <Menu
                              id="simple-menu"
                              anchorEl={anchorEl}
                              keepMounted
                              open={Boolean(anchorEl) && currentPlanIndex === i}
                              onClose={handleMenuClose}
                              sx={{
                                '& .MuiMenu-paper': {
                                  boxShadow:
                                    '0 2px 2px 2px rgba(140, 152, 164, 0.2)',
                                },
                              }}
                            >
                              <MenuItem
                                onClick={() => {
                                  handleMenuClose();
                                  setShowPlanPricingUpdateDialog(i);
                                }}
                              >
                                Update Plan Pricing
                              </MenuItem>
                              {/* <MenuItem
                                onClick={() => {
                                  setAnchorEl(null);
                                  setShowConfirmPublishDialog(true);
                                }}
                              >
                                {plan?.publishedForPricingPage
                                  ? 'Unpublish'
                                  : 'Publish'}
                              </MenuItem> */}
                            </Menu>
                            <Box
                              marginBottom={4}
                              display={'flex'}
                              flexDirection={'column'}
                              alignItems={'center'}
                            >
                              <Typography variant={'h6'}>
                                <Box component={'span'} fontWeight={600}>
                                  {capitalizeText(plan.title)}
                                </Box>
                                {plan?.publishedForPricingPage ? (
                                  <Chip
                                    label={'published'}
                                    color="primary"
                                    size="small"
                                    sx={{
                                      marginLeft: 2,
                                    }}
                                  />
                                ) : null}
                              </Typography>
                              {/* {plan?.trailPeriodDays ? (
                                <Typography variant="body2">
                                  {plan?.trailPeriodDays} days trial
                                </Typography>
                              ) : null} */}
                              {plan?.price ? (
                                <Box
                                  display={'flex'}
                                  alignItems={'center'}
                                  // gap={1}
                                  mt={2}
                                >
                                  <Typography
                                    sx={{
                                      fontSize: 28,
                                      color: theme.palette.primary.main,
                                      fontWeight: 'bold',
                                    }}
                                  >
                                    {plan?.price?.currency === 'usd'
                                      ? '$'
                                      : '&#8377;'}
                                    {plan?.price?.amount}
                                  </Typography>
                                  <Typography>
                                    /{plan?.price?.priceUnit}/
                                    {plan?.price?.pricingDuration}
                                  </Typography>
                                </Box>
                              ) : null}
                            </Box>
                            <Grid container spacing={1}>
                              {Object.values(plan.features || {})
                                .sort((a, b) => {
                                  if (a.feature > b.feature) {
                                    return 1;
                                  } else if (b.feature > a.feature) {
                                    return -1;
                                  } else {
                                    return 0;
                                  }
                                })
                                .map((feature, j) => (
                                  <Grid item xs={12} key={j}>
                                    <Box
                                      display={'flex'}
                                      alignItems={'center'}
                                      gap={2}
                                      justifyContent={'space-between'}
                                      mb={2}
                                    >
                                      <Typography
                                        component={'p'}
                                        sx={{
                                          fontSize: 18,
                                          fontWeight: 'bold',
                                        }}
                                      >
                                        {capitalizeText(
                                          feature.feature
                                            ?.split('_')
                                            ?.join(' '),
                                        ) === 'Practice Call'
                                          ? 'Roleplay'
                                          : capitalizeText(
                                              feature.feature
                                                ?.split('_')
                                                ?.join(' '),
                                            ) === 'Donna'
                                          ? 'AI Assistant'
                                          : capitalizeText(
                                              feature.feature
                                                ?.split('_')
                                                ?.join(' '),
                                            )}
                                        {feature.feature === 'practice_call' ||
                                        feature.feature === 'task'
                                          ? 's'
                                          : null}
                                      </Typography>
                                    </Box>
                                    {feature.feature === 'call_analysis' ? (
                                      <Box
                                        ml={2}
                                        display={'flex'}
                                        flexDirection={'column'}
                                        gap={1}
                                      >
                                        <Box display={'flex'} gap={1}>
                                          <Check />
                                          <Typography>
                                            {`${
                                              Object.values(
                                                feature?.restrictions || {},
                                              )?.find(
                                                (res) =>
                                                  res.restriction ===
                                                  'number_of_sale_call_recording_analysis',
                                              )?.unlimited
                                                ? 'Unlimited'
                                                : Object.values(
                                                    feature?.restrictions || {},
                                                  )?.find(
                                                    (res) =>
                                                      res.restriction ===
                                                      'number_of_sale_call_recording_analysis',
                                                  )?.limit
                                            } analysis / user / month`}
                                          </Typography>
                                        </Box>
                                        <Box display={'flex'} gap={1}>
                                          <Check />
                                          <Typography>
                                            {`${Math.round(
                                              (Object.values(
                                                feature?.restrictions || {},
                                              )?.find(
                                                (res) =>
                                                  res.restriction ===
                                                  'duration_of_sale_call_recording_analysis',
                                              )?.unlimited
                                                ? 'Unlimited'
                                                : Object.values(
                                                    feature?.restrictions || {},
                                                  )?.find(
                                                    (res) =>
                                                      res.restriction ===
                                                      'duration_of_sale_call_recording_analysis',
                                                  )?.limit) / 60000,
                                            )} minutes / file upload`}
                                          </Typography>
                                        </Box>
                                      </Box>
                                    ) : feature.feature === 'practice_call' ? (
                                      <Box
                                        ml={2}
                                        display={'flex'}
                                        flexDirection={'column'}
                                        gap={1}
                                      >
                                        <Box display={'flex'} gap={1}>
                                          <Check />
                                          <Typography>
                                            {`${
                                              Object.values(
                                                feature?.restrictions || {},
                                              )?.find(
                                                (res) =>
                                                  res.restriction ===
                                                  'number_of_practice_call',
                                              )?.unlimited
                                                ? 'Unlimited'
                                                : Object.values(
                                                    feature?.restrictions || {},
                                                  )?.find(
                                                    (res) =>
                                                      res.restriction ===
                                                      'number_of_practice_call',
                                                  )?.limit
                                            } calls / user / month`}
                                          </Typography>
                                        </Box>
                                        <Box display={'flex'} gap={1}>
                                          <Check />
                                          <Typography>
                                            {`${Math.round(
                                              (Object.values(
                                                feature?.restrictions || {},
                                              )?.find(
                                                (res) =>
                                                  res.restriction ===
                                                  'duration_of_practice_call',
                                              )?.unlimited
                                                ? 'Unlimited'
                                                : Object.values(
                                                    feature?.restrictions || {},
                                                  )?.find(
                                                    (res) =>
                                                      res.restriction ===
                                                      'duration_of_practice_call',
                                                  )?.limit) / 60000,
                                            )} minutes / call`}
                                          </Typography>
                                        </Box>
                                        <Box display={'flex'} gap={1}>
                                          <Check />
                                          <Typography>
                                            {`${
                                              Object.values(
                                                feature?.restrictions || {},
                                              )?.find(
                                                (res) =>
                                                  res.restriction ===
                                                  'standard_bots',
                                              )?.unlimited
                                                ? 'Unlimited'
                                                : Object.values(
                                                    feature?.restrictions || {},
                                                  )?.find(
                                                    (res) =>
                                                      res.restriction ===
                                                      'standard_bots',
                                                  )?.limit
                                            } standard bots`}
                                          </Typography>
                                        </Box>
                                        <Box display={'flex'} gap={1}>
                                          {Object.values(
                                            feature?.restrictions || {},
                                          )?.find(
                                            (res) =>
                                              res.restriction === 'custom_bots',
                                          )?.unlimited ||
                                          Object.values(
                                            feature?.restrictions || {},
                                          )?.find(
                                            (res) =>
                                              res.restriction === 'custom_bots',
                                          )?.limit > 0 ? (
                                            <Check />
                                          ) : (
                                            <Close />
                                          )}
                                          <Typography>
                                            {`${
                                              Object.values(
                                                feature?.restrictions || {},
                                              )?.find(
                                                (res) =>
                                                  res.restriction ===
                                                  'custom_bots',
                                              )?.unlimited
                                                ? 'Unlimited'
                                                : Object.values(
                                                    feature?.restrictions || {},
                                                  )?.find(
                                                    (res) =>
                                                      res.restriction ===
                                                      'custom_bots',
                                                  )?.limit
                                            } customized bots`}
                                          </Typography>
                                        </Box>
                                      </Box>
                                    ) : feature.feature === 'donna' ? (
                                      <Box
                                        ml={2}
                                        display={'flex'}
                                        flexDirection={'column'}
                                        gap={1}
                                      >
                                        <Box display={'flex'} gap={1}>
                                          <Check />
                                          <Typography>
                                            Your own ChatGPT like AI Assistant
                                          </Typography>
                                        </Box>
                                      </Box>
                                    ) : feature.feature === 'task' ? (
                                      <Box
                                        ml={2}
                                        display={'flex'}
                                        flexDirection={'column'}
                                        gap={1}
                                      >
                                        <Box display={'flex'} gap={1}>
                                          <Check />
                                          <Typography>
                                            Automatic task creation from calls
                                          </Typography>
                                        </Box>
                                        <Box display={'flex'} gap={1}>
                                          <Check />
                                          <Typography>
                                            Task management
                                          </Typography>
                                        </Box>
                                      </Box>
                                    ) : null}
                                  </Grid>
                                ))}
                              <Grid item xs={12}>
                                <Box
                                  display={'flex'}
                                  alignItems={'center'}
                                  gap={2}
                                  justifyContent={'space-between'}
                                  mb={2}
                                >
                                  <Typography
                                    component={'p'}
                                    sx={{ fontSize: 18, fontWeight: 'bold' }}
                                  >
                                    Team Account
                                  </Typography>
                                </Box>
                                <Box
                                  ml={2}
                                  display={'flex'}
                                  flexDirection={'column'}
                                  gap={1}
                                >
                                  <Box display={'flex'} gap={1}>
                                    {plan?.title === 'free' ? (
                                      <Close />
                                    ) : (
                                      <Check />
                                    )}
                                    <Typography>
                                      Centralized Playbook and Bot Management
                                    </Typography>
                                  </Box>
                                  <Box display={'flex'} gap={1}>
                                    {plan?.title === 'free' ? (
                                      <Close />
                                    ) : (
                                      <Check />
                                    )}
                                    <Typography>
                                      Performance Tracking Dashboard
                                    </Typography>
                                  </Box>
                                  <Box display={'flex'} gap={1}>
                                    {plan?.title === 'free' ? (
                                      <Close />
                                    ) : (
                                      <Check />
                                    )}
                                    <Typography>Roleplays Scheduler</Typography>
                                  </Box>
                                </Box>
                              </Grid>
                              <Grid item xs={12}>
                                <Box
                                  display={'flex'}
                                  alignItems={'center'}
                                  gap={2}
                                  justifyContent={'space-between'}
                                  mb={2}
                                >
                                  <Typography
                                    component={'p'}
                                    sx={{ fontSize: 18, fontWeight: 'bold' }}
                                  >
                                    Integrations
                                  </Typography>
                                </Box>
                                <Box
                                  ml={2}
                                  display={'flex'}
                                  flexDirection={'column'}
                                  gap={1}
                                >
                                  <Box display={'flex'} gap={1}>
                                    {plan?.title === 'free' ? (
                                      <Close />
                                    ) : (
                                      <Check />
                                    )}
                                    <Typography>
                                      Integrations with CRMs like HubSpot,
                                      LeadSquared and others
                                    </Typography>
                                  </Box>
                                  <Box display={'flex'} gap={1}>
                                    {plan?.title === 'free' ? (
                                      <Close />
                                    ) : (
                                      <Check />
                                    )}
                                    <Typography>
                                      Calendar Integration
                                    </Typography>
                                  </Box>
                                  <Box display={'flex'} gap={1}>
                                    {plan?.title === 'free' ? (
                                      <Close />
                                    ) : (
                                      <Check />
                                    )}
                                    <Typography>
                                      Auto-analysis of meeting recordings
                                    </Typography>
                                  </Box>
                                </Box>
                              </Grid>
                              <Grid item xs={12}>
                                <Box
                                  display={'flex'}
                                  alignItems={'center'}
                                  gap={2}
                                  justifyContent={'space-between'}
                                  mb={2}
                                >
                                  <Typography
                                    component={'p'}
                                    sx={{ fontSize: 18, fontWeight: 'bold' }}
                                  >
                                    Dedicated Support
                                  </Typography>
                                </Box>
                                <Box
                                  ml={2}
                                  display={'flex'}
                                  flexDirection={'column'}
                                  gap={1}
                                >
                                  <Box display={'flex'} gap={1}>
                                    {plan?.title === 'free' ? (
                                      <Close />
                                    ) : (
                                      <Check />
                                    )}
                                    <Typography>
                                      Support for customizations, automations,
                                      onboarding and training
                                    </Typography>
                                  </Box>
                                </Box>
                              </Grid>
                            </Grid>
                          </CardContent>
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              ) : null}
            </>
          )}
        </Box>
        {/* </Paper> */}
      </Container>
      <Dialog
        open={Boolean(showPlanPricingUpdateDialog !== null)}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{
          // zIndex: 10002,
          '& .MuiPaper-root-MuiDialog-paper': {
            maxWidth: '70vw',
          },
        }}
      >
        {/* <DialogTitle id="alert-dialog-title">Update Plan Pricing</DialogTitle> */}
        <DialogContent
          sx={{
            padding: 0,
          }}
        >
          <Box
            display={'flex'}
            alignItems={'center'}
            gap={2}
            borderBottom={'1px solid #d3d3d3'}
            p={2}
          >
            <DialogContentText id="alert-dialog-description" flex={1}>
              Update default pricing for plan
            </DialogContentText>
          </Box>
          <Box display={'flex'} gap={2} p={2}>
            <Box>
              <Typography color={'text.secondary'}>Price</Typography>
              <TextField
                // label="Price*"
                variant="outlined"
                name={'planPrice'}
                size="small"
                fullWidth
                value={planPrice}
                onChange={(e) => setPlanPrice(e.target.value)}
              />
            </Box>
            <Box>
              <Typography color={'text.secondary'}>Unit</Typography>
              <FormControl fullWidth size="small">
                <Select
                  size="small"
                  labelId="plan-unit-select-label"
                  id="plan-unit-select"
                  name="priceUnit"
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                  sx={{
                    '& .MuiSelect-select': {
                      // zIndex: 1111111,
                      // padding: '4px 8px',
                    },
                  }}
                  onChange={(e) => {
                    setPriceUnit(e.target.value);
                  }}
                  value={priceUnit}
                >
                  <MenuItem value={'usd'}>
                    <Typography variant="body2">USD</Typography>
                  </MenuItem>
                  <MenuItem value={'inr'}>
                    <Typography variant="body2">INR</Typography>
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box>
              <Typography color={'text.secondary'}>Scheme</Typography>
              <Typography color={'text.secondary'}>/seat/month</Typography>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions
          sx={{
            borderTop: '1px solid #d3d3d3',
          }}
        >
          <Button
            // data-trackid={'cta_permission_denied_dialog_close'}
            onClick={(e) => {
              // handleTrackEvent(e, `cta_permission_denied_dialog_close`);
              setShowPlanPricingUpdateDialog(null);
              // if (testMode) setShowTestDeviceDialog(null);
            }}
            color="primary"
            // autoFocus
          >
            Cancel
          </Button>
          <Button
            size="small"
            variant="contained"
            disabled={updatingPlan}
            // data-trackid={'cta_permission_denied_dialog_close'}
            onClick={(e) => {
              setUpdatingPlan(true);
              dispatch(
                updatePlanWL(
                  plans?.[showPlanPricingUpdateDialog]?.id,
                  {
                    price: {
                      amount: Number(planPrice),
                      currency: priceUnit,
                      priceUnit: 'seat',
                      pricingDuration: 'monthly',
                      pricingScheme: 'seat_based',
                    },
                  },
                  (error) => {
                    setUpdatingPlan(false);
                    setShowPlanPricingUpdateDialog(null);
                    if (error) {
                      dispatch(
                        showAlert({
                          message:
                            'Failed to update plan. Please try again later!',
                          showCross: true,
                          title: null,
                          type: 'error',
                          autoHideDuration: 2000,
                          vertical: 'top',
                          horizontal: 'center',
                        }),
                      );
                    } else {
                      setPlans((prev) =>
                        prev?.map((plan) =>
                          plan?.id === plans?.[currentPlanIndex]?.id
                            ? {
                                ...plan,
                                price: {
                                  amount: planPrice,
                                  currency: priceUnit,
                                  priceUnit: 'seat',
                                  pricingDuration: 'monthly',
                                  pricingScheme: 'seat_based',
                                },
                              }
                            : plan,
                        ),
                      );
                      dispatch(
                        showAlert({
                          message: 'Plan is updated successfully.',
                          showCross: true,
                          title: null,
                          type: 'success',
                          autoHideDuration: 2000,
                          vertical: 'top',
                          horizontal: 'center',
                        }),
                      );
                    }
                  },
                ),
              );
            }}
            color="primary"
            autoFocus
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={Boolean(showConfirmPublishDialog)}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{
          zIndex: 10002,
          '& .MuiPaper-root-MuiDialog-paper': {
            maxWidth: '70vw',
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">Confirm</DialogTitle>
        <DialogContent>
          <Box display={'flex'} alignItems={'center'} gap={2}>
            <DialogContentText id="alert-dialog-description" flex={1}>
              {plans?.[currentPlanIndex]?.publishedForPricingPage
                ? 'Are you sure you want to unpublish this plan?'
                : 'Are you sure you want to make this plan publicly visible to all of your client?'}
            </DialogContentText>
          </Box>
          <Typography variant="subtitle2">
            {plans?.[currentPlanIndex]?.publishedForPricingPage
              ? 'This will remove this plan from public pricing page from all of your customer.'
              : "This will make this plan visible on your client's pricing page."}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            // data-trackid={'cta_permission_denied_dialog_close'}
            onClick={(e) => {
              // handleTrackEvent(e, `cta_permission_denied_dialog_close`);
              setShowConfirmPublishDialog(null);
              // if (testMode) setShowTestDeviceDialog(null);
            }}
            color="primary"
            // autoFocus
          >
            Cancel
          </Button>
          <Button
            size="small"
            variant="contained"
            disabled={publishingPlan}
            // data-trackid={'cta_permission_denied_dialog_close'}
            onClick={(e) => {
              setPublishingPlan(true);
              dispatch(
                updatePlanWL(
                  plans?.[currentPlanIndex]?.id,
                  {
                    publishedForPricingPage: !plans?.[currentPlanIndex]
                      ?.publishedForPricingPage,
                  },
                  (error) => {
                    setPublishingPlan(false);
                    setShowConfirmPublishDialog(null);
                    if (error) {
                      dispatch(
                        showAlert({
                          message:
                            'Failed to publish plan. Please try again later!',
                          showCross: true,
                          title: null,
                          type: 'error',
                          autoHideDuration: 2000,
                          vertical: 'top',
                          horizontal: 'center',
                        }),
                      );
                    } else {
                      setPlans((prev) =>
                        prev?.map((plan) =>
                          plan?.id === plans?.[currentPlanIndex]?.id
                            ? {
                                ...plan,
                                publishedForPricingPage: !plans?.[
                                  currentPlanIndex
                                ]?.publishedForPricingPage,
                              }
                            : plan,
                        ),
                      );
                      dispatch(
                        showAlert({
                          message: 'Plan is published successfully.',
                          showCross: true,
                          title: null,
                          type: 'success',
                          autoHideDuration: 2000,
                          vertical: 'top',
                          horizontal: 'center',
                        }),
                      );
                    }
                  },
                ),
              );
            }}
            color="primary"
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={Boolean(showConfirmDeleteDialog)}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{
          zIndex: 10002,
          '& .MuiPaper-root-MuiDialog-paper': {
            maxWidth: '70vw',
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">Confirm</DialogTitle>
        <DialogContent>
          <Box display={'flex'} alignItems={'center'} gap={2}>
            <DialogContentText id="alert-dialog-description" flex={1}>
              Are you sure you want to delete this user from the organization?
            </DialogContentText>
          </Box>
          <Typography variant="caption">
            This will remove user from the organization and they will not be
            able to login again with same account.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            // data-trackid={'cta_permission_denied_dialog_close'}
            onClick={(e) => {
              // handleTrackEvent(e, `cta_permission_denied_dialog_close`);
              setShowConfirmDeleteDialog(null);
              // if (testMode) setShowTestDeviceDialog(null);
            }}
            color="primary"
            // autoFocus
          >
            Cancel
          </Button>
          <Button
            size="small"
            variant="contained"
            disabled={removingUser}
            // data-trackid={'cta_permission_denied_dialog_close'}
            onClick={(e) => {
              setRemovingUser(true);
              dispatch(
                removeUserFromOrg(showConfirmDeleteDialog, () => {
                  dispatch(
                    getAllTeamMembers(currentOrgId, 25, null, (data) => {
                      setRemovingUser(false);
                      setShowConfirmDeleteDialog(null);
                    }),
                  );
                }),
              );
            }}
            color="primary"
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={Boolean(showConfirmTemplateDeleteDialog)}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{
          zIndex: 10002,
          '& .MuiPaper-root-MuiDialog-paper': {
            maxWidth: '70vw',
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">Confirm</DialogTitle>
        <DialogContent>
          <Box display={'flex'} alignItems={'center'} gap={2}>
            <DialogContentText id="alert-dialog-description" flex={1}>
              Are you sure you want to delete this template?
            </DialogContentText>
          </Box>
          {/* <Typography variant="caption">
            This will remove this template.
          </Typography> */}
        </DialogContent>
        <DialogActions>
          <Button
            // data-trackid={'cta_permission_denied_dialog_close'}
            onClick={(e) => {
              // handleTrackEvent(e, `cta_permission_denied_dialog_close`);
              setShowConfirmTemplateDeleteDialog(null);
              // if (testMode) setShowTestDeviceDialog(null);
            }}
            color="primary"
            // autoFocus
          >
            Cancel
          </Button>
          <Button
            size="small"
            variant="contained"
            disabled={deletingTemplate}
            // data-trackid={'cta_permission_denied_dialog_close'}
            onClick={(e) => {
              setDeletingTemplate(true);
              dispatch(
                deletePlaybookTemplate(showConfirmTemplateDeleteDialog, () => {
                  dispatch(
                    getPlaybookTemplates((data) => {
                      console.log(data);
                      setPlaybookTemplates(data || []);
                      setDeletingTemplate(false);
                      setShowConfirmTemplateDeleteDialog(false);
                    }),
                  );
                }),
              );
            }}
            color="primary"
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={Boolean(showConfirmPlaybookDeleteDialog)}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{
          zIndex: 10002,
          '& .MuiPaper-root-MuiDialog-paper': {
            maxWidth: '70vw',
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">Confirm</DialogTitle>
        <DialogContent>
          <Box display={'flex'} alignItems={'center'} gap={2}>
            <DialogContentText id="alert-dialog-description" flex={1}>
              Are you sure you want to delete this playbook?
            </DialogContentText>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={(e) => {
              setShowConfirmPlaybookDeleteDialog(null);
            }}
            color="primary"
            // autoFocus
          >
            Cancel
          </Button>
          <Button
            size="small"
            variant="contained"
            disabled={deletingTemplate}
            onClick={(e) => {
              setDeletingPlaybook(true);
              dispatch(
                deletePlaybook(showConfirmPlaybookDeleteDialog, () => {
                  dispatch(
                    getPlaybooks((data) => {
                      console.log(data);
                      setPlaybooks(data?.history || []);
                      setDeletingPlaybook(false);
                      setShowConfirmPlaybookDeleteDialog(false);
                    }),
                  );
                }),
              );
            }}
            color="primary"
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Drawer
        open={Boolean(showAddCustomerDialog)}
        anchor="right"
        onClose={(e) => {
          handleTrackEvent(e, 'hide_request_bot_form');
          setShowAddCustomerDialog(null);
        }}
      >
        <Box
          p={1}
          sx={{
            borderBottom: '1px solid #d3d3d3',
          }}
          display={'flex'}
          alignItems={'center'}
        >
          <IconButton
            onClick={(e) => {
              handleTrackEvent(e, 'hide_request_bot_form');
              setShowAddCustomerDialog(false);
            }}
          >
            <ChevronRight sx={{}} />
          </IconButton>
          <Typography variant="body1" color={'text.secondary'}>
            Invite Client
          </Typography>
        </Box>

        <form onSubmit={formikInvite.handleSubmit}>
          <Box
            p={2}
            sx={{
              minWidth: '40vw',
              maxWidth: '40vw',
              minHeight: 'calc(100vh - 140px)',
              maxHeight: 'calc(100vh - 140px)',
              overflowY: 'scroll',
              '&::-webkit-scrollbar': {
                width: '5px',
              },
              '&::-webkit-scrollbar-track': {
                boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: theme.palette.primary.main,
                borderRadius: 2,
                // outline: '1px solid slategrey',
              },
              flex: 1,
              gap: 1,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Grid container spacing={1}>
              <Grid container item spacing={1}>
                <Grid item xs={12}>
                  <FormControlLabel
                    checked={formikInvite.values.demo}
                    control={
                      <Checkbox size="small" value={true} name="methods" />
                    }
                    label={
                      <Box display={'flex'} alignItems={'center'} gap={1}>
                        <Typography variant="body2" color={'text.secondary'}>
                          Demo Account
                        </Typography>
                        <Typography variant="caption" color={'text.secondary'}>
                          (To give demo to your clients.)
                        </Typography>
                      </Box>
                    }
                    onChange={(e) => {
                      formikInvite.setFieldValue('demo', e.target.checked);
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container item spacing={1}>
                <Grid item xs={12}>
                  <TextField
                    label="Email*"
                    variant="outlined"
                    name={'email'}
                    size="small"
                    fullWidth
                    value={formikInvite.values.email}
                    onChange={formikInvite.handleChange}
                    error={
                      formikInvite.touched.email &&
                      Boolean(formikInvite.errors.email)
                    }
                    helperText={
                      formikInvite.touched.email && formikInvite.errors.email
                    }
                  />
                </Grid>
              </Grid>
              <Grid container item spacing={1}>
                <Grid item xs={12}>
                  <TextField
                    label="Company Name*"
                    variant="outlined"
                    name={'companyName'}
                    size="small"
                    fullWidth
                    value={formikInvite.values.companyName}
                    onChange={formikInvite.handleChange}
                    error={
                      formikInvite.touched.companyName &&
                      Boolean(formikInvite.errors.companyName)
                    }
                    helperText={
                      formikInvite.touched.companyName &&
                      formikInvite.errors.companyName
                    }
                  />
                </Grid>
              </Grid>
              <Grid container item spacing={1}>
                <Grid item xs={12}>
                  <FormControl fullWidth size="small">
                    <Select
                      size="small"
                      labelId="plan-select-label"
                      id="plan-select"
                      name="selectedPlan"
                      MenuProps={{
                        disableScrollLock: true,
                      }}
                      sx={{
                        '& .MuiSelect-select': {
                          // padding: '4px 8px',
                        },
                      }}
                      onChange={(e) => {
                        console.log(e.target.value);
                        formikInvite.setFieldValue('planId', e.target.value);
                      }}
                      value={formikInvite.values.planId}
                    >
                      <MenuItem value={'-'}>
                        <Typography variant="body2">Select Plan</Typography>
                      </MenuItem>
                      {plans?.map((plan) => (
                        <MenuItem key={plan?.id} value={plan?.id}>
                          <Typography variant="body2">{plan?.title}</Typography>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container item spacing={1}>
                <Grid item xs={12}>
                  <FormControl
                    component="fieldset"
                    variant="standard"
                    size="small"
                  >
                    <FormGroup row>
                      <FormControlLabel
                        control={
                          <Radio
                            size="small"
                            checked={planType === 'regular'}
                            value={'regular'}
                            name="planType"
                          />
                        }
                        label={
                          <Typography variant="subtitle2">Regular</Typography>
                        }
                        onChange={() => setPlanType('regular')}
                      />
                      <FormControlLabel
                        control={
                          <Radio
                            size="small"
                            checked={planType === 'trial'}
                            value={'trial'}
                            name="planType"
                          />
                        }
                        label={
                          <Typography variant="subtitle2">
                            Trial Plan(14 Days)
                          </Typography>
                        }
                        onChange={() => setPlanType('trial')}
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container item spacing={1}>
                <Grid item xs={12}>
                  <TextField
                    label="Plan Price"
                    variant="outlined"
                    name={'clientPlanPrice'}
                    size="small"
                    fullWidth
                    value={clientPlanPrice}
                    onChange={(e) => setClientPlanPrice(e.target.value)}
                  />
                </Grid>
              </Grid>
              <Grid container item spacing={1}>
                {addOns && addOns?.length > 0 ? (
                  <>
                    <Grid item xs={12}>
                      <Typography
                        variant={'subtitle2'}
                        color={'text.secondary'}
                        fontWeight={'bold'}
                      >
                        <span>Select Addons</span>
                      </Typography>
                    </Grid>
                    {addOns?.map((addon) => (
                      <Grid key={addon?.feature?.feature} item xs={12}>
                        <Box display={'flex'} alignItems={'center'} gap={2}>
                          <FormControlLabel
                            checked={selectedAddOns?.includes(
                              addon?.feature?.feature,
                            )}
                            control={
                              <Checkbox
                                size="small"
                                value={addon?.feature?.feature}
                                name="methods"
                              />
                            }
                            label={capitalizeText(
                              addon.feature?.feature?.split('_')?.join(' '),
                            )}
                            onChange={(e) => {
                              if (
                                selectedAddOns?.includes(
                                  addon?.feature?.feature,
                                )
                              ) {
                                setSelectedAddOns((prev) =>
                                  prev.filter(
                                    (adn) => adn !== addon?.feature?.feature,
                                  ),
                                );
                              } else {
                                setSelectedAddOns((prev) => [
                                  ...prev,
                                  e.target.value,
                                ]);
                              }
                            }}
                          />
                          <TextField
                            label="Addon Price"
                            variant="outlined"
                            name={'addOnsPrice'}
                            size="small"
                            // fullWidth
                            value={addOnsPrice?.[addon?.feature?.feature] || 0}
                            onChange={(e) =>
                              setAddOnsPrice((prev) => {
                                return {
                                  ...prev,
                                  [addon?.feature?.feature]: e.target.value,
                                };
                              })
                            }
                          />
                        </Box>
                      </Grid>
                    ))}
                  </>
                ) : null}
              </Grid>
              <Grid container item spacing={1}>
                <Grid item xs={12}>
                  <Typography
                    variant={'subtitle2'}
                    // sx={{ marginBottom: 1 }}
                    color={'text.secondary'}
                    fontWeight={'bold'}
                  >
                    <span>Sales methodologies that they follow</span>
                  </Typography>
                  <FormControl component="fieldset" variant="standard">
                    <FormGroup row>
                      {orgConfigs?.methodologies?.map((mthd) => (
                        <FormControlLabel
                          key={mthd}
                          checked={methodologies?.includes(mthd)}
                          control={
                            <Checkbox
                              size="small"
                              value={mthd}
                              name="methods"
                            />
                          }
                          label={
                            mthd === 'meddpic' ? 'MEDDICC' : mthd?.toUpperCase()
                          }
                          onChange={(e) => {
                            if (methodologies?.includes(mthd)) {
                              setMethodologies((prev) =>
                                prev.filter((mthd) => mthd !== e.target.value),
                              );
                            } else {
                              setMethodologies((prev) => [
                                ...prev,
                                e.target.value,
                              ]);
                            }
                          }}
                        />
                      ))}
                      {orgConfigs?.customMethodologies?.map((mthd) => (
                        <FormControlLabel
                          key={mthd}
                          checked={methodologies?.includes(mthd)}
                          control={
                            <Checkbox
                              size="small"
                              value={mthd}
                              name="methods"
                            />
                          }
                          label={mthd?.toUpperCase()}
                          onChange={(e) => {
                            if (methodologies?.includes(mthd)) {
                              setMethodologies((prev) =>
                                prev.filter((mthd) => mthd !== e.target.value),
                              );
                            } else {
                              setMethodologies((prev) => [
                                ...prev,
                                e.target.value,
                              ]);
                            }
                          }}
                        />
                      ))}
                    </FormGroup>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container item spacing={1}>
                <Grid item xs={12}>
                  <Box
                    display={'flex'}
                    flexDirection={'column'}
                    // gap={1}
                    sx={{
                      border: '1px solid #f1f1f1',
                      borderRadius: 2,
                    }}
                  >
                    <Box
                      sx={{
                        width: 1,
                        background: '#f1f1f1',
                        borderRadius: 2,
                        padding: '8px 16px',
                      }}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            size="small"
                            checked={formikInvite.values.withProspects}
                            name="withProspects"
                          />
                        }
                        label={
                          <Typography variant="subtitle2">
                            With Prospects
                          </Typography>
                        }
                        onChange={formikInvite.handleChange}
                      />
                    </Box>
                    {formikInvite.values.withProspects ? (
                      <Box sx={{ padding: 2 }}>
                        <Box>
                          <Typography
                            variant="subtitle2"
                            fontWeight={'bold'}
                            color={'text.secondary'}
                          >
                            Prospect Verticals
                          </Typography>
                          <Typography
                            variant="caption"
                            color={'text.secondary'}
                          >
                            If you sell to specific verticals, select them from
                            the list. You can also add your own.
                          </Typography>
                        </Box>
                        <AutoCompleteMUI
                          multiple
                          size="small"
                          value={
                            formikInvite.values.prospectDetails?.vertical || []
                          }
                          onKeyDown={(event) => {
                            if (event.target.value.trim()) {
                              formikInvite.setFieldValue('prospectDetails', {
                                ...(formikInvite.values.prospectDetails || {}),
                                vertical: formikInvite.values.prospectDetails
                                  ?.vertical
                                  ? [
                                      ...(formikInvite.values.prospectDetails
                                        ?.vertical || []),
                                      event.target.value,
                                    ]
                                  : [event.target.value],
                              });
                            }
                          }}
                          onChange={(event, newValue) => {
                            formikInvite.setFieldValue('prospectDetails', {
                              ...(formikInvite.values.prospectDetails || {}),
                              vertical: [...newValue],
                            });
                            // console.log(newValue);
                          }}
                          id="vertical"
                          options={verticalOptions}
                        />
                        {/* <SkillTagInput
                          preSkills={
                            formikInvite.values.prospectDetails?.vertical &&
                            formikInvite.values.prospectDetails?.vertical
                              ?.length
                              ? formikInvite.values.prospectDetails?.vertical.map(
                                  (kw) => {
                                    return {
                                      label: kw,
                                      value: kw,
                                    };
                                  },
                                )
                              : []
                          }
                          skillsTag={(value) => {
                            const keywords = value.map((skils) => skils.value);
                            formikInvite.setFieldValue('prospectDetails', {
                              ...(formikInvite.values.prospectDetails || {}),
                              vertical: keywords,
                            });
                          }}
                          options={verticalOptions.map((op) => {
                            return { label: op, value: op };
                          })}
                          // placeholder={`Your Target's Vertical (The vertical your prospect belongs to)`}
                        /> */}
                        <Box>
                          <Typography
                            fontWeight={'bold'}
                            variant="subtitle2"
                            color={'text.secondary'}
                          >
                            Prospect Designations
                          </Typography>
                          <Typography
                            variant="caption"
                            color={'text.secondary'}
                          >
                            If you sell to specific designation, select them
                            from the list. You can also add your own.
                          </Typography>
                        </Box>
                        <AutoCompleteMUI
                          multiple
                          value={
                            formikInvite.values.prospectDetails?.designation ||
                            []
                          }
                          onKeyDown={(event) => {
                            if (event.target.value.trim()) {
                              formikInvite.setFieldValue('prospectDetails', {
                                ...(formikInvite.values.prospectDetails || {}),
                                designation: formikInvite.values.prospectDetails
                                  ?.designation
                                  ? [
                                      ...(formikInvite.values.prospectDetails
                                        ?.designation || []),
                                      event.target.value,
                                    ]
                                  : [event.target.value],
                              });
                            }
                          }}
                          onChange={(event, newValue) => {
                            if (newValue.includes('Other')) {
                              formikInvite.setFieldValue('prospectDetails', {
                                ...(formikInvite.values.prospectDetails || {}),
                                designation: [],
                              });
                            } else {
                              formikInvite.setFieldValue('prospectDetails', {
                                ...(formikInvite.values.prospectDetails || {}),
                                designation: [...newValue],
                              });
                            }
                            console.log(newValue);
                          }}
                          id="designation"
                          options={designationOptions}
                        />
                      </Box>
                    ) : null}
                  </Box>
                </Grid>
              </Grid>
              <Grid container item spacing={2}>
                <Grid item xs={12}>
                  <Box
                    display={'flex'}
                    flexDirection={'column'}
                    // gap={1}
                    sx={{
                      border: '1px solid #f1f1f1',
                      borderRadius: 2,
                    }}
                  >
                    <Box
                      sx={{
                        width: 1,
                        background: '#f1f1f1',
                        padding: '8px 16px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            size="small"
                            checked={formikInvite.values.withPlaybook}
                            name="withPlaybook"
                          />
                        }
                        label={
                          <Typography variant="subtitle2">
                            With Playbook
                          </Typography>
                        }
                        onChange={formikInvite.handleChange}
                      />
                      {formikInvite.values.withPlaybook ? (
                        <Button
                          variant="contained"
                          size="small"
                          onClick={() => {
                            if (
                              userDetails?.plan?.trailEndsOn >
                                new Date().getTime() ||
                              userDetails?.plan?.currentPaymentStatus === 'paid'
                            ) {
                              setShowGeneratePlaybookWithAI('invite');
                            } else {
                              dispatch({
                                type: 'UPDATE_UI_STATE',
                                payload: {
                                  key: 'showPlanExpired',
                                  value: true,
                                },
                              });
                            }
                          }}
                        >
                          Generate with AI
                        </Button>
                      ) : null}
                    </Box>
                    {formikInvite.values.withPlaybook ? (
                      <Box
                        sx={{
                          padding: 2,
                          display: 'flex',
                          flexDirection: 'column',
                          gap: 1,
                        }}
                      >
                        <FormControl fullWidth size="small">
                          <Select
                            size="small"
                            labelId="template-select-label"
                            id="template-select"
                            name="selectedTemplate"
                            MenuProps={{
                              disableScrollLock: true,
                            }}
                            sx={{
                              '& .MuiSelect-select': {
                                // padding: '4px 8px',
                              },
                            }}
                            onChange={(e) => {
                              console.log(e.target.value);
                              setSelectedTemplate(e.target.value);
                            }}
                            value={selectedTemplate}
                          >
                            <MenuItem value={'-'}>
                              <Typography variant="body2">
                                Select Template
                              </Typography>
                            </MenuItem>
                            {playbookTemplates?.map((template) => (
                              <MenuItem key={template?.id} value={template?.id}>
                                <Typography variant="body2">
                                  {template?.title}
                                </Typography>
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <FormControl fullWidth size="small">
                          <Select
                            size="small"
                            labelId="playbook-select-label"
                            id="playbook-select"
                            name="selectedPlaybook"
                            MenuProps={{
                              disableScrollLock: true,
                            }}
                            sx={{
                              '& .MuiSelect-select': {
                                // padding: '4px 8px',
                              },
                            }}
                            onChange={(e) => {
                              console.log(e.target.value);
                              setSelectedPlaybook(e.target.value);
                            }}
                            value={selectedPlaybook}
                          >
                            <MenuItem value={'-'}>
                              <Typography variant="body2">
                                Select Playbook
                              </Typography>
                            </MenuItem>
                            {playbooks?.map((playbook) => (
                              <MenuItem key={playbook?.id} value={playbook?.id}>
                                <Typography variant="body2">
                                  {playbook?.tags?.[0]}
                                </Typography>
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {selectedPlaybook &&
                        selectedPlaybook !== '-' &&
                        selectedTemplate &&
                        selectedTemplate !== '-' ? (
                          <>
                            <Box>
                              <Typography
                                variant="subtitle2"
                                color={'text.secondary'}
                                fontWeight={'bold'}
                              >
                                Playbook Title*
                              </Typography>
                            </Box>
                            <AutoCompleteMUI
                              id="inviteTags"
                              value={inviteTags}
                              onChange={(event, newValue, reason) => {
                                setInviteTags([...(newValue || [])]);
                              }}
                              options={[]}
                              onTextChange={(e) => {
                                if (e.target.value?.trim()) {
                                  setInviteTags([e.target.value]);
                                } else {
                                  setInviteTags([]);
                                }
                              }}
                              onKeyDown={() => {}}
                            />
                            {Object.keys(defaultPlaybookFields || {})?.map(
                              (field, index) => (
                                <Grid item xs={12} key={index}>
                                  <Box
                                    p={1}
                                    borderRadius={1}
                                    display={'flex'}
                                    flexDirection={'column'}
                                    gap={1}
                                  >
                                    <Box
                                      display={'flex'}
                                      alignItems={'center'}
                                      gap={1}
                                      justifyContent={'space-between'}
                                    >
                                      <Typography
                                        variant="subtitle2"
                                        color={'text.secondary'}
                                        fontWeight={'bold'}
                                      >
                                        {
                                          defaultPlaybookFieldsMapping?.[field]
                                            ?.text
                                        }
                                      </Typography>
                                    </Box>
                                    {field === 'challengesForCustomer' ? (
                                      <>
                                        {defaultPlaybookFields.challengesForCustomer &&
                                          Array.isArray(
                                            defaultPlaybookFields.challengesForCustomer,
                                          ) &&
                                          defaultPlaybookFields
                                            .challengesForCustomer.length > 0 &&
                                          defaultPlaybookFields.challengesForCustomer?.map(
                                            (objAndAns, faqIndex) => {
                                              return (
                                                <Box
                                                  key={faqIndex}
                                                  sx={{
                                                    border: '1px solid #d3d3d3',
                                                    borderRadius: 2,
                                                    padding: 2,
                                                    marginTop: 1,
                                                    position: 'relative',
                                                  }}
                                                >
                                                  <Button
                                                    variant="text"
                                                    sx={{
                                                      position: 'absolute',
                                                      top: 0,
                                                      right: 10,
                                                      zIndex: 2,
                                                      background: '#fff',
                                                      '&:hover': {
                                                        background: '#fff',
                                                      },
                                                    }}
                                                    onClick={() => {
                                                      const updatedObjAndAns = defaultPlaybookFields.challengesForCustomer.filter(
                                                        (objAndAns, i) =>
                                                          i !== faqIndex,
                                                      );
                                                      setDefaultPlaybookFields(
                                                        (prev) => {
                                                          return {
                                                            ...prev,
                                                            challengesForCustomer: updatedObjAndAns,
                                                          };
                                                        },
                                                      );
                                                    }}
                                                  >
                                                    Remove
                                                  </Button>
                                                  <TextField
                                                    label={`Challenge ${faqIndex +
                                                      1}`}
                                                    size="small"
                                                    variant="outlined"
                                                    fullWidth
                                                    value={objAndAns?.question}
                                                    sx={{ marginTop: 1 }}
                                                    onChange={(e) => {
                                                      const updatedObjAndAns = defaultPlaybookFields.challengesForCustomer.map(
                                                        (objAndAns, i) => {
                                                          if (i === faqIndex) {
                                                            return {
                                                              ...objAndAns,
                                                              question:
                                                                e.target.value,
                                                            };
                                                          } else {
                                                            return objAndAns;
                                                          }
                                                        },
                                                      );
                                                      setDefaultPlaybookFields(
                                                        (prev) => {
                                                          return {
                                                            ...prev,
                                                            challengesForCustomer: updatedObjAndAns,
                                                          };
                                                        },
                                                      );
                                                    }}
                                                  />
                                                  <TextField
                                                    size="small"
                                                    label={`Recommended Response`}
                                                    variant="outlined"
                                                    fullWidth
                                                    value={objAndAns?.answer}
                                                    onChange={(e) => {
                                                      const updatedObjAndAns = defaultPlaybookFields.challengesForCustomer.map(
                                                        (objAndAns, i) => {
                                                          if (i === faqIndex) {
                                                            return {
                                                              ...objAndAns,
                                                              answer:
                                                                e.target.value,
                                                            };
                                                          } else {
                                                            return objAndAns;
                                                          }
                                                        },
                                                      );
                                                      setDefaultPlaybookFields(
                                                        (prev) => {
                                                          return {
                                                            ...prev,
                                                            challengesForCustomer: updatedObjAndAns,
                                                          };
                                                        },
                                                      );
                                                    }}
                                                    sx={{ marginTop: 1 }}
                                                    // error={formik.touched.email && Boolean(formik.errors.email)}
                                                    // helperText={formik.touched.email && formik.errors.email}
                                                  />
                                                </Box>
                                              );
                                            },
                                          )}
                                        <Button
                                          onClick={() => {
                                            setDefaultPlaybookFields((prev) => {
                                              return {
                                                ...prev,
                                                challengesForCustomer: [
                                                  ...prev.challengesForCustomer,
                                                  { question: '', answer: '' },
                                                ],
                                              };
                                            });
                                          }}
                                          sx={{ marginTop: 1 }}
                                          variant="contained"
                                        >
                                          Add Challenge and Recommended Response
                                        </Button>
                                      </>
                                    ) : field === 'customerQuestions' ? (
                                      <>
                                        {defaultPlaybookFields.customerQuestions &&
                                          Array.isArray(
                                            defaultPlaybookFields.customerQuestions,
                                          ) &&
                                          defaultPlaybookFields
                                            .customerQuestions.length > 0 &&
                                          defaultPlaybookFields.customerQuestions?.map(
                                            (objAndAns, faqIndex) => {
                                              return (
                                                <Box
                                                  key={faqIndex}
                                                  sx={{
                                                    border: '1px solid #d3d3d3',
                                                    borderRadius: 2,
                                                    padding: 2,
                                                    marginTop: 1,
                                                    position: 'relative',
                                                  }}
                                                >
                                                  <Button
                                                    variant="text"
                                                    sx={{
                                                      position: 'absolute',
                                                      top: 0,
                                                      right: 10,
                                                      zIndex: 2,
                                                      background: '#fff',
                                                      '&:hover': {
                                                        background: '#fff',
                                                      },
                                                    }}
                                                    onClick={() => {
                                                      const updatedObjAndAns = defaultPlaybookFields.customerQuestions.filter(
                                                        (objAndAns, i) =>
                                                          i !== faqIndex,
                                                      );
                                                      setDefaultPlaybookFields(
                                                        (prev) => {
                                                          return {
                                                            ...prev,
                                                            customerQuestions: updatedObjAndAns,
                                                          };
                                                        },
                                                      );
                                                    }}
                                                  >
                                                    Remove
                                                  </Button>
                                                  <TextField
                                                    label={`Objection ${faqIndex +
                                                      1}`}
                                                    size="small"
                                                    variant="outlined"
                                                    fullWidth
                                                    value={objAndAns?.question}
                                                    sx={{ marginTop: 1 }}
                                                    onChange={(e) => {
                                                      const updatedObjAndAns = defaultPlaybookFields.customerQuestions.map(
                                                        (objAndAns, i) => {
                                                          if (i === faqIndex) {
                                                            return {
                                                              ...objAndAns,
                                                              question:
                                                                e.target.value,
                                                            };
                                                          } else {
                                                            return objAndAns;
                                                          }
                                                        },
                                                      );
                                                      setDefaultPlaybookFields(
                                                        (prev) => {
                                                          return {
                                                            ...prev,
                                                            customerQuestions: updatedObjAndAns,
                                                          };
                                                        },
                                                      );
                                                    }}
                                                  />
                                                  <TextField
                                                    size="small"
                                                    label={`Answer`}
                                                    variant="outlined"
                                                    fullWidth
                                                    value={objAndAns?.answer}
                                                    onChange={(e) => {
                                                      const updatedObjAndAns = defaultPlaybookFields.customerQuestions.map(
                                                        (objAndAns, i) => {
                                                          if (i === faqIndex) {
                                                            return {
                                                              ...objAndAns,
                                                              answer:
                                                                e.target.value,
                                                            };
                                                          } else {
                                                            return objAndAns;
                                                          }
                                                        },
                                                      );
                                                      setDefaultPlaybookFields(
                                                        (prev) => {
                                                          return {
                                                            ...prev,
                                                            customerQuestions: updatedObjAndAns,
                                                          };
                                                        },
                                                      );
                                                    }}
                                                    sx={{ marginTop: 1 }}
                                                    // error={formik.touched.email && Boolean(formik.errors.email)}
                                                    // helperText={formik.touched.email && formik.errors.email}
                                                  />
                                                </Box>
                                              );
                                            },
                                          )}
                                        <Button
                                          onClick={() => {
                                            setDefaultPlaybookFields((prev) => {
                                              return {
                                                ...prev,
                                                customerQuestions: [
                                                  ...prev.customerQuestions,
                                                  { question: '', answer: '' },
                                                ],
                                              };
                                            });
                                          }}
                                          sx={{ marginTop: 1 }}
                                          variant="contained"
                                        >
                                          Add Objection and Answer
                                        </Button>
                                      </>
                                    ) : (
                                      <TextField
                                        placeholder={
                                          defaultPlaybookFieldsMapping?.[field]
                                            ?.text
                                        }
                                        variant="outlined"
                                        name={field}
                                        fullWidth
                                        size="small"
                                        multiline
                                        rows={5}
                                        value={defaultPlaybookFields?.[field]}
                                        onChange={(e) => {
                                          setDefaultPlaybookFields((prev) => {
                                            return {
                                              ...prev,
                                              [field]: e.target.value,
                                            };
                                          });
                                        }}
                                        type={'text'}
                                      />
                                    )}
                                  </Box>
                                </Grid>
                              ),
                            )}
                            {customPlaybookFields?.map((field, index) => (
                              <Grid item xs={12} key={index}>
                                <Box
                                  p={1}
                                  borderRadius={1}
                                  display={'flex'}
                                  flexDirection={'column'}
                                  gap={1}
                                >
                                  <Box
                                    display={'flex'}
                                    alignItems={'center'}
                                    gap={1}
                                    justifyContent={'space-between'}
                                  >
                                    <Typography
                                      variant="subtitle2"
                                      color={'text.secondary'}
                                      fontWeight={'bold'}
                                    >
                                      {field?.title}
                                    </Typography>
                                  </Box>
                                  <TextField
                                    placeholder={field?.title}
                                    variant="outlined"
                                    name={field}
                                    fullWidth
                                    size="small"
                                    multiline
                                    rows={5}
                                    value={field?.content}
                                    onChange={(e) => {
                                      setCustomPlaybookFields((prev) => {
                                        return prev.map((item) => {
                                          if (item?.title === field?.title) {
                                            return {
                                              ...item,
                                              content: e.target.value,
                                            };
                                          } else {
                                            return item;
                                          }
                                        });
                                      });
                                    }}
                                    type={'text'}
                                  />
                                </Box>
                              </Grid>
                            ))}
                          </>
                        ) : null}
                      </Box>
                    ) : null}
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid container item spacing={2}>
              <Grid item xs={12}>
                <Box
                  display={'flex'}
                  flexDirection={'column'}
                  // gap={1}
                  sx={{
                    border: '1px solid #f1f1f1',
                    borderRadius: 2,
                  }}
                >
                  <Box
                    sx={{
                      width: 1,
                      background: '#f1f1f1',
                      padding: '8px 16px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          size="small"
                          checked={withContentScoringConfig}
                          name="withContentScoringConfig"
                        />
                      }
                      label={
                        <Typography variant="subtitle2">
                          Call Scoring Configurations
                        </Typography>
                      }
                      onChange={(e) => {
                        if (e.target.checked) {
                          setShowEditContentScoringConfig(true);
                        } else {
                          setShowEditContentScoringConfig(false);
                        }
                        setWithContentScoringConfig(e.target.checked);
                      }}
                    />
                    {withContentScoringConfig ? (
                      <Button
                        startIcon={<Settings />}
                        size="small"
                        onClick={() => setShowEditContentScoringConfig(true)}
                      >
                        Edit Configurations
                      </Button>
                    ) : null}
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box
            p={1}
            sx={{
              borderTop: '1px solid #d3d3d3',
              height: 80,
            }}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            <Box display={'flex'} flexDirection={'column'}>
              {formikInvite.errors && formikInvite.errors.email && (
                <Typography
                  variant="caption"
                  sx={{
                    color: theme.palette.error.dark,
                  }}
                >
                  Email is required *
                </Typography>
              )}
              {formikInvite.errors && formikInvite.errors.companyName && (
                <Typography
                  variant="caption"
                  sx={{
                    color: theme.palette.error.dark,
                  }}
                >
                  Company Name is required *
                </Typography>
              )}
              {formikInvite.errors && formikInvite.errors.planId && (
                <Typography
                  variant="caption"
                  sx={{
                    color: theme.palette.error.dark,
                  }}
                >
                  Please select a plan *
                </Typography>
              )}
              {formikInvite.values.withPlaybook &&
              !(inviteTags && inviteTags?.length > 0) ? (
                <Typography
                  variant="caption"
                  sx={{
                    color: theme.palette.error.dark,
                  }}
                >
                  Playbook title is required *
                </Typography>
              ) : null}
            </Box>
            <Button
              type="submit"
              size="small"
              disabled={
                invitingCustomer ||
                !formikInvite.values.email.trim() ||
                !formikInvite.values.companyName.trim() ||
                (formikInvite.values.withPlaybook &&
                  inviteTags &&
                  inviteTags?.length === 0)
              }
              variant="contained"
              color="primary"
              startIcon={
                invitingCustomer ? <CircularProgress size={20} /> : null
              }
            >
              Invite Client
            </Button>
          </Box>
        </form>
      </Drawer>
      <Drawer
        open={Boolean(showEditTemplateDialog)}
        anchor="right"
        onClose={(e) => {
          handleTrackEvent(e, 'hide_edit_template_dialog');
          setShowEditTemplateDialog(null);
        }}
      >
        <Box
          p={1}
          sx={{
            borderBottom: '1px solid #d3d3d3',
          }}
          display={'flex'}
          alignItems={'center'}
        >
          <IconButton
            onClick={(e) => {
              handleTrackEvent(e, 'hide_edit_template_dialog');
              setShowEditTemplateDialog(false);
            }}
          >
            <ChevronRight sx={{}} />
          </IconButton>
          <Typography variant="body1" color={'text.secondary'}>
            {showEditTemplateDialog === 'new'
              ? 'Create Template'
              : 'Edit Template'}
          </Typography>
        </Box>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleCreateOrUpdateTemplate(
              showEditTemplateDialog === 'new' ? 'create' : 'update',
            );
          }}
        >
          <Box
            p={2}
            sx={{
              minWidth: '40vw',
              maxWidth: '40vw',
              minHeight: 'calc(100vh - 120px)',
              maxHeight: 'calc(100vh - 120px)',
              overflowY: 'scroll',
              '&::-webkit-scrollbar': {
                width: '5px',
              },
              '&::-webkit-scrollbar-track': {
                boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: theme.palette.primary.main,
                borderRadius: 2,
                // outline: '1px solid slategrey',
              },
              flex: 1,
              gap: 1,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <TextField
              placeholder="Template Title"
              variant="outlined"
              name={'templateTitle'}
              fullWidth
              size="small"
              value={templateTitle}
              onChange={(e) => {
                setTemplateTitle(e.target.value);
              }}
              type="text"
            />
            {/* {showEditTemplateDialog === 'new' ? ( */}
            <Box display={'flex'} flexDirection={'column'} gap={1}>
              <Box
                display={'flex'}
                alignItems={'center'}
                gap={1}
                sx={{ cursor: 'pointer' }}
                onClick={() =>
                  setExpandedField((prev) =>
                    prev?.includes('default')
                      ? prev?.filter((ex) => ex !== 'default')
                      : [...prev, 'default'],
                  )
                }
              >
                {expandedField?.includes('default') ? (
                  <ArrowDropDown />
                ) : (
                  <ArrowRight />
                )}
                <Typography sx={{ color: 'text.secondary' }}>
                  Default Playbook Fields
                </Typography>
              </Box>
              {expandedField?.includes('default') ? (
                <Grid container spacing={1} pl={4}>
                  {Object.keys(defaultPlaybookFields || {})?.map(
                    (field, index) => (
                      <Grid item xs={12} key={index}>
                        <Box
                          display={'flex'}
                          border={'1px solid #d3d3d3'}
                          p={1}
                          borderRadius={1}
                          justifyContent={'space-between'}
                          alignContent={'start'}
                        >
                          <Box>
                            <Typography
                              variant="subtitle2"
                              color={'text.secondary'}
                              fontWeight={'bold'}
                            >
                              {defaultPlaybookFieldsMapping?.[field]?.text}
                            </Typography>
                            <Chip
                              size="small"
                              variant="outlined"
                              label={
                                defaultPlaybookFieldsMapping?.[field]?.type ===
                                'textarea'
                                  ? 'Text Field'
                                  : 'List of Questions And Answers'
                              }
                            />
                          </Box>
                          <Box display={'flex'} alignItems={'center'} gap={1}>
                            {/* <Tooltip arrow placement="top" title="Delete Field">
                            <IconButton
                              sx={{
                                borderRadius: '4px',
                                border: '1px solid #d3d3d3',
                                padding: '4px',
                                width: 26,
                                height: 26,
                              }}
                              onClick={() => {
                                setDefaultPlaybookFields((prev) =>
                                  Object.keys(prev || {})
                                    .filter((item) => item !== field)
                                    ?.reduce((acc, curr) => {
                                      acc[curr] = '';
                                      return acc;
                                    }, {}),
                                );
                              }}
                            >
                              <Delete sx={{ fontSize: 16 }} />
                            </IconButton>
                          </Tooltip> */}
                            <Tooltip arrow placement="top" title="Delete Field">
                              <IconButton
                                sx={{
                                  borderRadius: '4px',
                                  border: '1px solid #d3d3d3',
                                  padding: '4px',
                                  width: 26,
                                  height: 26,
                                }}
                                onClick={() => {
                                  setDefaultPlaybookFields((prev) =>
                                    Object.keys(prev || {})
                                      .filter((item) => item !== field)
                                      ?.reduce((acc, curr) => {
                                        acc[curr] = '';
                                        return acc;
                                      }, {}),
                                  );
                                }}
                              >
                                <Delete sx={{ fontSize: 16 }} />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        </Box>
                      </Grid>
                    ),
                  )}
                </Grid>
              ) : null}
              <Box
                display={'flex'}
                alignItems={'center'}
                gap={1}
                sx={{ cursor: 'pointer' }}
                onClick={() =>
                  setExpandedField((prev) =>
                    prev?.includes('custom')
                      ? prev?.filter((ex) => ex !== 'custom')
                      : [...prev, 'custom'],
                  )
                }
              >
                {expandedField?.includes('custom') ? (
                  <ArrowDropDown />
                ) : (
                  <ArrowRight />
                )}
                <Typography sx={{ color: 'text.secondary' }}>
                  Custom Playbook Fields
                </Typography>
              </Box>
              {expandedField?.includes('custom') ? (
                <Grid container spacing={1} pl={4}>
                  {customPlaybookFields?.map((field, index) => (
                    <Grid item xs={12} key={index}>
                      <Box
                        border={'1px solid #d3d3d3'}
                        p={1}
                        borderRadius={1}
                        flexDirection={'column'}
                        gap={1}
                      >
                        <Box
                          display={'flex'}
                          gap={1}
                          justifyContent={'space-between'}
                          alignContent={'start'}
                        >
                          <Box>
                            <Typography
                              variant="subtitle2"
                              color={'text.secondary'}
                              fontWeight={'bold'}
                            >
                              {field?.title}
                            </Typography>
                            <Chip
                              size="small"
                              variant="outlined"
                              label={'Text Field'}
                            />
                          </Box>
                          <Tooltip arrow placement="top" title="Delete Field">
                            <IconButton
                              sx={{
                                borderRadius: '4px',
                                border: '1px solid #d3d3d3',
                                padding: '4px',
                                width: 26,
                                height: 26,
                              }}
                              onClick={() => {
                                setCustomPlaybookFields((prev) =>
                                  prev.filter(
                                    (item) => item?.title !== field?.title,
                                  ),
                                );
                              }}
                            >
                              <Delete sx={{ fontSize: 16 }} />
                            </IconButton>
                          </Tooltip>
                        </Box>
                        {field?.context ? (
                          <Typography>{field?.context}</Typography>
                        ) : null}
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              ) : null}
              <Box
                display={'flex'}
                alignItems={'center'}
                gap={1}
                ml={4}
                // justifyContent={'flex-end'}
              >
                <Button
                  startIcon={<Add />}
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={() => setShowAddCustomFieldDialog(true)}
                >
                  Add new field
                </Button>
              </Box>
            </Box>
            {/* // ) : (
            //   <Grid item xs={12}></Grid>
            // )} */}
          </Box>
          <Box
            p={1}
            sx={{
              borderTop: '1px solid #d3d3d3',
              height: 60,
              // background: '#d3d3d3',
            }}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'flex-end'}
          >
            <Button
              type="submit"
              size="small"
              disabled={creatingOrUpdatingTemplate || !templateTitle?.trim()}
              variant="contained"
              color="primary"
              startIcon={
                creatingOrUpdatingTemplate ? (
                  <CircularProgress size={20} />
                ) : null
              }
            >
              {showEditTemplateDialog === 'new'
                ? 'Create Template'
                : 'Update Template'}
            </Button>
          </Box>
        </form>
      </Drawer>
      <Drawer
        open={Boolean(showTemplatePreview)}
        anchor="right"
        onClose={(e) => {
          handleTrackEvent(e, 'hide_template_preview');
          setShowTemplatePreview(null);
        }}
      >
        <Box
          p={1}
          sx={{
            borderBottom: '1px solid #d3d3d3',
          }}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <Box display={'flex'} alignItems={'center'} gap={1}>
            <IconButton
              onClick={(e) => {
                handleTrackEvent(e, 'hide_template_preview');
                setShowTemplatePreview(false);
              }}
            >
              <ChevronRight sx={{}} />
            </IconButton>
            <Typography variant="body1" color={'text.secondary'}>
              Template Preview ({templateTitle})
            </Typography>
          </Box>
          <Tooltip arrow placement="top" title="Edit Template">
            <IconButton
              sx={{
                borderRadius: '4px',
                border: '1px solid #d3d3d3',
                padding: '4px',
                width: 26,
                height: 26,
              }}
              onClick={() => {
                setShowEditTemplateDialog(showTemplatePreview);
                setShowTemplatePreview(null);
              }}
            >
              <Edit sx={{ fontSize: 16 }} />
            </IconButton>
          </Tooltip>
        </Box>
        <Box
          p={2}
          sx={{
            minWidth: '40vw',
            maxWidth: '40vw',
            minHeight: 'calc(100vh - 120px)',
            maxHeight: 'calc(100vh - 120px)',
            overflowY: 'scroll',
            '&::-webkit-scrollbar': {
              width: '5px',
            },
            '&::-webkit-scrollbar-track': {
              boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
              webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: theme.palette.primary.main,
              borderRadius: 2,
              // outline: '1px solid slategrey',
            },
            flex: 1,
            gap: 1,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box display={'flex'} flexDirection={'column'} gap={1}>
            <Box
              display={'flex'}
              alignItems={'center'}
              gap={1}
              sx={{ cursor: 'pointer' }}
              onClick={() =>
                setExpandedField((prev) =>
                  prev?.includes('default')
                    ? prev?.filter((ex) => ex !== 'default')
                    : [...prev, 'default'],
                )
              }
            >
              {expandedField?.includes('default') ? (
                <ArrowDropDown />
              ) : (
                <ArrowRight />
              )}
              <Typography sx={{ color: 'text.secondary' }}>
                Default Playbook Fields
              </Typography>
            </Box>
            {expandedField?.includes('default') ? (
              <Grid container spacing={1} pl={4}>
                {Object.keys(defaultPlaybookFields || {})?.map(
                  (field, index) => (
                    <Grid item xs={12} key={index}>
                      <Box
                        display={'flex'}
                        border={'1px solid #d3d3d3'}
                        p={1}
                        borderRadius={1}
                        justifyContent={'space-between'}
                        alignContent={'start'}
                      >
                        <Box>
                          <Typography
                            variant="subtitle2"
                            color={'text.secondary'}
                            fontWeight={'bold'}
                          >
                            {defaultPlaybookFieldsMapping?.[field]?.text}
                          </Typography>
                          <Chip
                            size="small"
                            variant="outlined"
                            label={
                              defaultPlaybookFieldsMapping?.[field]?.type ===
                              'textarea'
                                ? 'Text Field'
                                : 'List of Questions And Answers'
                            }
                          />
                        </Box>
                      </Box>
                    </Grid>
                  ),
                )}
              </Grid>
            ) : null}
            <Box
              display={'flex'}
              alignItems={'center'}
              gap={1}
              sx={{ cursor: 'pointer' }}
              onClick={() =>
                setExpandedField((prev) =>
                  prev?.includes('custom')
                    ? prev?.filter((ex) => ex !== 'custom')
                    : [...prev, 'custom'],
                )
              }
            >
              {expandedField?.includes('custom') ? (
                <ArrowDropDown />
              ) : (
                <ArrowRight />
              )}
              <Typography sx={{ color: 'text.secondary' }}>
                Custom Playbook Fields
              </Typography>
            </Box>
            {expandedField?.includes('custom') ? (
              <Grid container spacing={1} pl={4}>
                {customPlaybookFields?.map((field, index) => (
                  <Grid item xs={12} key={index}>
                    <Box
                      border={'1px solid #d3d3d3'}
                      p={1}
                      borderRadius={1}
                      flexDirection={'column'}
                      gap={1}
                    >
                      <Box
                        display={'flex'}
                        gap={1}
                        justifyContent={'space-between'}
                        alignContent={'start'}
                      >
                        <Box>
                          <Typography
                            variant="subtitle2"
                            color={'text.secondary'}
                            fontWeight={'bold'}
                          >
                            {field?.title}
                          </Typography>
                          <Chip
                            size="small"
                            variant="outlined"
                            label={'Text Field'}
                          />
                        </Box>
                        {/* <Tooltip arrow placement="top" title="Delete Field">
                            <IconButton
                              sx={{
                                borderRadius: '4px',
                                border: '1px solid #d3d3d3',
                                padding: '4px',
                                width: 26,
                                height: 26,
                              }}
                              onClick={() => {
                                setCustomPlaybookFields((prev) =>
                                  prev.filter(
                                    (item) => item?.title !== field?.title,
                                  ),
                                );
                              }}
                            >
                              <Delete sx={{ fontSize: 16 }} />
                            </IconButton>
                          </Tooltip> */}
                      </Box>
                      {field?.context ? (
                        <Typography>{field?.context}</Typography>
                      ) : null}
                    </Box>
                  </Grid>
                ))}
              </Grid>
            ) : null}
            {/* <Box
                display={'flex'}
                alignItems={'center'}
                gap={1}
                ml={4}
                // justifyContent={'flex-end'}
              >
                <Button
                  startIcon={<Add />}
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={() => setShowAddCustomFieldDialog(true)}
                >
                  Add new field
                </Button>
              </Box> */}
          </Box>
        </Box>
      </Drawer>
      <Drawer
        open={Boolean(showEditPlaybookDialog)}
        anchor="right"
        onClose={(e) => {
          handleTrackEvent(e, 'hide_edit_playboook_dialog');
          setShowEditPlaybookDialog(null);
        }}
      >
        <Box
          p={1}
          sx={{
            borderBottom: '1px solid #d3d3d3',
          }}
          display={'flex'}
          alignItems={'center'}
        >
          <IconButton
            onClick={(e) => {
              handleTrackEvent(e, 'hide_edit_playboook_dialog');
              setShowEditPlaybookDialog(false);
            }}
          >
            <ChevronRight sx={{}} />
          </IconButton>
          <Typography variant="body1" color={'text.secondary'}>
            {showEditPlaybookDialog === 'new'
              ? 'Create Playbook'
              : 'Edit Playbook'}
          </Typography>
        </Box>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleCreateOrUpdateplaybook(
              showEditPlaybookDialog === 'new' ? 'create' : 'update',
            );
          }}
        >
          <Box
            p={2}
            sx={{
              minWidth: '40vw',
              maxWidth: '40vw',
              minHeight: 'calc(100vh - 120px)',
              maxHeight: 'calc(100vh - 120px)',
              overflowY: 'scroll',
              '&::-webkit-scrollbar': {
                width: '5px',
              },
              '&::-webkit-scrollbar-track': {
                boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: theme.palette.primary.main,
                borderRadius: 2,
                // outline: '1px solid slategrey',
              },
              flex: 1,
              gap: 1,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Box display={'flex'} flexDirection={'column'} gap={1}>
              <Typography
                variant="subtitle2"
                color={'text.secondary'}
                fontWeight={'bold'}
              >
                Playbook Title*
              </Typography>
              <AutoCompleteMUI
                id="tags"
                value={tags}
                onChange={(event, newValue, reason) => {
                  setTags([...(newValue || [])]);
                }}
                options={[]}
                onTextChange={(e) => {
                  if (e.target.value?.trim()) {
                    setTags([e.target.value]);
                  } else {
                    setTags([]);
                  }
                }}
                onKeyDown={() => {}}
              />
              <FormControl fullWidth size="small">
                <Select
                  size="small"
                  labelId="template-select-label"
                  id="template-select"
                  name="selectedTemplate"
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                  sx={{
                    '& .MuiSelect-select': {
                      // padding: '4px 8px',
                    },
                  }}
                  onChange={(e) => {
                    console.log(e.target.value);
                    setSelectedTemplate(e.target.value);
                  }}
                  value={selectedTemplate}
                >
                  <MenuItem value={'-'}>
                    <Typography variant="body2">Select Template</Typography>
                  </MenuItem>
                  {playbookTemplates?.map((template) => (
                    <MenuItem key={template?.id} value={template?.id}>
                      <Typography variant="body2">{template?.title}</Typography>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Box
                display={'flex'}
                alignItems={'center'}
                gap={1}
                sx={{ cursor: 'pointer' }}
                onClick={() =>
                  setExpandedField((prev) =>
                    prev?.includes('default')
                      ? prev?.filter((ex) => ex !== 'default')
                      : [...prev, 'default'],
                  )
                }
              >
                {expandedField?.includes('default') ? (
                  <ArrowDropDown />
                ) : (
                  <ArrowRight />
                )}
                <Typography sx={{ color: 'text.secondary' }}>
                  Default Playbook Fields
                </Typography>
              </Box>
            </Box>
            {expandedField?.includes('default') ? (
              <Grid container spacing={1}>
                {Object.keys(defaultPlaybookFields || {})?.map(
                  (field, index) => (
                    <Grid item xs={12} key={index}>
                      <Box
                        p={1}
                        borderRadius={1}
                        display={'flex'}
                        flexDirection={'column'}
                        gap={1}
                      >
                        <Box
                          display={'flex'}
                          alignItems={'center'}
                          gap={1}
                          justifyContent={'space-between'}
                        >
                          <Typography
                            variant="subtitle2"
                            color={'text.secondary'}
                            fontWeight={'bold'}
                          >
                            {defaultPlaybookFieldsMapping?.[field]?.text}
                          </Typography>
                        </Box>
                        {field === 'challengesForCustomer' ? (
                          <>
                            {defaultPlaybookFields?.['challengesForCustomer'] &&
                              Array.isArray(
                                defaultPlaybookFields?.[
                                  'challengesForCustomer'
                                ],
                              ) &&
                              defaultPlaybookFields?.['challengesForCustomer']
                                .length > 0 &&
                              defaultPlaybookFields?.[
                                'challengesForCustomer'
                              ]?.map((objAndAns, faqIndex) => {
                                return (
                                  <Box
                                    key={faqIndex}
                                    sx={{
                                      border: '1px solid #d3d3d3',
                                      borderRadius: 2,
                                      padding: 2,
                                      marginTop: 1,
                                      position: 'relative',
                                    }}
                                  >
                                    <Button
                                      variant="text"
                                      sx={{
                                        position: 'absolute',
                                        top: 0,
                                        right: 10,
                                        zIndex: 2,
                                        background: '#fff',
                                        '&:hover': {
                                          background: '#fff',
                                        },
                                      }}
                                      onClick={() => {
                                        const updatedObjAndAns = defaultPlaybookFields?.[
                                          'challengesForCustomer'
                                        ]?.filter(
                                          (objAndAns, i) => i !== faqIndex,
                                        );
                                        setDefaultPlaybookFields((prev) => {
                                          return {
                                            ...prev,
                                            challengesForCustomer: updatedObjAndAns,
                                          };
                                        });
                                      }}
                                    >
                                      Remove
                                    </Button>
                                    <TextField
                                      label={`Challenge ${faqIndex + 1}`}
                                      size="small"
                                      variant="outlined"
                                      fullWidth
                                      value={objAndAns?.question}
                                      sx={{ marginTop: 1 }}
                                      onChange={(e) => {
                                        const updatedObjAndAns = defaultPlaybookFields?.[
                                          'challengesForCustomer'
                                        ]?.map((objAndAns, i) => {
                                          if (i === faqIndex) {
                                            return {
                                              ...objAndAns,
                                              question: e.target.value,
                                            };
                                          } else {
                                            return objAndAns;
                                          }
                                        });
                                        setDefaultPlaybookFields((prev) => {
                                          return {
                                            ...prev,
                                            challengesForCustomer: updatedObjAndAns,
                                          };
                                        });
                                      }}
                                    />
                                    <TextField
                                      size="small"
                                      label={`Recommended Response`}
                                      variant="outlined"
                                      fullWidth
                                      value={objAndAns?.answer}
                                      onChange={(e) => {
                                        const updatedObjAndAns = defaultPlaybookFields?.[
                                          'challengesForCustomer'
                                        ]?.map((objAndAns, i) => {
                                          if (i === faqIndex) {
                                            return {
                                              ...objAndAns,
                                              answer: e.target.value,
                                            };
                                          } else {
                                            return objAndAns;
                                          }
                                        });
                                        setDefaultPlaybookFields((prev) => {
                                          return {
                                            ...prev,
                                            challengesForCustomer: updatedObjAndAns,
                                          };
                                        });
                                      }}
                                      sx={{ marginTop: 1 }}
                                      // error={formik.touched.email && Boolean(formik.errors.email)}
                                      // helperText={formik.touched.email && formik.errors.email}
                                    />
                                  </Box>
                                );
                              })}
                            <Button
                              onClick={() => {
                                setDefaultPlaybookFields((prev) => {
                                  return {
                                    ...prev,
                                    challengesForCustomer: [
                                      ...prev.challengesForCustomer,
                                      {
                                        question: '',
                                        answer: '',
                                      },
                                    ],
                                  };
                                });
                              }}
                              sx={{ marginTop: 1 }}
                              variant="contained"
                            >
                              Add Challenge and Recommended Response
                            </Button>
                          </>
                        ) : field === 'customerQuestions' ? (
                          <>
                            {defaultPlaybookFields?.['customerQuestions'] &&
                              Array.isArray(
                                defaultPlaybookFields?.['customerQuestions'],
                              ) &&
                              defaultPlaybookFields?.['customerQuestions']
                                .length > 0 &&
                              defaultPlaybookFields?.['customerQuestions']?.map(
                                (objAndAns, faqIndex) => {
                                  return (
                                    <Box
                                      key={faqIndex}
                                      sx={{
                                        border: '1px solid #d3d3d3',
                                        borderRadius: 2,
                                        padding: 2,
                                        marginTop: 1,
                                        position: 'relative',
                                      }}
                                    >
                                      <Button
                                        variant="text"
                                        sx={{
                                          position: 'absolute',
                                          top: 0,
                                          right: 10,
                                          zIndex: 2,
                                          background: '#fff',
                                          '&:hover': {
                                            background: '#fff',
                                          },
                                        }}
                                        onClick={() => {
                                          const updatedObjAndAns = defaultPlaybookFields?.[
                                            'customerQuestions'
                                          ]?.filter(
                                            (objAndAns, i) => i !== faqIndex,
                                          );
                                          setDefaultPlaybookFields((prev) => {
                                            return {
                                              ...prev,
                                              customerQuestions: updatedObjAndAns,
                                            };
                                          });
                                        }}
                                      >
                                        Remove
                                      </Button>
                                      <TextField
                                        label={`Objection ${faqIndex + 1}`}
                                        size="small"
                                        variant="outlined"
                                        fullWidth
                                        value={objAndAns?.question}
                                        sx={{ marginTop: 1 }}
                                        onChange={(e) => {
                                          const updatedObjAndAns = defaultPlaybookFields?.[
                                            'customerQuestions'
                                          ]?.map((objAndAns, i) => {
                                            if (i === faqIndex) {
                                              return {
                                                ...objAndAns,
                                                question: e.target.value,
                                              };
                                            } else {
                                              return objAndAns;
                                            }
                                          });
                                          setDefaultFieldsMapping((prev) => {
                                            return {
                                              ...prev,
                                              customerQuestions: updatedObjAndAns,
                                            };
                                          });
                                        }}
                                      />
                                      <TextField
                                        size="small"
                                        label={`Answer`}
                                        variant="outlined"
                                        fullWidth
                                        value={objAndAns?.answer}
                                        onChange={(e) => {
                                          const updatedObjAndAns = defaultPlaybookFields?.[
                                            'customerQuestions'
                                          ]?.map((objAndAns, i) => {
                                            if (i === faqIndex) {
                                              return {
                                                ...objAndAns,
                                                answer: e.target.value,
                                              };
                                            } else {
                                              return objAndAns;
                                            }
                                          });
                                          setDefaultPlaybookFields((prev) => {
                                            return {
                                              ...prev,
                                              customerQuestions: updatedObjAndAns,
                                            };
                                          });
                                        }}
                                        sx={{ marginTop: 1 }}
                                        // error={formik.touched.email && Boolean(formik.errors.email)}
                                        // helperText={formik.touched.email && formik.errors.email}
                                      />
                                    </Box>
                                  );
                                },
                              )}
                            <Button
                              onClick={() => {
                                setDefaultPlaybookFields((prev) => {
                                  return {
                                    ...prev,
                                    customerQuestions: [
                                      ...prev.customerQuestions,
                                      {
                                        question: '',
                                        answer: '',
                                      },
                                    ],
                                  };
                                });
                              }}
                              sx={{ marginTop: 1 }}
                              variant="contained"
                            >
                              Add Objection and Answer
                            </Button>
                          </>
                        ) : (
                          <TextField
                            placeholder={
                              defaultPlaybookFieldsMapping?.[field]?.text
                            }
                            variant="outlined"
                            name={field}
                            fullWidth
                            size="small"
                            multiline
                            rows={5}
                            value={defaultPlaybookFields?.[field]}
                            onChange={(e) => {
                              setDefaultPlaybookFields((prev) => {
                                return {
                                  ...prev,
                                  [field]: e.target.value,
                                };
                              });
                            }}
                            type={'text'}
                          />
                        )}
                      </Box>
                    </Grid>
                  ),
                )}
              </Grid>
            ) : null}
            <Box display={'flex'} flexDirection={'column'} gap={1}>
              <Box
                display={'flex'}
                alignItems={'center'}
                gap={1}
                sx={{ cursor: 'pointer' }}
                onClick={() =>
                  setExpandedField((prev) =>
                    prev?.includes('custom')
                      ? prev?.filter((ex) => ex !== 'custom')
                      : [...prev, 'custom'],
                  )
                }
              >
                {expandedField?.includes('custom') ? (
                  <ArrowDropDown />
                ) : (
                  <ArrowRight />
                )}
                <Typography sx={{ color: 'text.secondary' }}>
                  Custom Playbook Fields
                </Typography>
              </Box>
            </Box>
            {expandedField?.includes('custom') ? (
              <Grid container spacing={1}>
                {customPlaybookFields?.map((field, index) => (
                  <Grid item xs={12} key={index}>
                    <Box
                      p={1}
                      borderRadius={1}
                      display={'flex'}
                      flexDirection={'column'}
                      gap={1}
                    >
                      <Box
                        display={'flex'}
                        alignItems={'center'}
                        gap={1}
                        justifyContent={'space-between'}
                      >
                        <Typography
                          variant="subtitle2"
                          color={'text.secondary'}
                          fontWeight={'bold'}
                        >
                          {field?.title}
                        </Typography>
                      </Box>
                      <TextField
                        placeholder={field?.title}
                        variant="outlined"
                        name={field}
                        fullWidth
                        size="small"
                        multiline
                        rows={5}
                        value={field?.content}
                        onChange={(e) => {
                          setCustomPlaybookFields((prev) => {
                            return prev.map((item) => {
                              if (item?.title === field?.title) {
                                return {
                                  ...item,
                                  content: e.target.value,
                                };
                              } else {
                                return item;
                              }
                            });
                          });
                        }}
                        type={'text'}
                      />
                    </Box>
                  </Grid>
                ))}
              </Grid>
            ) : null}
            <Box
              display={'flex'}
              alignItems={'center'}
              gap={1}
              ml={4}
              // justifyContent={'flex-end'}
            >
              <Button
                startIcon={<Add />}
                variant="contained"
                color="primary"
                size="small"
                onClick={() => setShowAddCustomFieldDialog(true)}
              >
                Add new field
              </Button>
            </Box>
          </Box>
          <Box
            p={1}
            sx={{
              borderTop: '1px solid #d3d3d3',
              height: 60,
              // background: '#d3d3d3',
            }}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'flex-end'}
          >
            <Button
              type="submit"
              size="small"
              disabled={
                creatingOrUpdatingPlaybook ||
                !tags ||
                (tags && tags?.length === 0)
              }
              variant="contained"
              color="primary"
              startIcon={
                creatingOrUpdatingPlaybook ? (
                  <CircularProgress size={20} />
                ) : null
              }
            >
              {showEditPlaybookDialog === 'new'
                ? 'Create Playbook'
                : 'Update Playbook'}
            </Button>
          </Box>
        </form>
      </Drawer>
      <Modal
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        open={Boolean(showWarningBox)}
        onClose={(e, reason) => {
          if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
            return;
          }
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Box
          sx={{
            backgroundColor: theme.palette.background.paper,
            width: '40vw',
            height: 'auto',
            // maxHeight: '40vh',
            overflowY: 'auto',
            borderRadius: 1,
            // padding: 2,
            position: 'relative',
          }}
        >
          <Box
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
            p={2}
            borderBottom={'1px solid #d3d3d3'}
          >
            <Typography
              variant="body1"
              sx={{
                color: theme.palette.error.light,
              }}
            >
              You have not filled the following fields.
            </Typography>
            <Button
              variant="outlined"
              size="small"
              startIcon={<Close />}
              onClick={() => setShowWarningBox(false)}
            >
              Close
            </Button>
          </Box>
          <Box p={2}>
            {!formikInvite.values?.playbookStructure?.productDescription?.trim() ? (
              <Typography
                variant="body1"
                sx={{
                  color: theme.palette.error.light,
                }}
              >
                Product Description
              </Typography>
            ) : null}
            {!formikInvite.values?.playbookStructure?.productKeyFeatures?.trim() ? (
              <Typography
                variant="body1"
                sx={{
                  color: theme.palette.error.light,
                }}
              >
                Product Key Features
              </Typography>
            ) : null}
            {!formikInvite.values?.playbookStructure?.customerQualificationQuestions?.trim() ? (
              <Typography
                variant="body1"
                sx={{
                  color: theme.palette.error.light,
                }}
              >
                Customer Qualification Questions
              </Typography>
            ) : null}
            {!(
              formikInvite.values?.playbookStructure?.customerQuestions &&
              formikInvite.values?.playbookStructure?.customerQuestions
                ?.length > 0 &&
              formikInvite.values?.playbookStructure?.customerQuestions?.find(
                (x) => x?.question?.trim() && x?.answer?.trim(),
              )
            ) ? (
              <Typography
                variant="body1"
                sx={{
                  color: theme.palette.error.light,
                }}
              >
                Typical customer objections and their answers
              </Typography>
            ) : null}
            {!(
              formikInvite.values?.playbookStructure?.challengesForCustomer &&
              formikInvite.values?.playbookStructure?.challengesForCustomer
                ?.length > 0 &&
              formikInvite.values?.playbookStructure?.challengesForCustomer?.find(
                (x) => x?.question?.trim() && x?.answer?.trim(),
              )
            ) ? (
              <Typography
                variant="body1"
                sx={{
                  color: theme.palette.error.light,
                }}
              >
                Top customer challenges and recommended responses
              </Typography>
            ) : null}
            {!formikInvite.values?.playbookStructure?.competingProducts?.trim() ? (
              <Typography
                variant="body1"
                sx={{
                  color: theme.palette.error.light,
                }}
              >
                Competing Products and Companies
              </Typography>
            ) : null}
          </Box>
          <Box
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
            p={2}
            borderTop={'1px solid #d3d3d3'}
          >
            <Button
              variant="outlined"
              size="small"
              startIcon={
                invitingCustomer ? <CircularProgress size={20} /> : null
              }
              disabled={invitingCustomer}
              onClick={() => {
                setInvitingCustomer(true);
                dispatch(
                  createCustomer(
                    formikInvite.values.withPlaybook
                      ? formikInvite.values.withProspects
                        ? {
                            addTeamMemberRequestDtos: [
                              {
                                designations: ['account_admin'],
                                email: formikInvite.values.email,
                                emailVerified:
                                  formikInvite.values.emailVerified,
                                orgRoles: ['ORG_ADMIN'],
                              },
                            ],
                            botDesignations:
                              formikInvite.values.prospectDetails?.designation,
                            botVerticals:
                              formikInvite.values.prospectDetails?.vertical,
                            demo: formikInvite.values?.demo,
                            methodologies: methodologies,
                            contentScoringConfig: withContentScoringConfig
                              ? {
                                  inbound: sameConfig
                                    ? contentScoringConfig.outbound
                                    : contentScoringConfig.inbound,
                                  outbound: contentScoringConfig.outbound,
                                }
                              : null,
                            organizationName: formikInvite.values.companyName,
                            planId: formikInvite.values.planId,
                            priceRequest: {
                              amount: clientPlanPrice,
                            },
                            planAddOns: addOns
                              ?.filter((addon) =>
                                selectedAddOns?.includes(
                                  addon?.feature?.feature,
                                ),
                              )
                              ?.map((addon) => {
                                return {
                                  ...addon,
                                  price: {
                                    ...addon.price,
                                    amount: Number(
                                      addOnsPrice?.[addon?.feature?.feature],
                                    ),
                                  },
                                };
                              }),
                            countries: [formikInvite.values.country],
                            playbookStructure: {
                              ...defaultPlaybookFields,
                              customPlayBookFields: customPlaybookFields,
                            },
                          }
                        : {
                            addTeamMemberRequestDtos: [
                              {
                                designations: ['account_admin'],
                                email: formikInvite.values.email,
                                emailVerified:
                                  formikInvite.values.emailVerified,
                                orgRoles: ['ORG_ADMIN'],
                              },
                            ],
                            demo: formikInvite.values?.demo,
                            methodologies: methodologies,
                            organizationName: formikInvite.values.companyName,
                            contentScoringConfig: withContentScoringConfig
                              ? {
                                  inbound: sameConfig
                                    ? contentScoringConfig.outbound
                                    : contentScoringConfig.inbound,
                                  outbound: contentScoringConfig.outbound,
                                }
                              : null,
                            planId: formikInvite.values.planId,
                            priceRequest: {
                              amount: clientPlanPrice,
                            },
                            countries: [formikInvite.values.country],
                            playbookStructure: {
                              ...defaultPlaybookFields,
                              customPlayBookFields: customPlaybookFields,
                            },
                          }
                      : {
                          addTeamMemberRequestDtos: [
                            {
                              designations: ['account_admin'],
                              email: formikInvite.values.email,
                              emailVerified: formikInvite.values.emailVerified,
                              orgRoles: ['ORG_ADMIN'],
                            },
                          ],
                          organizationName: formikInvite.values.companyName,
                          demo: formikInvite.values?.demo,
                          methodologies: methodologies,
                          contentScoringConfig: withContentScoringConfig
                            ? {
                                inbound: sameConfig
                                  ? contentScoringConfig.outbound
                                  : contentScoringConfig.inbound,
                                outbound: contentScoringConfig.outbound,
                              }
                            : null,
                          countries: [formikInvite.values.country],
                          planId: formikInvite.values.planId,
                          priceRequest: {
                            amount: clientPlanPrice,
                          },
                        },
                    (data, error) => {
                      if (error) {
                        setInvitingCustomer(false);
                        dispatch(
                          showAlert({
                            message:
                              'Customer invitation failed. Please try again later!',
                            showCross: true,
                            title: null,
                            type: 'error',
                            autoHideDuration: 2000,
                            vertical: 'top',
                            horizontal: 'center',
                          }),
                        );
                      } else {
                        setInvitingCustomer(false);
                        setShowWarningBox(false);
                        dispatch(
                          getWlCustomers(
                            userDetails?.organizationId,
                            (data) => {
                              console.log(data);
                              // setCustomers(data);
                            },
                          ),
                        );
                        dispatch(
                          showAlert({
                            message: 'Customer invited successfully',
                            showCross: true,
                            title: null,
                            type: 'success',
                            autoHideDuration: 2000,
                            vertical: 'top',
                            horizontal: 'center',
                          }),
                        );
                        setShowAddCustomerDialog(false);
                      }
                    },
                  ),
                );
              }}
            >
              Continue Anyway
            </Button>
            <Button
              variant="contained"
              size="small"
              // startIcon={
              //   invitingCustomer ? <CircularProgress size={20} /> : null
              // }
              disabled={invitingCustomer}
              onClick={() => setShowWarningBox(false)}
            >
              Continue Editing
            </Button>
          </Box>
        </Box>
      </Modal>
      <Modal
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        open={Boolean(showWarningBoxForUpdatePlaybook)}
        onClose={(e, reason) => {
          if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
            return;
          }
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Box
          sx={{
            backgroundColor: theme.palette.background.paper,
            width: '40vw',
            height: 'auto',
            // maxHeight: '40vh',
            overflowY: 'auto',
            borderRadius: 1,
            // padding: 2,
            position: 'relative',
          }}
        >
          <Box
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
            p={2}
            borderBottom={'1px solid #d3d3d3'}
          >
            <Typography
              variant="body1"
              sx={{
                color: theme.palette.error.light,
              }}
            >
              You have not filled the following fields.
            </Typography>
            <Button
              variant="outlined"
              size="small"
              startIcon={<Close />}
              onClick={() => setShowWarningBoxForUpdatePlaybook(false)}
            >
              Close
            </Button>
          </Box>
          <Box p={2}>
            {!formikPlaybook?.values?.productDescription?.trim() ? (
              <Typography
                variant="body1"
                sx={{
                  color: theme.palette.error.light,
                }}
              >
                Product Description
              </Typography>
            ) : null}
            {!formikPlaybook?.values?.productKeyFeatures?.trim() ? (
              <Typography
                variant="body1"
                sx={{
                  color: theme.palette.error.light,
                }}
              >
                Product Key Features
              </Typography>
            ) : null}
            {!formikPlaybook?.values?.customerQualificationQuestions?.trim() ? (
              <Typography
                variant="body1"
                sx={{
                  color: theme.palette.error.light,
                }}
              >
                Customer Qualification Questions
              </Typography>
            ) : null}
            {!(
              formikPlaybook?.values?.customerQuestions &&
              formikPlaybook?.values?.customerQuestions?.length > 0 &&
              formikPlaybook?.values?.customerQuestions?.find(
                (x) => x?.question?.trim() && x?.answer?.trim(),
              )
            ) ? (
              <Typography
                variant="body1"
                sx={{
                  color: theme.palette.error.light,
                }}
              >
                Typical customer objections and their answers
              </Typography>
            ) : null}
            {!(
              formikPlaybook?.values?.challengesForCustomer &&
              formikPlaybook?.values?.challengesForCustomer?.length > 0 &&
              formikPlaybook?.values?.challengesForCustomer?.find(
                (x) => x?.question?.trim() && x?.answer?.trim(),
              )
            ) ? (
              <Typography
                variant="body1"
                sx={{
                  color: theme.palette.error.light,
                }}
              >
                Top customer challenges and recommended responses
              </Typography>
            ) : null}
            {!formikPlaybook?.values?.competingProducts?.trim() ? (
              <Typography
                variant="body1"
                sx={{
                  color: theme.palette.error.light,
                }}
              >
                Competing Products and Companies
              </Typography>
            ) : null}
          </Box>
          <Box
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
            p={2}
            borderTop={'1px solid #d3d3d3'}
          >
            <Button
              variant="outlined"
              size="small"
              startIcon={
                updatingOrgDetails ? <CircularProgress size={20} /> : null
              }
              disabled={updatingOrgDetails}
              onClick={() => {
                setUpdatingOrgDetails(true);
                dispatch(
                  updateCustomerDetails(
                    currentOrgId,
                    {
                      organizationName,
                      methodologies: methodologies,
                      countries: [country],
                      contentScoringConfig: withContentScoringConfig
                        ? {
                            inbound: sameConfig
                              ? contentScoringConfig.outbound
                              : contentScoringConfig.inbound,
                            outbound: contentScoringConfig.outbound,
                          }
                        : null,
                      playbookStructure: {
                        ...defaultPlaybookFields,
                        customPlayBookFields: customPlaybookFields,
                      },
                    },
                    () => {
                      setUpdatingOrgDetails(false);
                      dispatch(
                        getWlCustomers(userDetails?.organizationId, () => {}),
                      );
                      setShowWarningBoxForUpdatePlaybook(false);
                      dispatch(
                        showAlert({
                          message: 'Details updated successfully.',
                          showCross: true,
                          title: null,
                          type: 'success',
                          autoHideDuration: 2000,
                          vertical: 'top',
                          horizontal: 'center',
                        }),
                      );
                    },
                  ),
                );
              }}
            >
              Continue Anyway
            </Button>
            <Button
              variant="contained"
              size="small"
              // startIcon={
              //   invitingCustomer ? <CircularProgress size={20} /> : null
              // }
              disabled={updatingOrgDetails}
              onClick={() => setShowWarningBoxForUpdatePlaybook(false)}
            >
              Continue Editing
            </Button>
          </Box>
        </Box>
      </Modal>
      <Modal
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        open={Boolean(showGeneratePlaybookWithAI)}
        onClose={(e, reason) => {
          if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
            return;
          }
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Box
          sx={{
            backgroundColor: theme.palette.background.paper,
            width: '50vw',
            height: 'auto',
            borderRadius: 1,
            // padding: 2,
            position: 'relative',
          }}
        >
          <Box
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
            p={2}
            sx={{
              background: '#f1f1f1',
            }}
          >
            <Typography
              variant="body1"
              sx={
                {
                  // fontWeight: 'bold',
                }
              }
            >
              Generate Playbook with AI
            </Typography>
            <Button
              variant="outlined"
              size="small"
              startIcon={<Close />}
              onClick={() => setShowGeneratePlaybookWithAI('')}
            >
              Close
            </Button>
          </Box>
          <PlaybookGeneration
            asComponent={true}
            handlePrefillFromGeneratedPlaybook={(generatedPlaybook) =>
              handlePrefillFromGeneratedPlaybook(generatedPlaybook)
            }
          />
        </Box>
      </Modal>
      <Modal
        open={Boolean(showQuestionDialog)}
        onClose={(e, reason) => {
          if (reason !== 'backdropClick' && reason !== 'escapeKeyDown')
            setShowQuestionDialog(null);
          else return;
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[5],
            // maxHeight: '30vh',
            maxWidth: '50vw',
            minWidth: '40vw',
            outline: 'none',
            borderRadius: 1,
            // padding: 3,
            position: 'relative',
          }}
        >
          {showQuestionDialog === 'create' ? (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
                // padding: 2,
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  background: '#f1f1f1',
                  padding: 2,
                }}
              >
                <Typography variant="body1">
                  {showQuestionDialog === 'create' ? 'Add new' : 'Update'}{' '}
                  Question
                  {showQuestionDialog === 'create' ? 's' : ''}
                </Typography>
                <FormControlLabel
                  checked={showSuggestions}
                  control={
                    <Checkbox
                      size="small"
                      value={showSuggestions}
                      name="showSuggestions"
                    />
                  }
                  label={'Show Suggested Questions'}
                  onChange={(e) => {
                    setShowSuggestions(e.target.checked);
                  }}
                />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 1,
                  padding: '8px 16px',
                }}
              >
                {newQuestions && newQuestions.length > 0
                  ? newQuestions.map((q, i) => (
                      <Box
                        key={`question-${i}`}
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          // gap: 1,
                          width: '100%',
                        }}
                      >
                        <Box
                          display={'flex'}
                          alignItems={'center'}
                          justifyContent={'space-between'}
                        >
                          <Typography
                            variant="caption"
                            color={'text.secondary'}
                            fontWeight={'bold'}
                          >
                            Question {i + 1}
                          </Typography>
                          <Button
                            variant="text"
                            size="small"
                            sx={{
                              padding: '4px 8px',
                            }}
                            color="primary"
                            onClick={() =>
                              setNewQuestions((prev) => [
                                ...prev.slice(0, i),
                                ...prev.slice(i + 1),
                              ])
                            }
                          >
                            Remove
                          </Button>
                        </Box>
                        {showSuggestions ? (
                          <AutoCompleteMUI
                            id="question"
                            value={q}
                            onChange={(event, newValue) => {
                              setNewQuestions([
                                ...newQuestions.slice(0, i),
                                newValue,
                                ...newQuestions.slice(i + 1),
                              ]);
                            }}
                            options={
                              orgConfigs?.defaultPresetQuestions?.questions?.filter(
                                (q) =>
                                  currentTab === 'clients-ask-donna'
                                    ? !orgConfigs?.clientPresetQuestions?.includes(
                                        q,
                                      )
                                    : !customers
                                        ?.find(
                                          (org) => org?.id === currentOrgId,
                                        )
                                        ?.presetQuestions?.includes(q),
                              ) || []
                            }
                            onTextChange={(e) =>
                              setNewQuestions([
                                ...newQuestions.slice(0, i),
                                e.target.value,
                                ...newQuestions.slice(i + 1),
                              ])
                            }
                            placeholder="Question"
                            onKeyDown={() => {}}
                          />
                        ) : (
                          <TextField
                            placeholder="Question"
                            variant="outlined"
                            name={'question'}
                            size="small"
                            value={q}
                            onChange={(e) => {
                              setNewQuestions([
                                ...newQuestions.slice(0, i),
                                e.target.value,
                                ...newQuestions.slice(i + 1),
                              ]);
                            }}
                            type={'text'}
                          />
                        )}
                      </Box>
                    ))
                  : null}
                <Box display={'flex'} justifyContent={'flex-end'}>
                  <Button
                    variant="outlined"
                    size="small"
                    startIcon={<Add />}
                    sx={{
                      padding: '4px 8px',
                    }}
                    onClick={() => setNewQuestions((prev) => [...prev, ''])}
                  >
                    Add Question
                  </Button>
                </Box>
              </Box>
            </Box>
          ) : (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
                padding: 2,
              }}
            >
              <Typography variant="body1">Update Question</Typography>
              <TextField
                placeholder="Question"
                variant="outlined"
                name={'question'}
                size="small"
                fullWidth
                multiline
                rows={3}
                value={question}
                onChange={(e) => setQuestion(e.target.value)}
                type={'text'}
              />
            </Box>
          )}
          <Box
            width={1}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
            p={'8px 16px'}
          >
            <Button
              onClick={(e) => {
                setShowQuestionDialog(null);
              }}
              color="primary"
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              disabled={
                (showQuestionDialog === 'create' &&
                  (!newQuestions ||
                    !(
                      newQuestions &&
                      newQuestions.length > 0 &&
                      newQuestions?.filter((nq) => nq?.trim())?.length > 0
                    ))) ||
                (!question?.trim() && showQuestionDialog !== 'create')
              }
              onClick={(e) => {
                if (showQuestionDialog === 'create') {
                  if (currentTab === 'clients-ask-donna') {
                    dispatch(
                      updateGlobalOrganizationConfig(
                        {
                          clientPresetQuestions: [
                            ...(orgConfigs?.clientPresetQuestions || []),
                            ...(newQuestions?.filter((nq) => nq?.trim()) || []),
                          ],
                          textInLogo: orgConfigs?.textInLogo,
                          generalAutoAnalysis:
                            orgConfigs?.isGeneralAutoAnalysis,
                        },
                        () => {
                          setShowQuestionDialog(null);
                          setQuestion('');
                          setNewQuestions(['']);
                          dispatch(getOrganizationConfigs(() => {}));
                        },
                      ),
                    );
                  } else {
                    dispatch(
                      updateCustomerDetails(
                        currentOrgId,
                        {
                          presetQuestions: [
                            ...(customers?.find(
                              (org) => org?.id === currentOrgId,
                            )?.presetQuestions || []),
                            ...(newQuestions?.filter((nq) => nq?.trim()) || []),
                          ],
                        },
                        () => {
                          setShowQuestionDialog(null);
                          setQuestion('');
                          setNewQuestions(['']);
                          setUpdatingOrgDetails(false);
                          dispatch(
                            getWlCustomers(
                              userDetails?.organizationId,
                              () => {},
                            ),
                          );
                          dispatch(
                            showAlert({
                              message: 'Details updated successfully.',
                              showCross: true,
                              title: null,
                              type: 'success',
                              autoHideDuration: 2000,
                              vertical: 'top',
                              horizontal: 'center',
                            }),
                          );
                        },
                      ),
                    );
                  }
                } else {
                  if (currentTab === 'clients-ask-donna') {
                    dispatch(
                      updateGlobalOrganizationConfig(
                        {
                          clientPresetQuestions: orgConfigs?.clientPresetQuestions.map(
                            (qs) =>
                              qs === showQuestionDialog ? question?.trim() : qs,
                          ),
                          textInLogo: orgConfigs?.textInLogo,
                          generalAutoAnalysis:
                            orgConfigs?.isGeneralAutoAnalysis,
                        },
                        () => {
                          setShowQuestionDialog(null);
                          setQuestion('');
                          dispatch(getOrganizationConfigs(() => {}));
                        },
                      ),
                    );
                  } else {
                    dispatch(
                      updateCustomerDetails(
                        currentOrgId,
                        {
                          presetQuestions: customers
                            ?.find((org) => org?.id === currentOrgId)
                            ?.presetQuestions.map((qs) =>
                              qs === showQuestionDialog ? question?.trim() : qs,
                            ),
                        },
                        () => {
                          setShowQuestionDialog(null);
                          setQuestion('');
                          setUpdatingOrgDetails(false);
                          dispatch(
                            getWlCustomers(
                              userDetails?.organizationId,
                              () => {},
                            ),
                          );
                          dispatch(
                            showAlert({
                              message: 'Details updated successfully.',
                              showCross: true,
                              title: null,
                              type: 'success',
                              autoHideDuration: 2000,
                              vertical: 'top',
                              horizontal: 'center',
                            }),
                          );
                        },
                      ),
                    );
                  }
                }
              }}
              color="primary"
              // autoFocus
            >
              {showQuestionDialog === 'create' ? 'Save' : 'Update'}
            </Button>
          </Box>
        </Box>
      </Modal>
      <Modal
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        open={showEditContentScoringConfig}
        onClose={(e, reason) => {
          if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
            return;
          }
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Box
          sx={{
            backgroundColor: theme.palette.background.paper,
            // boxShadow: theme.shadows[5],
            outline: 'none',
            maxHeight: '95vh',
            maxWidth: '70vw',
            minWidth: '70vw',
            borderRadius: 1,
            // padding: 2,
            position: 'relative',
            // paddingTop: 3,
          }}
        >
          <Typography
            variant="body1"
            padding={2}
            borderBottom={'1px solid #d3d3d3'}
          >
            Call Scoring Configurations
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              padding: '16px',
              minHeight: 'calc(95vh - 140px)',
              maxHeight: 'calc(95vh - 140px)',
              overflowY: 'scroll',
              '&::-webkit-scrollbar': {
                width: '5px',
              },
              '&::-webkit-scrollbar-track': {
                boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: theme.palette.primary.main,
                borderRadius: 2,
                // outline: '1px solid slategrey',
              },
            }}
          >
            {/* <FormControl>
              <FormLabel id="demo-row-radio-buttons-group-label">
                <Typography variant="caption" color={'text.secondary'}>
                  Configure with
                </Typography>
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={configType}
                onChange={(e) => {
                  if (e.target.value === 'form') {
                    try {
                      const outboundObj = JSON.parse(
                        contentScoreConfigText?.outbound || '{}',
                      );
                      const inboundObj = JSON.parse(
                        contentScoreConfigText?.inbound || '{}',
                      );
                      setContentScoringConfig((prev) => {
                        return {
                          ...prev,
                          outbound: outboundObj,
                          inbound: inboundObj,
                        };
                      });
                      setConfigType(e.target.value);
                    } catch (e) {
                      alert(
                        'Failed to parse json text. please validate the json.',
                      );
                    }
                  } else {
                    try {
                      setContentScoreConfigText((prev) => {
                        return {
                          ...prev,
                          outbound: JSON.stringify(
                            contentScoringConfig?.outbound || '{}',
                            null,
                            2,
                          ),
                          inbound: JSON.stringify(
                            contentScoringConfig?.inbound || '{}',
                            null,
                            2,
                          ),
                        };
                      });
                      setConfigType(e.target.value);
                    } catch (e) {
                      alert(
                        'Failed to convert json to text. please try again.',
                      );
                    }
                  }
                }}
              >
                <FormControlLabel
                  value={'form'}
                  control={<Radio size="small" />}
                  label={'Form'}
                />
                <FormControlLabel
                  value={'json'}
                  control={<Radio size="small" />}
                  label={'json'}
                />
              </RadioGroup>
            </FormControl> */}
            <FormControl>
              <FormLabel id="demo-row-radio-buttons-group-label">
                <Typography variant="caption" color={'text.secondary'}>
                  Call Type
                </Typography>
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={callType}
                onChange={(e) => setCallType(e.target.value)}
              >
                <FormControlLabel
                  value={'outbound'}
                  control={<Radio size="small" />}
                  label={'Outbound'}
                />
                <FormControlLabel
                  value={'inbound'}
                  control={<Radio size="small" />}
                  label={'Inbound'}
                />
              </RadioGroup>
            </FormControl>
            {callType === 'inbound' ? (
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    checked={sameConfig}
                    name="sameConfig"
                  />
                }
                label={
                  <Typography variant="subtitle2">Same as outbound</Typography>
                }
                onChange={(e) => {
                  setSameConfig(e.target.checked);
                }}
              />
            ) : null}
            {callType === 'outbound' || (callType === 'inbound' && !sameConfig)
              ? Object.keys(contentScoringConfig?.[callType] || {})?.map(
                  (configKey) => {
                    return configKey === 'criteriaList' ? (
                      <Box
                        sx={{
                          // border: '1px solid #d3d3d3',
                          borderRadius: 2,
                          position: 'relative',
                          // padding: 2,
                          display: 'flex',
                          flexDirection: 'column',
                          gap: 2,
                        }}
                        key={configKey}
                      >
                        {configType === 'form' ? (
                          <>
                            <Box
                              display={'flex'}
                              flexDirection={'column'}
                              gap={2}
                              p={1}
                            >
                              {contentScoringConfig[callType][configKey] ? (
                                contentScoringConfig[callType][configKey]
                                  ?.length > 0 ? (
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      flexDirection: 'column',
                                      gap: 2,
                                    }}
                                  >
                                    <Box
                                      display={'flex'}
                                      alignItems={'center'}
                                      justifyContent={'space-between'}
                                      gap={1}
                                    >
                                      <Typography>Categories</Typography>
                                      <Box
                                        display={'flex'}
                                        alignItems={'center'}
                                        gap={1}
                                      >
                                        <TextField
                                          placeholder="Category Name"
                                          variant="outlined"
                                          name={'criteriaName'}
                                          size="small"
                                          value={criteriaName}
                                          onChange={(e) =>
                                            setCriteriaName(e.target.value)
                                          }
                                          type="text"
                                        />
                                        <Button
                                          variant="contained"
                                          size="small"
                                          startIcon={<Add />}
                                          disabled={!criteriaName?.trim()}
                                          onClick={() => {
                                            setContentScoringConfig((prev) => {
                                              return {
                                                ...prev,
                                                [callType]: {
                                                  ...prev[callType],
                                                  [configKey]: [
                                                    {
                                                      [criteriaName]: [],
                                                    },
                                                    ...prev[callType][
                                                      configKey
                                                    ],
                                                  ],
                                                  categoryWeight: {
                                                    ...prev[callType][
                                                      'categoryWeight'
                                                    ],
                                                    [criteriaName]: 1,
                                                  },
                                                },
                                              };
                                            });
                                            setCriteriaName('');
                                          }}
                                        >
                                          Add new category
                                        </Button>
                                      </Box>
                                    </Box>
                                    {contentScoringConfig[callType][
                                      configKey
                                    ]?.map((criteriaKey, index) => (
                                      <Box
                                        key={
                                          Object.keys(criteriaKey || {})?.[0]
                                        }
                                        display={'flex'}
                                        flexDirection={'column'}
                                        gap={2}
                                        ml={4}
                                      >
                                        <Box
                                          display={'flex'}
                                          gap={2}
                                          alignItems={'center'}
                                        >
                                          <Typography variant="body1">
                                            {
                                              Object.keys(
                                                criteriaKey || {},
                                              )?.[0]
                                            }
                                          </Typography>
                                          <Tooltip
                                            arrow
                                            placement="right"
                                            title="Delete Category"
                                          >
                                            <IconButton
                                              sx={{
                                                borderRadius: '4px',
                                                border: '1px solid #d3d3d3',
                                                padding: '4px',
                                              }}
                                              onClick={() => {
                                                setContentScoringConfig(
                                                  (prev) => {
                                                    return {
                                                      ...prev,
                                                      [callType]: {
                                                        ...prev[callType],
                                                        [configKey]: prev[
                                                          callType
                                                        ][configKey]?.filter(
                                                          (criteriaMap) =>
                                                            Object.keys(
                                                              criteriaMap || {},
                                                            )?.[0] !==
                                                            Object.keys(
                                                              criteriaKey || {},
                                                            )?.[0],
                                                        ),
                                                        ['categoryWeight']: Object.keys(
                                                          prev[callType][
                                                            'categoryWeight'
                                                          ] || {},
                                                        )
                                                          ?.filter(
                                                            (key) =>
                                                              key !==
                                                              Object.keys(
                                                                criteriaKey ||
                                                                  {},
                                                              )?.[0],
                                                          )
                                                          ?.reduce(
                                                            (acc, curr) => {
                                                              acc[curr] =
                                                                prev[callType][
                                                                  'categoryWeight'
                                                                ][curr];
                                                              return acc;
                                                            },
                                                            {},
                                                          ),
                                                      },
                                                    };
                                                  },
                                                );
                                              }}
                                            >
                                              <Delete sx={{ fontSize: 18 }} />
                                            </IconButton>
                                          </Tooltip>
                                        </Box>
                                        <Box
                                          display={'flex'}
                                          gap={1}
                                          alignItems={'center'}
                                          ml={4}
                                        >
                                          <Typography flex={1}>
                                            Category Weight
                                          </Typography>
                                          <TextField
                                            placeholder={'Category Weight'}
                                            variant="outlined"
                                            name={'factorKey'}
                                            size="small"
                                            sx={{
                                              flex: 2,
                                            }}
                                            value={
                                              contentScoringConfig[callType][
                                                'categoryWeight'
                                              ][
                                                Object.keys(
                                                  criteriaKey || {},
                                                )?.[0]
                                              ]
                                            }
                                            onChange={(e) => {
                                              setContentScoringConfig(
                                                (prev) => {
                                                  return {
                                                    ...prev,
                                                    [callType]: {
                                                      ...prev[callType],
                                                      [configKey]:
                                                        prev[callType][
                                                          configKey
                                                        ],
                                                      categoryWeight: {
                                                        ...prev[callType][
                                                          'categoryWeight'
                                                        ],
                                                        [Object.keys(
                                                          criteriaKey || {},
                                                        )?.[0]]: e.target.value,
                                                      },
                                                    },
                                                  };
                                                },
                                              );
                                            }}
                                            type={'number'}
                                          />
                                        </Box>
                                        <Box
                                          display={'flex'}
                                          flexDirection={'column'}
                                          gap={2}
                                          ml={4}
                                        >
                                          {contentScoringConfig[callType][
                                            configKey
                                          ][index][
                                            Object.keys(criteriaKey || {})?.[0]
                                          ] ? (
                                            contentScoringConfig[callType][
                                              configKey
                                            ][index][
                                              Object.keys(
                                                criteriaKey || {},
                                              )?.[0]
                                            ]?.length > 0 ? (
                                              <>
                                                <Box
                                                  display={'flex'}
                                                  alignItems={'center'}
                                                  justifyContent={
                                                    'space-between'
                                                  }
                                                  gap={1}
                                                >
                                                  <Typography variant="body1">
                                                    Metrics
                                                  </Typography>
                                                  <Box
                                                    display={'flex'}
                                                    alignItems={'center'}
                                                    gap={1}
                                                  >
                                                    <TextField
                                                      placeholder="Metric Name"
                                                      variant="outlined"
                                                      name={
                                                        'criteriaFactorName'
                                                      }
                                                      size="small"
                                                      // sx={{
                                                      //   width: '100%',
                                                      // }}
                                                      value={criteriaFactorName}
                                                      onChange={(e) =>
                                                        setCriteriaFactorName(
                                                          e.target.value,
                                                        )
                                                      }
                                                      type="text"
                                                    />
                                                    <Button
                                                      variant="contained"
                                                      size="small"
                                                      startIcon={<Add />}
                                                      disabled={
                                                        !criteriaFactorName?.trim()
                                                      }
                                                      onClick={() => {
                                                        setContentScoringConfig(
                                                          (prev) => {
                                                            return {
                                                              ...prev,
                                                              [callType]: {
                                                                ...prev[
                                                                  callType
                                                                ],
                                                                [configKey]: prev[
                                                                  callType
                                                                ][
                                                                  configKey
                                                                ]?.map(
                                                                  (ck, cki) =>
                                                                    cki ===
                                                                    index
                                                                      ? {
                                                                          [Object.keys(
                                                                            criteriaKey,
                                                                          )[0]]: [
                                                                            {
                                                                              [criteriaFactorName]: {
                                                                                description:
                                                                                  '',
                                                                                weight: 1,
                                                                                question:
                                                                                  '',
                                                                              },
                                                                            },
                                                                            ...prev[
                                                                              callType
                                                                            ][
                                                                              configKey
                                                                            ][
                                                                              index
                                                                            ][
                                                                              Object.keys(
                                                                                criteriaKey,
                                                                              )[0]
                                                                            ],
                                                                          ],
                                                                        }
                                                                      : ck,
                                                                ),
                                                              },
                                                            };
                                                          },
                                                        );
                                                        setCriteriaFactorName(
                                                          '',
                                                        );
                                                      }}
                                                      // fullWidth
                                                    >
                                                      Add new metric
                                                    </Button>
                                                  </Box>
                                                </Box>
                                                {contentScoringConfig[callType][
                                                  configKey
                                                ][index][
                                                  Object.keys(
                                                    criteriaKey || {},
                                                  )?.[0]
                                                ]?.map(
                                                  (
                                                    criteriaKeyFactor,
                                                    factorIndex,
                                                  ) => {
                                                    return (
                                                      <Box
                                                        key={`${
                                                          Object.keys(
                                                            criteriaKeyFactor ||
                                                              {},
                                                          )?.[0]
                                                        }${factorIndex}`}
                                                        display={'flex'}
                                                        flexDirection={'column'}
                                                        backgroundColor={'#fff'}
                                                      >
                                                        <Box
                                                          display={'flex'}
                                                          gap={1}
                                                          alignItems={'center'}
                                                          justifyContent={
                                                            'space-between'
                                                          }
                                                          sx={{
                                                            padding: 1,
                                                            border:
                                                              '1px solid #d3d3d3',
                                                          }}
                                                        >
                                                          <Typography variant="body1">
                                                            {capitalizeText(
                                                              Object.keys(
                                                                criteriaKeyFactor ||
                                                                  {},
                                                              )?.[0],
                                                            )}
                                                          </Typography>
                                                          <IconButton
                                                            onClick={() => {
                                                              setContentScoringConfig(
                                                                (prev) => {
                                                                  return {
                                                                    ...prev,
                                                                    [callType]: {
                                                                      ...prev[
                                                                        callType
                                                                      ],
                                                                      [configKey]: prev[
                                                                        callType
                                                                      ][
                                                                        configKey
                                                                      ]?.map(
                                                                        (
                                                                          ck,
                                                                          cki,
                                                                        ) =>
                                                                          cki ===
                                                                          index
                                                                            ? {
                                                                                [Object.keys(
                                                                                  ck ||
                                                                                    {},
                                                                                )?.[0]]: prev[
                                                                                  callType
                                                                                ][
                                                                                  configKey
                                                                                ][
                                                                                  index
                                                                                ][
                                                                                  Object.keys(
                                                                                    criteriaKey ||
                                                                                      {},
                                                                                  )?.[0]
                                                                                ]?.filter(
                                                                                  (
                                                                                    key,
                                                                                  ) =>
                                                                                    Object.keys(
                                                                                      key ||
                                                                                        {},
                                                                                    )?.[0] !==
                                                                                    Object.keys(
                                                                                      criteriaKeyFactor ||
                                                                                        {},
                                                                                    )?.[0],
                                                                                ),
                                                                              }
                                                                            : ck,
                                                                      ),
                                                                    },
                                                                  };
                                                                },
                                                              );
                                                            }}
                                                          >
                                                            <Delete
                                                              sx={{
                                                                fontSize: 18,
                                                              }}
                                                            />
                                                          </IconButton>
                                                        </Box>
                                                        <Box
                                                          sx={{
                                                            border:
                                                              '1px solid #d3d3d3',
                                                          }}
                                                        >
                                                          {contentScoringConfig[
                                                            callType
                                                          ][configKey][index][
                                                            Object.keys(
                                                              criteriaKey || {},
                                                            )?.[0]
                                                          ][factorIndex][
                                                            Object.keys(
                                                              criteriaKeyFactor ||
                                                                {},
                                                            )?.[0]
                                                          ]
                                                            ? Object.keys(
                                                                contentScoringConfig[
                                                                  callType
                                                                ][configKey][
                                                                  index
                                                                ][
                                                                  Object.keys(
                                                                    criteriaKey ||
                                                                      {},
                                                                  )?.[0]
                                                                ][factorIndex][
                                                                  Object.keys(
                                                                    criteriaKeyFactor ||
                                                                      {},
                                                                  )?.[0]
                                                                ] || {},
                                                              )?.map(
                                                                (factorKey) => {
                                                                  return factorKey ===
                                                                    'description' ||
                                                                    factorKey ===
                                                                      'weight' ||
                                                                    factorKey ===
                                                                      'question' ? (
                                                                    <Box
                                                                      display={
                                                                        'flex'
                                                                      }
                                                                      flexDirection={
                                                                        'column'
                                                                      }
                                                                    >
                                                                      <Box
                                                                        display={
                                                                          'flex'
                                                                        }
                                                                        gap={1}
                                                                        // alignItems={'center'}
                                                                        sx={{
                                                                          padding: 1,
                                                                        }}
                                                                      >
                                                                        <Typography
                                                                          flex={
                                                                            1
                                                                          }
                                                                        >
                                                                          {capitalizeText(
                                                                            factorKey,
                                                                          )}
                                                                        </Typography>
                                                                        <TextField
                                                                          placeholder={
                                                                            factorKey
                                                                          }
                                                                          variant="outlined"
                                                                          name={
                                                                            'factorKey'
                                                                          }
                                                                          size="small"
                                                                          multiline={
                                                                            factorKey ===
                                                                              'description' ||
                                                                            factorKey ===
                                                                              'question'
                                                                              ? true
                                                                              : false
                                                                          }
                                                                          rows={
                                                                            factorKey ===
                                                                              'description' ||
                                                                            factorKey ===
                                                                              'question'
                                                                              ? 3
                                                                              : 1
                                                                          }
                                                                          sx={{
                                                                            flex: 2,
                                                                          }}
                                                                          value={
                                                                            contentScoringConfig[
                                                                              callType
                                                                            ][
                                                                              configKey
                                                                            ][
                                                                              index
                                                                            ][
                                                                              Object.keys(
                                                                                criteriaKey ||
                                                                                  {},
                                                                              )?.[0]
                                                                            ][
                                                                              factorIndex
                                                                            ][
                                                                              Object.keys(
                                                                                criteriaKeyFactor ||
                                                                                  {},
                                                                              )?.[0]
                                                                            ][
                                                                              factorKey
                                                                            ]
                                                                          }
                                                                          onChange={(
                                                                            e,
                                                                          ) => {
                                                                            setContentScoringConfig(
                                                                              (
                                                                                prev,
                                                                              ) => {
                                                                                return {
                                                                                  ...prev,
                                                                                  [callType]: {
                                                                                    ...prev[
                                                                                      callType
                                                                                    ],
                                                                                    [configKey]: prev[
                                                                                      callType
                                                                                    ][
                                                                                      configKey
                                                                                    ]?.map(
                                                                                      (
                                                                                        ck,
                                                                                        cki,
                                                                                      ) =>
                                                                                        cki ===
                                                                                        index
                                                                                          ? {
                                                                                              [Object.keys(
                                                                                                ck ||
                                                                                                  {},
                                                                                              )?.[0]]: prev[
                                                                                                callType
                                                                                              ][
                                                                                                configKey
                                                                                              ][
                                                                                                index
                                                                                              ][
                                                                                                Object.keys(
                                                                                                  criteriaKey ||
                                                                                                    {},
                                                                                                )?.[0]
                                                                                              ]?.map(
                                                                                                (
                                                                                                  key,
                                                                                                  keyIndex,
                                                                                                ) =>
                                                                                                  keyIndex ===
                                                                                                  factorIndex
                                                                                                    ? {
                                                                                                        ...prev[
                                                                                                          callType
                                                                                                        ][
                                                                                                          configKey
                                                                                                        ][
                                                                                                          index
                                                                                                        ][
                                                                                                          Object.keys(
                                                                                                            criteriaKey ||
                                                                                                              {},
                                                                                                          )?.[0]
                                                                                                        ][
                                                                                                          factorIndex
                                                                                                        ],
                                                                                                        [Object.keys(
                                                                                                          criteriaKeyFactor ||
                                                                                                            {},
                                                                                                        )?.[0]]: {
                                                                                                          ...prev[
                                                                                                            callType
                                                                                                          ][
                                                                                                            configKey
                                                                                                          ][
                                                                                                            index
                                                                                                          ][
                                                                                                            Object.keys(
                                                                                                              criteriaKey ||
                                                                                                                {},
                                                                                                            )?.[0]
                                                                                                          ][
                                                                                                            factorIndex
                                                                                                          ][
                                                                                                            Object.keys(
                                                                                                              criteriaKeyFactor ||
                                                                                                                {},
                                                                                                            )?.[0]
                                                                                                          ],
                                                                                                          [factorKey]:
                                                                                                            e
                                                                                                              .target
                                                                                                              .value,
                                                                                                        },
                                                                                                      }
                                                                                                    : key,
                                                                                              ),
                                                                                            }
                                                                                          : ck,
                                                                                    ),
                                                                                  },
                                                                                };
                                                                              },
                                                                            );
                                                                          }}
                                                                          type={
                                                                            factorKey ===
                                                                              'description' ||
                                                                            factorKey ===
                                                                              'question'
                                                                              ? 'text'
                                                                              : 'number'
                                                                          }
                                                                        />
                                                                      </Box>
                                                                    </Box>
                                                                  ) : null;
                                                                },
                                                              )
                                                            : null}
                                                        </Box>
                                                      </Box>
                                                    );
                                                  },
                                                )}
                                              </>
                                            ) : (
                                              <Box
                                                display={'flex'}
                                                alignItems={'center'}
                                                justifyContent={'space-between'}
                                                gap={1}
                                              >
                                                <Typography variant="body1">
                                                  Metrics
                                                </Typography>
                                                <Box
                                                  display={'flex'}
                                                  alignItems={'center'}
                                                  gap={1}
                                                >
                                                  <TextField
                                                    placeholder="Metric Name"
                                                    variant="outlined"
                                                    name={'criteriaFactorName'}
                                                    size="small"
                                                    value={criteriaFactorName}
                                                    onChange={(e) =>
                                                      setCriteriaFactorName(
                                                        e.target.value,
                                                      )
                                                    }
                                                    type="text"
                                                  />
                                                  <Button
                                                    variant="contained"
                                                    size="small"
                                                    startIcon={<Add />}
                                                    disabled={
                                                      !criteriaFactorName?.trim()
                                                    }
                                                    onClick={() => {
                                                      setContentScoringConfig(
                                                        (prev) => {
                                                          return {
                                                            ...prev,
                                                            [callType]: {
                                                              ...prev[callType],
                                                              [configKey]: prev[
                                                                callType
                                                              ][configKey]?.map(
                                                                (ck, cki) =>
                                                                  cki === index
                                                                    ? {
                                                                        [Object.keys(
                                                                          criteriaKey,
                                                                        )[0]]: [
                                                                          {
                                                                            [criteriaFactorName]: {
                                                                              description:
                                                                                '',
                                                                              weight: 1,
                                                                            },
                                                                          },
                                                                        ],
                                                                      }
                                                                    : ck,
                                                              ),
                                                            },
                                                          };
                                                        },
                                                      );
                                                      setCriteriaFactorName('');
                                                    }}
                                                  >
                                                    Add new metric
                                                  </Button>
                                                </Box>
                                              </Box>
                                            )
                                          ) : null}
                                        </Box>
                                      </Box>
                                    ))}
                                  </Box>
                                ) : (
                                  <Box
                                    display={'flex'}
                                    alignItems={'center'}
                                    justifyContent={'space-between'}
                                    gap={1}
                                  >
                                    <Typography>Categories</Typography>
                                    <Box
                                      display={'flex'}
                                      alignItems={'center'}
                                      gap={1}
                                    >
                                      <TextField
                                        placeholder="Category Name"
                                        variant="outlined"
                                        name={'criteriaName'}
                                        size="small"
                                        // sx={{
                                        //   width: '100%',
                                        // }}
                                        value={criteriaName}
                                        onChange={(e) =>
                                          setCriteriaName(e.target.value)
                                        }
                                        type="text"
                                      />
                                      <Button
                                        variant="contained"
                                        size="small"
                                        startIcon={<Add />}
                                        disabled={!criteriaName?.trim()}
                                        onClick={() => {
                                          setContentScoringConfig((prev) => {
                                            return {
                                              ...prev,
                                              [callType]: {
                                                ...prev[callType],
                                                [configKey]: [
                                                  ...prev[callType][configKey],
                                                  {
                                                    [criteriaName]: [],
                                                  },
                                                ],
                                                categoryWeight: {
                                                  ...prev[callType][
                                                    'categoryWeight'
                                                  ],
                                                  [criteriaName]: 1,
                                                },
                                              },
                                            };
                                          });
                                          setCriteriaName('');
                                        }}
                                      >
                                        Add new category
                                      </Button>
                                    </Box>
                                  </Box>
                                )
                              ) : null}
                            </Box>
                          </>
                        ) : (
                          <TextField
                            placeholder="JSON CONFIG"
                            variant="outlined"
                            name={'contentScoreConfig'}
                            fullWidth
                            size="small"
                            multiline
                            rows={17}
                            value={contentScoreConfigText[callType]}
                            onChange={(e) =>
                              setContentScoreConfigText((prev) => {
                                return {
                                  ...prev,
                                  [callType]: e.target.value,
                                };
                              })
                            }
                            type={'text'}
                          />
                        )}
                      </Box>
                    ) : null;
                  },
                )
              : null}
          </Box>
          <Box
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
            sx={{
              padding: 1,
              borderTop: '1px solid #d3d3d3',
            }}
          >
            <Button
              variant="outlined"
              onClick={(e) => {
                setShowEditContentScoringConfig(false);
                if (!currentOrgId) {
                  setWithContentScoringConfig(false);
                }
              }}
              color="primary"
              autoFocus
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={(e) => {
                if (
                  !deepEqual(
                    orgConfigs?.contentScoringConfig,
                    contentScoringConfig,
                  )
                ) {
                  console.log(true);
                  setShowConfigurationChanged(true);
                }
                setShowEditContentScoringConfig(false);
                if (configType === 'form') {
                  try {
                    setContentScoreConfigText((prev) => {
                      return {
                        ...prev,
                        outbound: JSON.stringify(
                          contentScoringConfig?.outbound || '{}',
                          null,
                          2,
                        ),
                        inbound: JSON.stringify(
                          contentScoringConfig?.inbound || '{}',
                          null,
                          2,
                        ),
                      };
                    });
                    setConfigType('form');
                  } catch (e) {
                    alert('Failed to convert json to text. please try again.');
                  }
                } else {
                  try {
                    const outboundObj = JSON.parse(
                      contentScoreConfigText?.outbound || '{}',
                    );
                    const inboundObj = JSON.parse(
                      contentScoreConfigText?.inbound || '{}',
                    );
                    setContentScoringConfig((prev) => {
                      return {
                        ...prev,
                        outbound: outboundObj,
                        inbound: inboundObj,
                      };
                    });
                    setConfigType('form');
                  } catch (e) {
                    alert(
                      'Failed to parse json text. please validate the json.',
                    );
                  }
                }
                // dispatch(
                //   updateGlobalOrganizationConfig(
                //     {
                //       appSubTitle: values.appSubTitle,
                //       appTitle: values.appTitle,
                //       metaDescription: values.metaDescription,
                //       organizationWebsite: values.organizationWebsite,
                //       textInLogo: !values.textInLogo,
                //       generalAutoAnalysis: orgConfigs?.isGeneralAutoAnalysis,
                //     },
                //     () => {
                //       dispatch(getUserDetails(() => {}));
                //       dispatch(
                //         showAlert({
                //           message: 'Details updated successfully.',
                //           showCross: true,
                //           title: null,
                //           type: 'success',
                //           autoHideDuration: 2000,
                //           vertical: 'top',
                //           horizontal: 'center',
                //         }),
                //       );
                //     },
                //   ),
                // );
              }}
              color="primary"
              autoFocus
            >
              Done
            </Button>
          </Box>
        </Box>
      </Modal>
      <Dialog
        open={Boolean(showConfirmSuspend)}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{
          zIndex: 10002,
          minWidth: 400,
          '& .MuiPaper-root-MuiDialog-paper': {
            maxWidth: '70vw',
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">
          <Typography variant="body1">Confirm</Typography>
        </DialogTitle>
        <DialogContent>
          <Box
            sx={{
              minWidth: 400,
            }}
          >
            <Typography variant="body1">
              Are you sure you want to suspend this team account? This will
              disable access for all members of this account.
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Box
            width={1}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            <Button
              // data-trackid={'cta_permission_denied_dialog_close'}
              onClick={(e) => {
                setShowConfirmSuspend(null);
              }}
              color="primary"
              // autoFocus
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={(e) => {
                dispatch(
                  updateTeamSuspendStatus(
                    showConfirmSuspend?.id,
                    !showConfirmSuspend?.suspended,
                    () => {
                      setShowConfirmSuspend(null);
                      dispatch(
                        getWlCustomers(userDetails?.organizationId, () => {}),
                      );
                    },
                  ),
                );
              }}
              color="primary"
              // autoFocus
            >
              Confirm
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
      <Dialog
        open={Boolean(showConfigurationChanged)}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{
          zIndex: 10002,
          minWidth: 400,
          '& .MuiPaper-root-MuiDialog-paper': {
            maxWidth: '70vw',
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">
          <Typography variant="body1">Save as default template</Typography>
        </DialogTitle>
        <DialogContent>
          <Box
            sx={{
              minWidth: 400,
            }}
          >
            <Typography variant="body1">
              Do you wish to save your changes to the default template so they
              are used automatically for new clients?
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Box
            width={1}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            <Button
              // data-trackid={'cta_permission_denied_dialog_close'}
              onClick={(e) => {
                setShowConfigurationChanged(null);
              }}
              color="primary"
              // autoFocus
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={(e) => {
                dispatch(
                  updateGlobalOrganizationConfig(
                    {
                      contentScoringConfig: {
                        inbound: sameConfig
                          ? contentScoringConfig.outbound
                          : contentScoringConfig.inbound,
                        outbound: contentScoringConfig.outbound,
                      },
                    },
                    () => {
                      dispatch(getOrganizationConfigs(() => {}));
                      setShowConfigurationChanged(null);
                      dispatch(
                        showAlert({
                          message: 'Default template updated successfully.',
                          showCross: true,
                          title: null,
                          type: 'success',
                          autoHideDuration: 2000,
                          vertical: 'top',
                          horizontal: 'center',
                        }),
                      );
                    },
                  ),
                );
              }}
              color="primary"
              // autoFocus
            >
              Save as Default Template and Continue
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
      <Dialog
        open={Boolean(showConfirmDeleteQuestionDialog)}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{
          zIndex: 10002,
          minWidth: 400,
          '& .MuiPaper-root-MuiDialog-paper': {
            maxWidth: '70vw',
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">
          <Typography variant="body1">Confirm</Typography>
        </DialogTitle>
        <DialogContent>
          <Box
            sx={{
              minWidth: 400,
            }}
          >
            <Typography variant="body1">
              Are you sure you want to delete this question?
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Box
            width={1}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            <Button
              // data-trackid={'cta_permission_denied_dialog_close'}
              onClick={(e) => {
                setShowConfirmDeleteQuestionDialog(null);
              }}
              color="primary"
              // autoFocus
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={(e) => {
                if (currentTab === 'clients-ask-donna') {
                  dispatch(
                    updateGlobalOrganizationConfig(
                      {
                        clientPresetQuestions: [
                          ...(orgConfigs?.clientPresetQuestions?.filter(
                            (q) => q !== showConfirmDeleteQuestionDialog,
                          ) || []),
                        ],
                        textInLogo: orgConfigs?.textInLogo,
                        generalAutoAnalysis: orgConfigs?.isGeneralAutoAnalysis,
                      },
                      () => {
                        setShowQuestionDialog(null);
                        dispatch(getOrganizationConfigs(() => {}));
                        setShowConfirmDeleteQuestionDialog(null);
                      },
                    ),
                  );
                } else {
                  dispatch(
                    updateCustomerDetails(
                      currentOrgId,
                      {
                        presetQuestions: [
                          ...(customers
                            ?.find((org) => org?.id === currentOrgId)
                            ?.presetQuestions?.filter(
                              (q) => q !== showConfirmDeleteQuestionDialog,
                            ) || []),
                        ],
                      },
                      () => {
                        setShowQuestionDialog(null);
                        setShowConfirmDeleteQuestionDialog(null);
                        setUpdatingOrgDetails(false);
                        dispatch(
                          getWlCustomers(userDetails?.organizationId, () => {}),
                        );
                        dispatch(
                          showAlert({
                            message: 'Details updated successfully.',
                            showCross: true,
                            title: null,
                            type: 'success',
                            autoHideDuration: 2000,
                            vertical: 'top',
                            horizontal: 'center',
                          }),
                        );
                      },
                    ),
                  );
                }
              }}
              color="primary"
              // autoFocus
            >
              Confirm Delete
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
      <Dialog
        open={Boolean(showAddCustomFieldDialog)}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{
          zIndex: 10002,
          minWidth: 400,
          // '& .MuiPaper-root-MuiDialog-paper': {
          //   maxWidth: '70vw',
          // },
        }}
      >
        <DialogContent>
          <Box display={'flex'} flexDirection={'column'} gap={1}>
            <Typography variant="body1">Add Custom Field</Typography>
            <TextField
              placeholder="Custom Field Title"
              variant="outlined"
              name={'customFieldTitle'}
              size="small"
              sx={{
                width: '100%',
              }}
              value={customFieldTitle}
              onChange={(e) => setCustomFieldTitle(e.target.value)}
              type={'text'}
            />
            <Typography>Context</Typography>
            <Typography variant="caption">
              (Please provide some context as to how this information will be
              used to give feedback to the sales rep.)
            </Typography>
            <TextField
              placeholder="Context"
              variant="outlined"
              name={'customFieldContext'}
              size="small"
              sx={{
                width: '100%',
              }}
              multiline
              rows={3}
              value={customFieldContext}
              onChange={(e) => setCustomFieldContext(e.target.value)}
              type={'text'}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Box
            width={1}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            <Button
              // data-trackid={'cta_permission_denied_dialog_close'}
              onClick={(e) => {
                setShowAddCustomFieldDialog(null);
              }}
              color="primary"
              // autoFocus
            >
              Cancel
            </Button>
            <Button
              disabled={!customFieldTitle?.trim()}
              onClick={(e) => {
                setCustomPlaybookFields((prev) => [
                  ...prev,
                  {
                    title: customFieldTitle,
                    content: '',
                    context: customFieldContext,
                  },
                ]);
                setShowAddCustomFieldDialog(null);
                setExpandedField((prev) =>
                  prev?.includes('custom') ? prev : [...prev, 'custom'],
                );
              }}
              color="primary"
              // autoFocus
            >
              Add
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default CustomerSettings;
